import '../ErrorPages.scss';

import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as PadlockIcon } from 'images/icons/PadlockDark.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

export const NoAccess = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='error-page'>
      <PadlockIcon />
      <h2>{t('LooksLikeYouHaveNoAccess', { ns: 'general' })}</h2>
      <Paragraph>
        <>
          <Trans i18nKey='PleaseContactSupport' ns='general'>
            Please contact&nbsp;
            <a href='https://support.trackmangolf.com/' title={t('GoToSupportPage', { ns: 'general' })}>
              support
            </a>
            &nbsp;if you think that you should have access to this page.
          </Trans>
        </>
      </Paragraph>
    </div>
  );
});

export default NoAccess;
