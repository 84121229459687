import { AdminParameterModel, AdminParametersList, LegendModelGET } from 'types/admin/trackmanParameters';

import { AdminUploadedImage } from 'types/admin/media';
import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class ParametersService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getParameters = async () => {
    const response = await this.axiosInstance.get<Result<AdminParametersList>>(`/api/admin/trackman-parameters`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting trackman parameters.');
  };

  getParameter = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminParameterModel>>(`/api/admin/trackman-parameters/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting trackman parameter.');
  };

  getLegendImagesCollection = async () => {
    const response = await this.axiosInstance.get<Result<LegendModelGET[]>>(`/api/admin/legend-images`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting legend images.');
  };

  uploadLegendImage = async (data: AdminUploadedImage) => {
    const response = await this.axiosInstance.post<Result<AdminUploadedImage>>(`/api/admin/legend-images`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while uploading legend image.');
  };

  deleteParameter = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/trackman-parameters/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting trackman parameter.');
  };

  saveNewParameter = async (data: AdminParameterModel) => {
    const response = await this.axiosInstance.post<Result<AdminParameterModel>>(
      `/api/admin/trackman-parameters`,
      {
        parameter: JSON.stringify(data),
        image: data.mainImgData?.data,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (response.status !== 200) throw Error('There was an internal error while saving this parameter.');
  };

  saveExistingParameter = async (data: AdminParameterModel) => {
    const response = await this.axiosInstance.put<Result<AdminParameterModel>>(
      `/api/admin/trackman-parameters`,
      {
        parameter: JSON.stringify(data),
        image: data.mainImgData?.data,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (response.status !== 200) throw Error('There was an internal error while saving this parameter.');
  };
}

export default ParametersService;
