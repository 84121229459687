import './OutputTable.scss';

import { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from 'services/Calculator/CalculatorService';

import AddTableToStore from './AddTableToStore/AddTableToStore';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import InputsDisplayBox from '../DisplayBoxes/InputsDisplayBox/InputsDisplayBox';
import OutputDataBoxes from '../DisplayBoxes/OutputDataBoxes/OutputDataBoxes';
import { observer } from 'mobx-react-lite';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: BallLaunch | ClubDelivery | SwingDirection | BounceAndRoll;
  htmlColor: string;
};

const OutputTable = observer(({ calcOptions, calcResult, htmlColor }: Props) => {
  return (
    <div className='table-container'>
      <InputsDisplayBox calcOptions={calcOptions} htmlColor={htmlColor} />
      <OutputDataBoxes calcOptions={calcOptions} calcResult={calcResult} htmlColor={htmlColor} />
      <AddTableToStore calcOptions={calcOptions} calcResult={calcResult} htmlColor={htmlColor} />
    </div>
  );
});

export default OutputTable;
