import './MenuItem.scss';

import { ClickAwayListener } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
  isSubmenu?: boolean;
} & (
  | {
      onClick: () => void;
      submenu?: undefined;
    }
  | {
      onClick?: undefined;
      submenu: JSX.Element;
    }
);

const MenuItem = observer(({ label, icon, onClick, isActive, submenu, isSubmenu = false }: MenuItemProps) => {
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);

  return (
    <div className='menu-item--container'>
      <ClickAwayListener onClickAway={() => setSubmenuOpen(false)}>
        <div
          className={`menu-item ${isActive ? 'active' : ''} ${isSubmenuOpen ? 'hovered' : ''} ${isSubmenu ? 'submenu-item' : ''}`}
          onClick={() => (submenu ? setSubmenuOpen(true) : onClick())}
        >
          <div>{icon}</div>
          <div>{label}</div>
        </div>
      </ClickAwayListener>
      {submenu && <div className={`menu-item--submenu ${isSubmenuOpen ? 'is-open' : ''}`}>{submenu}</div>}
    </div>
  );
});

export default MenuItem;
