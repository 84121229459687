import { AdminOrganization, AdminOrganizationInstructor, AdminOrganizationsList } from 'types/admin/organizations';
import { AxiosInstance, AxiosResponse } from 'axios';

import { Result } from 'services/Result';

interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class OrganisationsEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getOrganizations = async () => {
    const response = await this.axiosInstance.get<Result<AdminOrganizationsList>>(`/api/admin/organisations/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching organizations.');
  };

  getOrganization = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminOrganization>>(`/api/admin/organisations/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching organization.');
  };

  instructorsEmailLookup = async (emails: string[]) => {
    const response = await this.axiosInstance.post<string[], AxiosResponse<ApiResponse<AdminOrganizationInstructor[]>>>(
      `/api/admin/organisations/emailslookup`,
      { Emails: emails }
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while inviting instructors.');
  };

  deleteOrganization = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/organisations/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting the organization.');
  };

  saveNewOrganization = async (organization: AdminOrganization) => {
    const response = await this.axiosInstance.post<AdminOrganization, AxiosResponse<ApiResponse<AdminOrganization>>>(
      `/api/admin/organisations`,
      { ...organization }
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving the organisation.');
  };

  saveEditedOrganization = async (organization: AdminOrganization) => {
    const response = await this.axiosInstance.put<AdminOrganization, AxiosResponse<ApiResponse<AdminOrganization>>>(
      `/api/admin/organisations`,
      { ...organization }
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving the organisation.');
  };
}

export default OrganisationsEditorService;
