import '../../../pages/Organization/Organization.scss';

import InviteStudentsButton from '../InviteStudentsButton/InviteStudentsButton';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const NoStudentsFound = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='no-data-found no-students-found'>
      <h2>{t('NoStudentsHaveJoinedThisClass', { ns: 'organizationPage' })}</h2>
      <Paragraph>
        <>{t('InviteStudentsOrResendInvitations', { ns: 'studentsPage' })}</>
      </Paragraph>
      <InviteStudentsButton />
    </div>
  );
});

export default NoStudentsFound;
