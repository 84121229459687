import './LearningMaterialsLessonText.scss';

import { observer } from 'mobx-react-lite';

type LearningMaterialsLessonTextProps = {
  description: string;
};

export const LearningMaterialsLessonText = observer(({ description }: LearningMaterialsLessonTextProps) => {
  return <div className='text-content lesson-content'>{description}</div>;
});

export default LearningMaterialsLessonText;
