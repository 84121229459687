import { configure, makeAutoObservable } from 'mobx';

import { LearningMaterialsLesson } from 'types/learningMaterials';
import LearningMaterialsRoomLessonService from '../../services/LearningMaterials/LearningMaterialsRoomLessonService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });
export class LearningMaterialsRoomLessonStore {
  private learningMaterialsRoomLessonService: LearningMaterialsRoomLessonService;
  private ns: NotificationsStore;

  constructor(learningMaterialsRoomLessonService: LearningMaterialsRoomLessonService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.learningMaterialsRoomLessonService = learningMaterialsRoomLessonService;
    this.ns = notificationStore;
  }

  learningMaterialLesson: LearningMaterialsLesson = {
    id: '',
    title: '',
    color: '',
    learningMaterial: [],
  };
  isLoading: boolean = false;
  isLoaded: boolean = false;
  isMarkingLessonItemAsDone: boolean = false;
  isMarkingLessonAsDone: boolean = false;

  startLesson = async (courseId: string, lessonId: string) => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialsRoomLessonService.loadLesson(courseId, lessonId);
      this.learningMaterialLesson = {
        ...response,
        learningMaterial: response.learningMaterial.map((lm) => ({
          ...lm,
          calculatorOptions: lm.calculatorOptions ? JSON.parse(lm.calculatorOptions) : '',
        })),
      };
      this.isLoaded = true;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  markLessonItemAsDone = async (courseId: string, lessonId: string, stepId: string) => {
    try {
      this.isMarkingLessonItemAsDone = true;
      await this.learningMaterialsRoomLessonService.markLessonItemAsDone(courseId, lessonId, stepId);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isMarkingLessonItemAsDone = false;
  };

  markLessonAsDone = async (courseId: string, lessonId: string) => {
    try {
      this.isMarkingLessonAsDone = true;
      await this.learningMaterialsRoomLessonService.markLessonAsDone(courseId, lessonId);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isMarkingLessonAsDone = false;
  };
}

export default LearningMaterialsRoomLessonStore;
