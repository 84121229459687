import { Invite, Invites } from 'types/organization/invites';
import { configure, makeAutoObservable } from 'mobx';

import ClassesStore from '../classes/ClassesStore';
import InviteStudentsService from '../../../services/CoachSection/students/InviteStudentsService';
import { NotificationsStore } from '../../NotificationsStore';
import OrganizationsStore from '../OrganizationsStore';
import { filterUsersByClass } from 'utilities/organizationDataFilter';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });

export class InviteStudentsStore {
  private inviteStudentsService: InviteStudentsService;
  private orgStore: OrganizationsStore;
  private classesStore: ClassesStore;
  private ns: NotificationsStore;

  constructor(
    inviteStudentsService: InviteStudentsService,
    orgStore: OrganizationsStore,
    classesStore: ClassesStore,
    notificationStore: NotificationsStore
  ) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.inviteStudentsService = inviteStudentsService;
    this.orgStore = orgStore;
    this.classesStore = classesStore;
    this.ns = notificationStore;
  }

  invites: Invites = [];
  searchInput: string = '';
  filteredList: Invites = [];
  isPendingInvitesTabActive: boolean = false;
  isLoading: boolean = false;

  numberOfInvitesLeft: number = 0;
  isInviteModalOpen: boolean = false;
  cleanNewInvite: Invite = {
    id: '',
    email: '',
    name: '',
    organisationName: '',
    classes: [],
  };
  newInvite: Invite = { ...this.cleanNewInvite };
  newInvites: Invites = [];
  selectedClass = { id: '', name: '' };
  isSubmittingInvites: boolean = false;

  getNumberOfInvitesLeft = async () => {
    try {
      const response = await this.inviteStudentsService.getNumberOfInvitesLeft(this.orgStore.selectedOrg.id);
      this.numberOfInvitesLeft = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  getInvites = async () => {
    try {
      this.isLoading = true;
      const response = await this.inviteStudentsService.getInvites(this.orgStore.selectedOrg.id);
      this.invites = response;
      this.filteredList = filterUsersByClass(this.invites, this.classesStore.selectedClassId) as Invites;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteInvitesAndUpdate = async (invitesIds: Array<string>) => {
    try {
      await this.inviteStudentsService.deleteInvitesAndUpdate(this.orgStore.selectedOrg.id, invitesIds);
      this.getInvites();
      this.getNumberOfInvitesLeft();
      this.ns.addToast('success', i18next.t('InvitesDeletedSuccessfully', { ns: 'studentsPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  submitInvitesAndUpdate = async () => {
    try {
      this.isSubmittingInvites = true;
      await this.inviteStudentsService.submitInvitesAndUpdate(this.newInvites, this.orgStore.selectedOrg.id);
      this.getInvites();
      this.getNumberOfInvitesLeft();
      this.ns.addToast('success', i18next.t('InvitesSentSuccessfully', { ns: 'studentsPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isSubmittingInvites = false;
  };

  resendInvitation = async (inviteId: string) => {
    try {
      await this.inviteStudentsService.resendInvitation(inviteId, this.orgStore.selectedOrg.id);
      this.ns.addToast('success', i18next.t('InvitationResentSuccessfully', { ns: 'studentsPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default InviteStudentsStore;
