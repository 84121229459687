import './CertificateTile.scss';

import { DefaultRoutes } from 'Routes';
import { ReactComponent as Document } from '../../images/icons/menu/Document.svg';
import { ReactComponent as Download } from '../../images/icons/Download.svg';
import { Loader } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import Subtitle from 'components/TextComps/Subtitle';
import certificateImg from 'images/Certification/player_hitting_driver_against_sunset.png';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  subtitle: string;
  altText: string;
  icon: string;
  level?: string;
  showLoader?: boolean;
  hasCertificate?: boolean;
};

const CertificateTile = ({ subtitle, altText, icon, level, showLoader, hasCertificate }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().certificateStore;
  const location = useLocation();
  const isLandingPage = useMemo(() => location.pathname === DefaultRoutes.LandingPage, [location.pathname]);

  return (
    <div className='certificate-tile-container' style={showLoader ? { opacity: 0.5 } : {}}>
      <div className='certificate-tile'>
        <div className='image-container'>
          <img src={certificateImg} alt={t('PlayerHittingDriver', { ns: 'certificate' })} className='certificate-img' />
          <img src={icon} alt={altText} className='certificate-icon' />
        </div>
        <div className={`info-wrapper ${!hasCertificate && !isLandingPage ? 'disabled' : ''}`}>
          <div className='icon pdf'>
            <Document />
            <p>{t('PDF', { ns: 'certificate' })}</p>
          </div>
          <div className='text'>
            <Subtitle>
              <>{subtitle}</>
            </Subtitle>
            <Paragraph>
              <>{t('DownloadCertificateHere', { ns: 'certificate' })}</>
            </Paragraph>
          </div>
          <span className='icon download' onClick={() => (hasCertificate && level ? cs.downloadPdfCertificate(level) : undefined)}>
            <Download />
          </span>
        </div>
      </div>
      {cs.certDownloadError.text && cs.certDownloadError.level === level && (
        <p className='error-info'>{t(cs.certDownloadError.text, { ns: 'certificate' })}</p>
      )}
      {showLoader && (
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      )}
    </div>
  );
};

export default CertificateTile;
