import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import { observer } from 'mobx-react-lite';

type Props = {
  label: string;
  input: string;
  onChange: (newValue: string) => void;
};

export const SearchForStudents = observer(({ label, input, onChange }: Props) => {
  return (
    <div className='text-field search-for-students'>
      <label htmlFor='search-for-students'>{label}</label>
      <input placeholder={'Search...'} name='search-for-students' value={input ?? ''} onChange={(e) => onChange(e.currentTarget.value)} />
      <SearchIcon />
    </div>
  );
});

export default SearchForStudents;
