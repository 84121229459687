import { Unit } from './supportedUnits';
import { i18nInit } from 'i18nSetup';
import i18next from 'i18next';

// todo - delete commented code and add translations where needed

i18nInit();

export const unitToSymbol: { [key in Unit]: string } = {
  MilesPerHour: i18next.t('MilesPerHourSymbol', { ns: 'units' }),
  Degree: i18next.t('Deg', { ns: 'units' }),
  Centimeter: i18next.t('CentimeterSymbol', { ns: 'units' }),
  UnitLess: '',
  RotationsPerMinute: i18next.t('RotationsPerMinuteSymbol', { ns: 'units' }),
  Meter: i18next.t('MeterSymbol', { ns: 'units' }),
  Second: i18next.t('SecondSymbol', { ns: 'units' }),
  MeterPerSecond: i18next.t('MetersPerSecondSymbol', { ns: 'units' }),
  PercentageAsFloat: i18next.t('PercentageSymbol', { ns: 'units' }),
  Celsius: i18next.t('CelsiusSymbol', { ns: 'units' }),
  Inch: i18next.t('InchSymbol', { ns: 'units' }),
  Foot: i18next.t('FootSymbol', { ns: 'units' }),
  Yard: i18next.t('YardSymbol', { ns: 'units' }),
  Fahrenheit: i18next.t('FahrenheitSymbol', { ns: 'units' }),
  KilometersPerHour: i18next.t('KilometersPerHourSymbol', { ns: 'units' }),
} as const;
