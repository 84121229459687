import i18next from 'i18next';

export const getUserInitials = (firstName: string | null, lastName: string | null) => {
  if (firstName && !lastName) {
    return firstName.slice(0, 2);
  }
  if (firstName && lastName) {
    return `${firstName.charAt(0)} ${lastName.charAt(0)}`;
  }
  return 'N/A';
};

export const getUsername = (firstName: string | null, lastName: string | null) => {
  if (firstName && !lastName) {
    return firstName;
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return i18next.t('Unknown', { ns: 'editProfile' });
};
