import './LearningMaterialsCalculator.scss';

import { getCalcOptionsByType, mapUnitSystemToCulture } from 'utilities/adminCalculatorOptions';
import { useCallback, useState } from 'react';

import AceEditor from 'react-ace';
import { CalculatorKind } from 'types/learningMaterials';
import CalculatorPreviewModal from 'components/Admin/Modals/CalculatorPreviewModal/CalculatorPreviewModal';
import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const calculatorKinds: CalculatorKind[] = ['CALC_NUM', 'CALC_UD', 'CALC_BR', 'CALC_CD', 'CALC_SD'];

export const LearningMaterialsCalculator = observer(() => {
  const lms = useStores().learningMaterialStore;
  const [isModalOn, setIsModalOn] = useState(false);
  const [jsonError, setJsonError] = useState<string>('');

  const validateJson = useCallback(
    (inputString: string) => {
      setJsonError('');

      try {
        const parsedJSON = JSON.parse(inputString);

        lms.learningMaterial.calculatorOptions = JSON.stringify(parsedJSON);
      } catch (e) {
        setJsonError('Invalid JSON, please check the syntax');
        return false;
      }
      return true;
    },
    [lms.learningMaterial]
  );

  return (
    <>
      <h2>Calculator answer settings</h2>
      <div className='calculator-editor-container'>
        <Paragraph>
          <>Calculator type</>
        </Paragraph>
        <ProfileInput
          label='Type'
          value={lms.learningMaterial.calculatorKind ?? ''}
          onChange={(newValue: string) => {
            if (calculatorKinds.includes(newValue as CalculatorKind)) {
              lms.learningMaterial.calculatorKind = newValue as CalculatorKind;
              lms.learningMaterial.calculatorOptions = getCalcOptionsByType(newValue as CalculatorKind);
            }
          }}
          selectOptions={calculatorKinds.map((type) => {
            return {
              label: type,
              value: type,
            };
          })}
        />
        <AceEditor
          placeholder='Type the calculator settings in JSON format here'
          name='calculator-settings'
          onChange={(newValue) => validateJson(newValue)}
          fontSize={14}
          lineHeight={19}
          value={JSON.stringify(JSON.parse(lms.learningMaterial.calculatorOptions), null, 2) ?? ``}
        />
        {jsonError ? <p className='error-info'>{jsonError}</p> : <></>}
        <div className='buttons-wrapper'>
          {lms.learningMaterial.languages.map((language) => {
            return (
              <button
                key={language}
                className='styled-button secondary'
                onClick={() => {
                  localStorage.calculatorPreviewCulture = mapUnitSystemToCulture(language);
                  setIsModalOn(true);
                }}
              >
                Preview {getLanguageText(language)}
              </button>
            );
          })}
          <button
            className='styled-button primary'
            disabled={jsonError !== ''}
            onClick={() => validateJson(lms.learningMaterial.calculatorOptions)}
          >
            Save
          </button>
        </div>
        <CalculatorPreviewModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} />
      </div>
    </>
  );
});

export default LearningMaterialsCalculator;
