import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import { Students } from 'types/organization/students';
import i18next from 'i18next';

export class GameProgressService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getClassProgressForSelectedCourse = async (classId: string, courseLessonsIds: Array<string>) => {
    const response = await this.axiosInstance.post<Result<Students>>(`/api/classes/${classId}/progress-game-lessons`, {
      lessonIds: courseLessonsIds,
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('ClassProgressError', { ns: 'organizationPage' }));
  };
}

export default GameProgressService;
