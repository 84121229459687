import { useCallback, useEffect, useState } from 'react';

import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { videoUrlValidator } from 'utilities/urlValidators';

type Props = {
  setSaveButtonDisabled: (value: boolean) => void;
};

export const LearningMaterialsVideo = observer(({ setSaveButtonDisabled }: Props) => {
  const lms = useStores().learningMaterialStore;
  const [videoIdError, setVideoIdError] = useState(false);

  useEffect(() => {
    lms.learningMaterial.kind === 'Video' && setVideoIdError(lms.learningMaterial.youTubeKey === '');
  }, [lms.learningMaterial.kind, lms.learningMaterial.youTubeKey]);

  const handleOnChange = useCallback(
    (newValue: string) => {
      setSaveButtonDisabled(false);
      setVideoIdError(false);
      if (videoUrlValidator(newValue) || newValue === '') {
        setVideoIdError(true);
        setSaveButtonDisabled(true);
        return;
      }
      lms.learningMaterial.youTubeKey = newValue;
    },
    [lms.learningMaterial, setSaveButtonDisabled]
  );

  return (
    <div className='video-settings-wrapper'>
      <Paragraph>
        <span className={videoIdError ? 'error' : ''}>
          {lms.learningMaterial.youTubeKey ? (
            <span>
              Please add only ID of the video, you can find it in the YouTube link eg. https://www.youtube.com/watch?v=
              <strong>xZoMV_ap4s4</strong>
            </span>
          ) : (
            <span>Please add video ID or change learning material type.</span>
          )}
        </span>
      </Paragraph>
      <div className='video-input'>
        <ProfileInput
          label='YouTube video ID'
          value={lms.learningMaterial.youTubeKey ?? ''}
          onChange={(newValue) => handleOnChange(newValue)}
        />
      </div>
    </div>
  );
});

export default LearningMaterialsVideo;
