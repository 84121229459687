import './ModulesAndTestsSectionHeading.scss';

import { observer } from 'mobx-react-lite';

type GameSectionHeadingProps = {
  title: string;
  description: string;
  color: string;
  progress: number;
};

export const ModulesAndTestsSectionHeading = observer(({ title, description, color, progress }: GameSectionHeadingProps) => {
  return (
    <div className='modules-and-tests-section-heading-container'>
      <div
        className='progress-box'
        style={{
          background: `conic-gradient(${color} ${progress}%, 0, ${color}22 ${(100 - progress).toString()}%)`,
        }}
      >
        <div className='progress-circle'>
          <span>{progress}%</span>
        </div>
      </div>
      <div className='modules-and-tests-section-heading'>
        <h2 className='title'>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
});

export default ModulesAndTestsSectionHeading;
