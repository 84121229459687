import { getSelectDisplayValue, getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { SelectVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { isSelectHidden } from 'components/FullCalculator/helpers/hiddenVariables';
import { observer } from 'mobx-react-lite';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useMemo } from 'react';
import { useSelectConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import useSelectOptions from 'components/FullCalculator/hooks/useSelectOptions';

type Props = {
  calcOptions: CalculatorOptions;
  selectVariable: SelectVariable;
};

const SelectVariableRow = observer(({ calcOptions, selectVariable }: Props) => {
  const { parameter, label } = useSelectConstants(selectVariable);
  const { clubType } = useClubType(calcOptions);
  const { selectOptions } = useSelectOptions({ calcOptions, parameter });

  const selectValue = useMemo(
    () => getValueByPath({ readableObject: calcOptions, readPath: selectVariable }),
    [calcOptions, selectVariable]
  );

  return isSelectHidden(clubType, parameter) ? (
    <></>
  ) : (
    <li className='parameter'>
      <div className='parameter-name'>
        <span>{label}</span>
      </div>
      <div className='parameter-value'>
        <span>{getSelectDisplayValue({ selectValue, selectOptions })}</span>
      </div>
    </li>
  );
});

export default SelectVariableRow;
