import { AxiosInstance, AxiosResponse } from 'axios';

import { AdminPartnerOrMasterModel } from 'types/admin/partners';
import { Result } from 'services/Result';

interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class MastersEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getMaster = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminPartnerOrMasterModel>>(`/api/admin/masters/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching a master.');
  };

  deleteMaster = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/masters/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting this master.');
  };

  saveNewMaster = async (master: AdminPartnerOrMasterModel) => {
    const response = await this.axiosInstance.post<AdminPartnerOrMasterModel, AxiosResponse<ApiResponse<AdminPartnerOrMasterModel>>>(
      `/api/admin/masters`,
      {
        file: master.file?.data,
        fullName: master.fullName,
        description: master.description,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (response.status !== 200) throw Error('There was an internal error while saving this master.');
  };

  saveExistingMaster = async (master: AdminPartnerOrMasterModel) => {
    const response = await this.axiosInstance.put<AdminPartnerOrMasterModel, AxiosResponse<ApiResponse<AdminPartnerOrMasterModel>>>(
      `/api/admin/masters`,
      { id: master.id, file: master.file?.data, fileUrl: master.imageUrl, fullName: master.fullName, description: master.description },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (response.status !== 200) throw Error('There was an internal error while saving this master.');
  };
}

export default MastersEditorService;
