import { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from 'services/Calculator/CalculatorService';
import { configure, makeAutoObservable } from 'mobx';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { NotificationsStore } from '../NotificationsStore';

configure({ enforceActions: 'never' });

export type SavedTable = {
  id: string;
  savedCalcOptions: CalculatorOptions;
  savedCalcResult: BallLaunch | ClubDelivery | SwingDirection | BounceAndRoll;
  htmlColor: string;
};

export class SavedCalcTableStore {
  private ns: NotificationsStore;

  constructor(notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  savedTables: Array<SavedTable> = [];

  saveTable = (newTable: SavedTable) => this.savedTables.unshift(newTable);

  deleteTable = (id: string) => (this.savedTables = this.savedTables.filter((table) => table.id !== id));
}

export default SavedCalcTableStore;
