import { AxiosInstance, AxiosResponse } from 'axios';

import { UserDataResponse } from 'types/admin/userData';

interface UserDataPost {
  userId: string;
}
interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class ManageUserDataService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  toggleMembership = async (userId: string) => {
    const response = await this.axiosInstance.post<UserDataPost, AxiosResponse<ApiResponse<UserDataResponse>>>(
      `/api/Payment/ToggleMembership`,
      {
        userId: userId,
      }
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while toggling membership.');
  };

  removeMembership = async (userId: string) => {
    const response = await this.axiosInstance.post<UserDataPost, AxiosResponse<ApiResponse<UserDataResponse>>>(
      `/api/Payment/RemovePremium`,
      { userId: userId }
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while removing membership.');
  };

  updatePlayerLevel1Certification = async (userId: string) => {
    const response = await this.axiosInstance.post<UserDataPost, AxiosResponse<ApiResponse<UserDataResponse>>>(
      `/api/admin/support/UpdatePlayerLevel1Certification`,
      { userId: userId }
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }

    throw Error('There was an internal error while updating player level 1 certification.');
  };

  resetPlayerLevel1Certification = async (userId: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/support/ResetPlayerCertificationProgressLevel1`, {
      data: { userId: userId },
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }

    throw Error('There was an internal error while resetting player level 1 certification.');
  };

  updatePlayerLevel2Certification = async (userId: string) => {
    const response = await this.axiosInstance.post<UserDataPost, AxiosResponse<ApiResponse<UserDataResponse>>>(
      `/api/admin/support/UpdatePlayerLevel2Certification`,
      { userId: userId }
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }

    throw Error('There was an internal error while updating player level 2 certification.');
  };

  resetPlayerLevel2Certification = async (userId: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/support/ResetPlayerCertificationProgressLevel2`, {
      data: { userId: userId },
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }

    throw Error('There was an internal error while resetting player level 2 certification.');
  };

  updatePlayerLevelMaster = async (userId: string) => {
    const response = await this.axiosInstance.post<UserDataPost, AxiosResponse<ApiResponse<UserDataResponse>>>(
      `/api/admin/support/UpdatePlayerLevelMaster`,
      { userId: userId }
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while updating player level master.');
  };

  getUserIdByEmail = async (userEmail: string) => {
    const response = await this.axiosInstance.get<ApiResponse<string>>(`/api/admin/user/GetIdByEmail?email=${userEmail}`);

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting user ID by email.');
  };

  getUserEmailById = async (userId: string) => {
    const response = await this.axiosInstance.get<ApiResponse<string>>(`/api/admin/user/GetEmailById?id=${userId}`);

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting user email by ID.');
  };
}

export default ManageUserDataService;
