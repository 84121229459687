import { LanguageInfo, LanguageType } from 'types/languages';
import { configure, makeAutoObservable } from 'mobx';

import LanguagesService from '../../services/Languages/LanguagesService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class LanguagesStore {
  private languagesService: LanguagesService;
  private ns: NotificationsStore;

  constructor(languagesService: LanguagesService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.languagesService = languagesService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  isChangingLanguage: boolean = false;
  languageInfo: LanguageInfo[] = [];
  userLanguage: LanguageType = '';
  selectedLanguage: string = '';

  loadLanguages = async () => {
    try {
      const response = await this.languagesService.loadLanguages();
      this.languageInfo = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  getUserLanguage = async () => {
    try {
      this.isLoading = true;
      const response = await this.languagesService.getUserLanguage();
      this.userLanguage = response;
      localStorage.i18nextLng = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  changeLanguage = async (countryCode: LanguageType) => {
    try {
      this.isChangingLanguage = true;
      await this.languagesService.changeLanguage(countryCode);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isChangingLanguage = false;
  };
}

export default LanguagesStore;
