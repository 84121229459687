type Props = {
  color: string;
  leftToRotationPoint: string;
  topToRotationPoint: string;
  rotationInDegrees?: number;
  thickness?: number;
  length?: string;
  scale?: number;
};

const mapColor = (color: string) => {
  switch (color) {
    case 'red':
      return '#E20717';
    case 'blue':
      return '#1E76BC';
    case 'orange':
      return '#ec691a';
    default:
      return color;
  }
};

const Arrow = ({
  color,
  leftToRotationPoint,
  topToRotationPoint,
  rotationInDegrees = 0,
  thickness = 12,
  length = '35%',
  scale = 1,
}: Props) => {
  return (
    <div
      style={{
        height: thickness,
        width: length,
        top: topToRotationPoint,
        left: leftToRotationPoint,
        position: 'absolute',
        transformOrigin: 'left',
        transition: 'transform 150ms ease',
        background: mapColor(color),
        transform: `translateY(-50%) rotate(${rotationInDegrees}deg) scale(${scale})`,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          right: '0',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: `12px 0 12px 30px`,
          transform: `translate(100%, -50%)`,
          borderColor: `transparent transparent transparent ${mapColor(color)}`,
        }}
      />
    </div>
  );
};

export default Arrow;
