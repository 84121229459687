import { configure, makeAutoObservable } from 'mobx';

import ManageUserDataService from '../../services/UserAccount/ManageUserDataService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class ManageUserDataStore {
  private manageUserDataService: ManageUserDataService;
  private ns: NotificationsStore;

  constructor(manageUserDataService: ManageUserDataService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.manageUserDataService = manageUserDataService;
    this.ns = notificationStore;
  }

  toggleMembershipUserId: string = '';
  removeMembershipUserId: string = '';
  giveLevel1CertUserId: string = '';
  resetLevel1CertUserId: string = '';
  giveLevel2CertUserId: string = '';
  resetLevel2CertUserId: string = '';
  giveLevelMasterUserId: string = '';
  userSearchEmail: string = '';
  foundUserId: string = '';
  userSearchId: string = '';
  foundUserEmail: string = '';
  isLoading: boolean = false;

  toggleMembership = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.toggleMembership(this.toggleMembershipUserId);
      this.ns.addToast('success', 'Player membership has been set', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.toggleMembershipUserId = '';
    this.isLoading = false;
  };

  removeMembership = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.removeMembership(this.removeMembershipUserId);
      this.ns.addToast('success', 'Player membership has been unset', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.removeMembershipUserId = '';
    this.isLoading = false;
  };

  updatePlayerLevel1Certification = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.updatePlayerLevel1Certification(this.giveLevel1CertUserId);
      this.ns.addToast('success', 'Player level 1 certification has been updated', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.giveLevel1CertUserId = '';
    this.isLoading = false;
  };

  resetPlayerLevel1Certification = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.resetPlayerLevel1Certification(this.resetLevel1CertUserId);
      this.ns.addToast('success', 'Player level 1 certification has been reset', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.resetLevel1CertUserId = '';
    this.isLoading = false;
  };

  updatePlayerLevel2Certification = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.updatePlayerLevel2Certification(this.giveLevel2CertUserId);
      this.ns.addToast('success', 'Player level 2 certification has been updated', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.giveLevel2CertUserId = '';
    this.isLoading = false;
  };

  resetPlayerLevel2Certification = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.resetPlayerLevel2Certification(this.resetLevel2CertUserId);
      this.ns.addToast('success', 'Player level 2 certification has been reset', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.resetLevel2CertUserId = '';
    this.isLoading = false;
  };

  updatePlayerLevelMaster = async () => {
    try {
      this.isLoading = true;
      await this.manageUserDataService.updatePlayerLevelMaster(this.giveLevelMasterUserId);
      this.ns.addToast('success', 'Player level Master has been updated', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.giveLevelMasterUserId = '';
    this.isLoading = false;
  };

  getUserIdByEmail = async () => {
    try {
      this.isLoading = true;
      const response = await this.manageUserDataService.getUserIdByEmail(this.userSearchEmail);
      this.foundUserId = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.userSearchEmail = '';
    this.isLoading = false;
  };

  getUserEmailById = async () => {
    try {
      this.isLoading = true;
      const response = await this.manageUserDataService.getUserEmailById(this.userSearchId);
      this.foundUserEmail = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.userSearchId = '';
    this.isLoading = false;
  };
}

export default ManageUserDataStore;
