import './AboutTab.scss';

import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@trackman/web-shared-components';
import { DefaultRoutes } from 'Routes';
import FAQTile from 'components/FAQTile/FAQTile';
import { OfferDescriptionItem } from 'components/OfferDescriptionItem/OfferDescriptionItem';
import Paragraph from 'components/TextComps/Paragraph';
import { PricingTable } from 'components/PricingTable/PricingTable';
import SectionTitle from 'components/TextComps/SectionTitle';
import Subtitle from 'components/TextComps/Subtitle';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'index';

export const AboutTab = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ps = useStores().profileStore;

  const currentPlanLabel = <div className='offer-label'>{t('CurrentPlan', { ns: 'aboutCertification' })}</div>;

  useEffect(() => {
    ps.checkIfUserIsMember();
  }, [ps]);

  const qna = [
    {
      q: t('HowDoISignUpToAWorkshop', { ns: 'aboutCertification' }),
      a: <>{t('OurListOfWorkshops', { ns: 'aboutCertification' })}</>,
    },
    {
      q: t('HowMuchDoINeedToPay', { ns: 'aboutCertification' }),
      a: <>{t('YouPay475ForTheFirstYear', { ns: 'aboutCertification' })}</>,
    },
    {
      q: t('ForHowLongIsTrackmanCertValid', { ns: 'aboutCertification' }),
      a: (
        <Trans i18nKey='YourCertificationIsValidForThreeYears' ns='aboutCertification'>
          Your Certification is valid for a period of 3 years counting from the date you passed the test. Click&nbsp;
          <span onClick={() => navigate(DefaultRoutes.ModulesAndTests)} className='link'>
            here
          </span>
          &nbsp; to take a test.
        </Trans>
      ),
    },
    {
      q: t('WhereDoITakeTheTestForLevel', { ns: 'aboutCertification' }),
      a: (
        <Trans i18nKey='ToTakeATest' ns='aboutCertification'>
          To take a test for Level 1 or 2 go to Modules and Tests. If you want to apply for a Master Certification, please contact Trackman
          at&nbsp;
          <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>.
        </Trans>
      ),
    },
    {
      q: t('CanPgmPgaStudents', { ns: 'aboutCertification' }),
      a: <>{t('YesWeHaveASpecialProgram', { ns: 'aboutCertification' })}</>,
    },
    {
      q: t('DoYouHaveCollegePrograms', { ns: 'aboutCertification' }),
      a: (
        <Trans i18nKey='YesCollegesCanEasilyLink' ns='aboutCertification'>
          Yes. Colleges can easily link the Trackman curriculum to their online platforms including a Student Administration Tool to monitor
          progress and performance. If you want to learn more please contact us at&nbsp;
          <a href='mailto:master@trackmanuniversity.com'>master@trackmanuniversity.com</a>.
        </Trans>
      ),
    },
  ];

  return (
    <div className='about-tab-container'>
      <div className='about-tab-first'>
        <div className='text-gap'>
          <SectionTitle>
            <>{t('TrackmanCertificatePrograms', { ns: 'aboutCertification' })}</>
          </SectionTitle>
          <Paragraph>
            <>
              {t('ThroughOurMultiLevel', { ns: 'aboutCertification' })}{' '}
              {t('BecomingTrackmanCertifiedWillBroadenYourKnowledge', { ns: 'aboutCertification' })}
            </>
          </Paragraph>

          <Subtitle>
            <>{t('LearnBuildAndGrow', { ns: 'aboutCertification' })}</>
          </Subtitle>
          <Paragraph>
            <>{t('AsAProfessionalGraduateFromTrackman', { ns: 'aboutCertification' })}</>
          </Paragraph>

          <Subtitle>
            <>{t('TrackmanCertificate', { ns: 'aboutCertification' })}</>
          </Subtitle>
          <Paragraph>
            <strong>
              <>{t('WhenYouAreDone', { ns: 'aboutCertification' })}</>&nbsp;
              <span onClick={() => navigate('/Certifications/Certificate')} className='link'>
                <>{t('Certificate', { ns: 'aboutCertification' })}</>
              </span>
            </strong>
          </Paragraph>
        </div>
      </div>

      <div className='about-tab-second'>
        <div className='col-left text-gap'>
          <SectionTitle>
            <>{t('StartYourCertificationJourney', { ns: 'aboutCertification' })}</>
          </SectionTitle>
          <Subtitle>
            <>{t('LoyaltyPaysOff', { ns: 'aboutCertification' })}</>
          </Subtitle>
          <Paragraph>
            <>{t('MostOfOurUsersRemainCertified', { ns: 'aboutCertification' })}</>
          </Paragraph>
          <Paragraph>
            <Trans i18nKey='ToLearnMoreHaveALook' ns='aboutCertification'>
              To learn more have a look at our&nbsp;
              <strong onClick={() => navigate('/Certifications/Partners')} className='link'>
                Partners
              </strong>
              &nbsp;and&nbsp;
              <strong onClick={() => navigate('/Certifications/Masters')} className='link'>
                Masters
              </strong>
              &nbsp;profiles.
            </Trans>
          </Paragraph>
        </div>
        <div className='col-right offers-comparison-tab'>
          <div className={`basic-plan ${!ps.isUserMember ? 'current-plan' : ''}`}>
            {!ps.isUserMember ? currentPlanLabel : <></>}
            <Subtitle>
              <>{t('Basic', { ns: 'aboutCertification' })}</>
            </Subtitle>
            <SectionTitle>
              <>{t('Free', { ns: 'aboutCertification' })}</>
            </SectionTitle>
            <div className='offer-description'>
              <OfferDescriptionItem text={t('TrackmanDataParameterDefinitions', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('LearningMaterialsThatHelpYou', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('AccessToAllAdaptive', { ns: 'aboutCertification' })} />
            </div>
          </div>
          <div className={`premium-plan ${ps.isUserMember ? 'current-plan' : ''}`}>
            {ps.isUserMember ? currentPlanLabel : <></>}
            <Subtitle>
              <>{t('Premium', { ns: 'aboutCertification' })}</>
            </Subtitle>
            <div className='price-wrapper'>
              <SectionTitle>
                <>{t('$175', { ns: 'aboutCertification' })}</>
              </SectionTitle>
              <span>{t('YearlyPrice', { ns: 'aboutCertification' })}</span>
            </div>
            <p className='entry-fee-info'>
              <Trans i18nKey='OneTimeEntryFeeOf' ns='aboutCertification'>
                <span>One time entry fee</span>
                <span>of $300 applies</span>
              </Trans>
            </p>
            <div className='offer-description'>
              <OfferDescriptionItem text={t('OnlineLearningPlatform', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('LocatorPromotion', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('AllCertificationTests', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('FreeAccessToAll', { ns: 'aboutCertification' })} />
              <OfferDescriptionItem text={t('CertificateDiplomaFor', { ns: 'aboutCertification' })} />
            </div>
            {!ps.isUserMember ? (
              <Button
                className='styled-button primary'
                label={t('GoPremium', { ns: 'aboutCertification' })}
                onClick={() => navigate(DefaultRoutes.PurchaseMembership)}
                intent={'primary'}
                placeholder={undefined}
              />
            ) : (
              <></>
            )}
          </div>
          <div className='loyalty-program-tab'>
            <PricingTable />
          </div>
        </div>
      </div>

      <div className='about-tab-third'>
        <SectionTitle>
          <>{t('GeneralQuestions', { ns: 'aboutCertification' })}</>
        </SectionTitle>
        <div className='about-tab-qna-container'>
          {qna.map((qna) => (
            <FAQTile key={qna.q} question={qna.q} answer={qna.a} />
          ))}
        </div>
      </div>
    </div>
  );
});

export default AboutTab;
