import AdminWYSIWYGEditor from 'components/Admin/AdminWYSIWYGEditor/AdminWYSIWYGEditor';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const ParameterAdditionalArticles = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>Additional articles on this parameter</h2>
      {ps.parameter.languages.map((language) => {
        const additionalArticles = ps.parameter.articlesInfo?.find((article) => article.countryCode === language);

        return (
          <AdminWYSIWYGEditor
            key={language}
            text={additionalArticles?.text ? additionalArticles.text : ''}
            dataItems={ps.parameter.articlesInfo ? ps.parameter.articlesInfo : [{ countryCode: language, text: '' }]}
            language={additionalArticles?.countryCode ? additionalArticles?.countryCode : language}
          />
        );
      })}
    </div>
  );
});

export default ParameterAdditionalArticles;
