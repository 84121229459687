// todo - delete commented code and add translations where needed

import { NumericParameter } from './supportedVariablesAndParameters';
import { Unit } from './supportedUnits';
import { UnitSystem } from './supportedUnitSystems';

// Only numeric parameters can have units, hence NumericParameter type used
export const parameterToUnitByUnitSystem: { [key in NumericParameter]: { [key2 in UnitSystem]: Unit } } = {
  ClubSpeed: { 'en-US': 'MilesPerHour', 'ja-JP': 'MeterPerSecond', 'ko-KR': 'KilometersPerHour', 'en-GB': 'MilesPerHour' },
  AttackAngle: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  ClubPath: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  DynamicLoft: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  FaceAngle: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  SpinLoft: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  FaceToPath: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  SwingPlane: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  SwingDirection: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  // LowPoint: { 'en-US': 'Inch', 'ja-JP': 'Inch', 'ko-KR': 'Centimeter', 'en-GB': 'Centimeter' },
  BallSpeed: { 'en-US': 'MilesPerHour', 'ja-JP': 'MeterPerSecond', 'ko-KR': 'KilometersPerHour', 'en-GB': 'MilesPerHour' },
  SmashFactor: { 'en-US': 'UnitLess', 'ja-JP': 'UnitLess', 'ko-KR': 'UnitLess', 'en-GB': 'UnitLess' },
  LaunchAngle: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  LaunchDirection: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  SpinRate: { 'en-US': 'RotationsPerMinute', 'ja-JP': 'RotationsPerMinute', 'ko-KR': 'RotationsPerMinute', 'en-GB': 'RotationsPerMinute' },
  SpinAxis: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  MaxHeight: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  Carry: { 'en-US': 'Yard', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  Total: { 'en-US': 'Yard', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  CarrySide: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  TotalSide: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  LandingAngle: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  // FromPin: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  // Targetdistance: { 'en-US': 'Yard', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  // HangTime: { 'en-US': 'Second', 'ja-JP': 'Second', 'ko-KR': 'Second', 'en-GB': 'Second' },
  // LastData: { 'en-US': 'Yard', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  // Score: { 'en-US': 'UnitLess', 'ja-JP': 'UnitLess', 'ko-KR': 'UnitLess', 'en-GB': 'UnitLess' },
  // WindDirection: { 'en-US': 'Degree', 'ja-JP': 'Degree', 'ko-KR': 'Degree', 'en-GB': 'Degree' },
  WindSpeed: { 'en-US': 'MilesPerHour', 'ja-JP': 'MeterPerSecond', 'ko-KR': 'KilometersPerHour', 'en-GB': 'MeterPerSecond' },
  Humidity: { 'en-US': 'PercentageAsFloat', 'ja-JP': 'PercentageAsFloat', 'ko-KR': 'PercentageAsFloat', 'en-GB': 'PercentageAsFloat' },
  Temperature: { 'en-US': 'Fahrenheit', 'ja-JP': 'Celsius', 'ko-KR': 'Celsius', 'en-GB': 'Celsius' },
  Altitude: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  // LandingSpeed: { 'en-US': 'MilesPerHour', 'ja-JP': 'MeterPerSecond', 'ko-KR': 'MeterPerSecond', 'en-GB': 'MilesPerHour' },
  LandingBallSpeed: { 'en-US': 'MilesPerHour', 'ja-JP': 'MeterPerSecond', 'ko-KR': 'MeterPerSecond', 'en-GB': 'MilesPerHour' },
  // LandingSpinRate: {
  //   'en-US': 'RotationsPerMinute',
  //   'ja-JP': 'RotationsPerMinute',
  //   'ko-KR': 'RotationsPerMinute',
  //   'en-GB': 'RotationsPerMinute',
  // },
  LandingHeight: { 'en-US': 'Foot', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
  Distance: { 'en-US': 'Yard', 'ja-JP': 'Yard', 'ko-KR': 'Meter', 'en-GB': 'Meter' },
};
