import './MasterCard.scss';

import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';

export type MasterCardProps =
  | {
      profileUrl: string;
      name: string;
      description: string;
      delay?: number;
      skeleton?: false;
      handleSetIsModalOpen: (isOpen: boolean) => void;
      handleSetModalData: (data: { name: string; description: string; url: string }) => void;
    }
  | {
      skeleton: true;
      delay?: number;
    };

export const MasterCard = observer((props: MasterCardProps) => {
  return (
    <div
      className='master-card'
      style={{ animationDelay: `${props.delay}ms` }}
      onClick={() => {
        !props.skeleton && props.handleSetIsModalOpen(true);
        !props.skeleton && props.handleSetModalData({ name: props.name, description: props.description, url: props.profileUrl });
      }}
    >
      {props.skeleton ? (
        <Skeleton variant='rectangular' width={100} height={100} animation='wave' />
      ) : (
        <img className='master-card-img' src={props.profileUrl} alt={`${props.name}`} />
      )}
      <div className='master-card-info'>
        {props.skeleton ? <Skeleton variant='text' width={120} animation='wave' /> : <div className='master-card-name'>{props.name}</div>}
        {props.skeleton ? (
          <>
            <Skeleton variant='text' width={200} animation='wave' />
            <Skeleton variant='text' width={140} animation='wave' />
          </>
        ) : (
          <div className='master-card-description'>{props.description}</div>
        )}
      </div>
    </div>
  );
});

export default MasterCard;
