import './ClubDeliveryChart.scss';

import Arrow from '../CommonComponents/Arrow/Arrow';
import BallWithArrow from '../CommonComponents/BallWithArrow/BallWithArrow';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { ClubDelivery } from 'services/Calculator/CalculatorService';
import { ClubType } from 'components/FullCalculator/constants/selectOptions';
import CurrentClub from '../CommonComponents/CurrentClub/CurrentClub';
import FlipContainer from '../CommonComponents/FlipContainer/FlipContainer';
import Ground from '../CommonComponents/Ground/Ground';
import TeeBall from '../CommonComponents/TeeBall/TeeBall';
import { observer } from 'mobx-react-lite';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useMemo } from 'react';
import { useStores } from 'index';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: ClubDelivery;
};

const clubTypeRotation: { [key in ClubType]: number } = {
  Driver: 12,
  '6Iron': 28,
  PitchingWedge: 42,
};

const clubTypeToArrowAngle: { [key in ClubType]: number } = {
  Driver: 0,
  '6Iron': 0,
  PitchingWedge: 5,
};

const clubTypeCenterFromTop: { [key in ClubType]: string } = {
  Driver: '70%',
  '6Iron': '85%',
  PitchingWedge: '85%',
};

const ClubDeliveryChart = observer(({ calcOptions, calcResult }: Props) => {
  const cs = useStores().calculatorStore;
  const isLoadingCalcResult = useMemo(() => cs.isLoading, [cs.isLoading]);

  const { clubType } = useClubType(calcOptions);

  const centerFromLeft = '25%';

  return calcOptions.calculators.c ? (
    <div className='ClubDeliveryChart-container'>
      <FlipContainer
        front={
          <div className='club-delivery-flip-container'>
            <Ground view='side' />
            {clubType === 'Driver' && (
              <TeeBall type={'tee'} leftToRotationPoint={'28%'} topToRotationPoint={'95%'} style={{ height: '20%' }} rotation={40} />
            )}
            <Arrow
              color={'blue'}
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={clubTypeCenterFromTop[clubType]}
              rotationInDegrees={-calcOptions.calculators.c.AttackAngle}
            />
            <Arrow
              color={'red'}
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={clubTypeCenterFromTop[clubType]}
              rotationInDegrees={-calcOptions.calculators.c.DynamicLoft - clubTypeToArrowAngle[clubType]}
              length='20%'
            />
            <BallWithArrow
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={clubTypeCenterFromTop[clubType]}
              length={'45%'}
              rotation={-calcResult.LaunchData.LaunchAngle}
              className={isLoadingCalcResult ? 'animate-pulse' : ''}
            />
            <CurrentClub
              view='side'
              calcOptions={calcOptions}
              leftToHitPoint={centerFromLeft}
              topToHitPoint={clubTypeCenterFromTop[clubType]}
              style={{ height: '170%' }}
              rotation={-calcOptions.calculators.c.DynamicLoft + clubTypeRotation[clubType]}
            />
          </div>
        }
        back={
          <div className='club-delivery-flip-container'>
            <Ground view='top' />
            <Arrow
              color={'blue'}
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={'50%'}
              rotationInDegrees={calcOptions.calculators.c.ClubPath}
            />
            <Arrow
              color={'red'}
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={'calc(50% - 4px)'}
              rotationInDegrees={calcOptions.calculators.c.FaceAngle}
              length='20%'
            />
            <BallWithArrow
              leftToRotationPoint={centerFromLeft}
              topToRotationPoint={'50%'}
              length={'45%'}
              rotation={calcResult.LaunchData.LaunchDirection}
              className={isLoadingCalcResult ? 'animate-pulse' : ''}
            />
            <CurrentClub
              view='top'
              calcOptions={calcOptions}
              leftToHitPoint={centerFromLeft}
              topToHitPoint='50%'
              style={{ height: '170%' }}
              rotation={calcOptions.calculators.c.FaceAngle}
            />
          </div>
        }
        startInTopView={calcOptions.startInTopView}
      />
    </div>
  ) : (
    <></>
  );
});

export default ClubDeliveryChart;
