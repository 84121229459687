import './FlipContainer.scss';

import { forwardRef, useState } from 'react';

import FlipCard from 'components/FlipCard/FlipCard';

type Props = {
  front: JSX.Element;
  back: JSX.Element;
  startInTopView?: boolean;
};

const FlipContainer = forwardRef<HTMLDivElement, Props>(({ front, back, startInTopView = false }, ref?) => {
  const [flip, setFlip] = useState(startInTopView);

  return (
    <div className={`flip-container flip-wrapper ${flip ? 'flip' : ''}`} ref={ref}>
      <FlipCard position='front' handleFlip={setFlip} flip={flip}>
        {front}
      </FlipCard>
      <FlipCard position='back' handleFlip={setFlip} flip={flip}>
        {back}
      </FlipCard>
    </div>
  );
});

export default FlipContainer;
