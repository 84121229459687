import { AxiosInstance } from 'axios';
import { ParametersList } from 'types/trackmanParameters';
import { Result } from 'services/Result';

export class TrackmanParametersService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getTrackmanParameters = async () => {
    const response = await this.axiosInstance.get<Result<ParametersList>>(`api/TrackmanParameters`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching dashboard data.');
  };
}

export default TrackmanParametersService;
