import { AxiosInstance } from 'axios';
import { ClassModel } from 'types/organization/classes';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class ClassesService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getClasses = async (orgId: string, includeStudents: boolean) => {
    const response = await this.axiosInstance.get(`/api/classes?organizationId=${orgId}&includeStudents=${includeStudents}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('ClassesFetchError', { ns: 'classPage' }));
  };

  createNewClassAndUpdate = async (newClassData: ClassModel) => {
    const response = await this.axiosInstance.post<Result<ClassModel>>(`/api/classes`, { ...newClassData });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('CreateNewClassError', { ns: 'classPage' }));
  };

  addSelectedStudentsToClass = async (classId: string, invites: string[], students: string[]) => {
    const response = await this.axiosInstance.post(`/api/classes/${classId}/all`, { invites, students });

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('AddingStudentsToClassError', { ns: 'classPage' }));
  };

  renameClassAndUpdate = async (renamedClass: ClassModel) => {
    const response = await this.axiosInstance.put(`/api/classes`, { ...renamedClass });

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('SavingEditedClassError', { ns: 'classPage' }));
  };

  deleteClassAndUpdate = async (selectedClassId: string) => {
    const response = await this.axiosInstance.delete(`/api/classes/${selectedClassId}`);

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('DeleteClassError', { ns: 'classPage' }));
  };
}

export default ClassesService;
