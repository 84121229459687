import { configure, makeAutoObservable } from 'mobx';

import { AdminPartnerOrMasterModel } from 'types/admin/partners';
import MastersEditorService from '../../../services/Admin/Editors/MastersEditorService';
import MastersStore from 'stores/Masters/MastersStore';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export const cleanMaster: AdminPartnerOrMasterModel = {
  id: '',
  fullName: '',
  imageUrl: '',
  file: { id: '', data: new File([], '') },
  description: '',
};

export class MastersEditorStore {
  private mastersStore: MastersStore;
  private mastersEditorService: MastersEditorService;
  private ns: NotificationsStore;

  constructor(mastersStore: MastersStore, mastersEditorService: MastersEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.mastersStore = mastersStore;
    this.mastersEditorService = mastersEditorService;
    this.ns = notificationStore;
  }

  masters: AdminPartnerOrMasterModel[] = [{ ...cleanMaster }];
  master: AdminPartnerOrMasterModel = { ...cleanMaster };
  selectedMaster: AdminPartnerOrMasterModel = this.masters[0];
  isLoading: boolean = false;

  getMaster = async (id: string) => {
    try {
      this.isLoading = true;
      const response = await this.mastersEditorService.getMaster(id);
      this.master = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteMaster = async (id: string) => {
    try {
      await this.mastersEditorService.deleteMaster(id);
      this.mastersStore.getMasters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  saveNewMasterAndUpdate = async () => {
    try {
      await this.mastersEditorService.saveNewMaster(this.master);
      this.ns.addToast('success', 'New master has been saved successfully', 'success');
      this.mastersStore.getMasters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  saveExistingMasterAndUpdate = async () => {
    try {
      await this.mastersEditorService.saveExistingMaster(this.master);
      this.ns.addToast('success', 'Existing master has been saved successfully', 'success');
      this.mastersStore.getMasters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default MastersEditorStore;
