import { configure, makeAutoObservable } from 'mobx';

import { AdminPartnerOrMasterModel } from 'types/admin/partners';
import { NotificationsStore } from '../../NotificationsStore';
import PartnersEditorService from '../../../services/Admin/Editors/PartnersEditorService';
import PartnersStore from '../../Partners/PartnersStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export const cleanPartner: AdminPartnerOrMasterModel = {
  id: '',
  fullName: '',
  imageUrl: '',
  file: { id: '', data: new File([], '') },
  description: '',
};

export class PartnersEditorStore {
  private partnersStore: PartnersStore;
  private partnersEditorService: PartnersEditorService;
  private ns: NotificationsStore;

  constructor(partnersStore: PartnersStore, partnersEditorService: PartnersEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.partnersStore = partnersStore;
    this.partnersEditorService = partnersEditorService;
    this.ns = notificationStore;
  }

  partners: AdminPartnerOrMasterModel[] = [{ ...cleanPartner }];
  partner: AdminPartnerOrMasterModel = { ...cleanPartner };
  selectedPartner: AdminPartnerOrMasterModel = this.partners[0];
  isLoading: boolean = false;

  getPartner = async (id: string) => {
    try {
      this.isLoading = true;
      const response = await this.partnersEditorService.getPartner(id);
      this.partner = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deletePartner = async (id: string) => {
    try {
      await this.partnersEditorService.deletePartner(id);
      this.partnersStore.getPartners();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  saveNewPartnerAndUpdate = async () => {
    try {
      await this.partnersEditorService.saveNewPartner(this.partner);
      this.ns.addToast('success', 'Existing partner has been saved successfully', 'success');
      this.partnersStore.getPartners();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  saveExistingPartnerAndUpdate = async () => {
    try {
      await this.partnersEditorService.saveExistingPartner(this.partner);
      this.ns.addToast('success', 'Existing partner has been saved successfully', 'success');
      this.partnersStore.getPartners();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default PartnersEditorStore;
