import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const SelectCourse = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().coursesStore;

  return (
    <ProfileInput
      label={t('SelectCourse', { ns: 'organizationPage' })}
      value={cs.selectedCourseId ?? ''}
      onChange={(newValue) => (cs.selectedCourseId = newValue)}
      selectOptions={cs.coursesList.map((course) => {
        return {
          label: course.courseName === ' ' ? t('PleaseSelectCourse', { ns: 'organizationPage' }) : course.courseName,
          value: course.id,
        };
      })}
    />
  );
});

export default SelectCourse;
