import {
  clubTypes,
  crosswind,
  headwindTailwind,
  optimizationTypes,
  surfaceHardness,
  surfaceTypes,
  trajectoryTypes,
} from '../constants/selectOptions';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { SelectOptions } from '@trackman/web-shared-components';
import { SelectParameter } from '../constants/supportedVariablesAndParameters';
import { useMemo } from 'react';

const parameterToOptions = {
  ClubType: clubTypes,
  OptimizationType: optimizationTypes,
  TrajectoryType: trajectoryTypes,
  SurfaceType: surfaceTypes,
  SurfaceHardness: surfaceHardness,
};

const getWindDirectionOptions = (calcOptions: CalculatorOptions) => {
  const isCrosswind =
    calcOptions.variables.values.find(
      (variable) => variable.Key === 'calculators.s.WindDirection' || variable.Key === 'simulatorInput.WindDirection'
    )?.Options === 'crosswind';

  return isCrosswind ? crosswind : headwindTailwind;
};

type Props = {
  calcOptions: CalculatorOptions;
  parameter: SelectParameter;
};

const useSelectOptions = ({ calcOptions, parameter }: Props) => {
  const selectOptions = useMemo(
    () =>
      (parameter === 'WindDirection' ? getWindDirectionOptions(calcOptions) : parameterToOptions[parameter]) as unknown as SelectOptions[],
    [parameter, calcOptions]
  );

  return { selectOptions };
};

export default useSelectOptions;
