import './LessonTileProgressBar.scss';

import { ReactComponent as Checkmark } from '../../../images/icons/Checkmark.svg';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type LessonTileProgressBarProps = {
  progress: number;
  color: string;
};

export const LessonTileProgressBar = observer(({ progress, color }: LessonTileProgressBarProps) => {
  const { t } = useTranslation();

  const filledStyles = {
    width: `${progress}%`,
    backgroundColor: `${color}`,
  };

  return (
    <div className='lesson-tile-progress-bar-container' style={{ backgroundColor: `${color}40` }}>
      {progress < 100 && (
        <span>
          {progress}
          {t('%progress', { ns: 'learningMaterials' })}
        </span>
      )}
      {progress >= 100 && <span>{t('Complete', { ns: 'learningMaterials' })}</span>}
      <div className='lesson-tile-progress-bar-wrapper'>
        <div className='lesson-tile-progress-bar' style={filledStyles}></div>
        {progress >= 100 && (
          <div className='checkmark' style={{ backgroundColor: `${color}` }}>
            <Checkmark />
          </div>
        )}
      </div>
    </div>
  );
});

export default LessonTileProgressBar;
