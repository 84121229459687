import { AdminParameterPrimaryCategory } from 'types/admin/trackmanParameters';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const parameterPrimaryCategories: AdminParameterPrimaryCategory[] = ['Tee-to-green', 'Putting'];

export const ParameterPrimaryCategory = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>Parameter primary category</h2>
      <ProfileInput
        label='Primary category'
        value={ps.parameter.primaryCategory ?? ''}
        onChange={(newValue) => {
          if (parameterPrimaryCategories.includes(newValue as AdminParameterPrimaryCategory)) {
            ps.parameter.primaryCategory = newValue as AdminParameterPrimaryCategory;
          }
        }}
        selectOptions={parameterPrimaryCategories.map((primaryCategory) => {
          return {
            label: primaryCategory ? primaryCategory : '',
            value: primaryCategory,
          };
        })}
      />
    </div>
  );
});

export default ParameterPrimaryCategory;
