import { AxiosInstance } from 'axios';
import { LessonsSectionsCollection } from 'types/lessons';
import { Result } from 'services/Result';

export class DashboardService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getDashboardData = async () => {
    const response = await this.axiosInstance.get<Result<LessonsSectionsCollection>>(`api/dashboard`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching dashboard data.');
  };
}

export default DashboardService;
