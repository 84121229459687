import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import LearningMaterialsRoomService from '../../services/LearningMaterials/LearningMaterialsRoomService';
import { getErrorMessage } from 'utilities/ErrorHelper';
import { LearningMaterialsSectionsCollection } from 'types/learningMaterials';

configure({ enforceActions: 'never' });

export class LearningMaterialsRoomStore {
  private learningMaterialsRoomService: LearningMaterialsRoomService;
  private ns: NotificationsStore;

  constructor(learningMaterialsRoomService: LearningMaterialsRoomService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.learningMaterialsRoomService = learningMaterialsRoomService;
    this.ns = notificationStore;
  }

  learningMaterialsSectionsCollection: LearningMaterialsSectionsCollection = { courses: [], totalProgress: 0 };
  isLoading: boolean = false;

  getLearningMaterialsData = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialsRoomService.loadCourses();
      this.learningMaterialsSectionsCollection = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default LearningMaterialsRoomStore;
