import './LearningMaterialsLessonPicture.scss';

import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

type LearningMaterialsLessonPictureProps = {
  imageUrl: string;
  imageTitle: string;
};

export const LearningMaterialsLessonPicture = observer(({ imageUrl, imageTitle }: LearningMaterialsLessonPictureProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className='image-content lesson-content'>
        <img src={imageUrl} alt={imageTitle} onClick={handleOpen}></img>
      </div>
      <Modal open={open} onClose={handleClose} aria-labelledby={`${imageTitle} modal`} aria-describedby={`${imageTitle} fullscreen view`}>
        <div className='modal-container'>
          <img src={imageUrl} alt={imageTitle} style={{ width: '100%' }}></img>
        </div>
      </Modal>
    </>
  );
});

export default LearningMaterialsLessonPicture;
