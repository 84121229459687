import './FAQTile.scss';

import Paragraph from 'components/TextComps/Paragraph';
import Subtitle from 'components/TextComps/Subtitle';

type Props = {
  question: string;
  answer: JSX.Element;
};

const FAQTile = ({ question, answer }: Props) => {
  return (
    <div className='tile-container'>
      <Subtitle>
        <>{question}</>
      </Subtitle>
      <Paragraph>{answer}</Paragraph>
    </div>
  );
};

export default FAQTile;
