import './LearningMaterialsSectionsList.scss';
import '../../LessonTile/TilesSectionContainer.scss';

import { LearningMaterialsSection } from 'types/learningMaterials';
import LearningMaterialsSectionHeading from '../LearningMaterialsSectionHeading/LearningMaterialsSectionHeading';
import LessonTile from 'components/LessonTile/LessonTile';
import { observer } from 'mobx-react-lite';

type LearningMaterialsSectionsListProps = {
  section: LearningMaterialsSection;
};

export const LearningMaterialsSectionsList = observer(({ section }: LearningMaterialsSectionsListProps) => {
  return (
    <div className='learning-materials-section-container tiles-section-container'>
      <LearningMaterialsSectionHeading title={section.title} description={section.description} />
      <div className='learning-materials-section-lessons-list tiles-section-lessons-list'>
        {section.lessons.map((lesson, i) => (
          <LessonTile
            lesson={lesson}
            key={lesson.id}
            lessonNumber={i + 1}
            color={section.color}
            courseId={section.id}
            sectionTitle={section.title}
            bookIcon={true}
            comingSoon={lesson.comingSoon}
          />
        ))}
      </div>
    </div>
  );
});

export default LearningMaterialsSectionsList;
