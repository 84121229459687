import { LegendModel, ParameterModel } from 'types/trackmanParameters';

import Paragraph from 'components/TextComps/Paragraph';
import ParametersPlaceholder from '../../../images/TrackmanParameters/ParametersPlaceholder.svg';
import ThreeDModel from '../ThreeDModel/ThreeDModel';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  visibleParameter: ParameterModel;
};

export const TrackmanParameterImage = observer(({ visibleParameter }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='image-wrapper'>
      {visibleParameter.threeDModelUrl ? (
        <ThreeDModel modelScene={visibleParameter.threeDModelUrl} />
      ) : (
        <div className='main-img'>
          <img
            src={visibleParameter.mainImgUrl ? visibleParameter.mainImgUrl : ParametersPlaceholder}
            alt={visibleParameter.mainImgUrl ? visibleParameter.mainImgUrl : t('ImagePlaceholder', { ns: 'trackmanParameters' })}
          />
        </div>
      )}
      {visibleParameter.legendData ? (
        <div className='legend-wrapper'>
          {visibleParameter.legendData.map((legend: LegendModel, index: number) => (
            <div className='legend-item' key={`legend-${index}`}>
              <div className='legend-image'>
                <img src={legend.legendImageUrl} alt={legend.legendDescriptions} />
              </div>
              <Paragraph>
                <>{legend.legendDescriptions}</>
              </Paragraph>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});

export default TrackmanParameterImage;
