import AddedLessonsTable from 'components/Admin/LessonsTables/AddedLessonsTable/AddedLessonsTable';
import AddedOrganizationsTable from 'components/Admin/OrganizationsTables/AddedOrganizationsTable/AddedOrganizationsTable';
import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import AvailableLessonsTable from 'components/Admin/LessonsTables/AvailableLessonsTable/AvailableLessonsTable';
import AvailableOrganizationsTable from 'components/Admin/OrganizationsTables/AvailableOrganizationsTable/AvailableOrganizationsTable';
import CertificationSetting from 'components/Admin/StatusSettings/CertificationSetting';
import ComingSoonSetting from 'components/Admin/StatusSettings/ComingSoonSetting';
import { DescriptionInputs } from 'components/Admin/DescriptionInputs/DescriptionInputs';
import HighlightColor from 'components/Admin/HighlightColor/HighlightColor';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import PrerequisitesTable from 'components/Admin/PrerequisitesTable/PrerequisitesTable';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import VisibleSetting from 'components/Admin/StatusSettings/VisibleSetting';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditCourse = observer(() => {
  useSetPageTitle({ title: 'Edit course', subtitle: '' });

  const ces = useStores().courseEditorStore;
  const ls = useStores().lessonEditorStore;
  const org = useStores().organizationsEditorStore;
  const { courseId } = useParams();

  useEffect(() => {
    ces.coursesList.length === 0 && ces.getCourses();
  }, [ces]);

  useEffect(() => {
    if (courseId) {
      ces.getCourse(courseId);
    }
    return () => {
      ces.course = { ...ces.cleanCourse };
    };
  }, [ces, courseId]);

  useEffect(() => {
    ls.getLessons();
  }, [ls]);

  useEffect(() => {
    org.getOrganizations();
  }, [org]);

  const handleSaveCourse = () => {
    if (courseId) {
      ces.saveEditedCourseAndUpdateCourses();
    } else {
      ces.saveNewCourseAndUpdate();
    }
  };

  return (
    <>
      <div className='admin-content-container'>
        <AdminEditWarning />
        <LanguageSettings dataItem={ces.course} />
        <TitleInputs dataItem={ces.course} />
        <DescriptionInputs dataItem={ces.course} />
        <HighlightColor dataItem={ces.course} />
        <div className='admin-edit-section'>
          <h2>Settings</h2>
          <div className='checkboxes-container'>
            <VisibleSetting dataItem={ces.course} />
            <ComingSoonSetting dataItem={ces.course} />
            <CertificationSetting dataItem={ces.course} />
          </div>
        </div>
        <PrerequisitesTable tableTitle='courses' noDataText='No courses found.' dataList={ces.coursesList} dataItem={ces.course} />
        <AddedLessonsTable />
        <AvailableLessonsTable />
        <AddedOrganizationsTable dataItem={ces.course} />
        <AvailableOrganizationsTable dataItem={ces.course} />
        <div className='admin-footer'>
          <button
            className='styled-button primary'
            onClick={() => handleSaveCourse()}
            disabled={ces.course.titles.find((title) => title.countryCode === 'en-US')?.text === ''}
          >
            Save changes
          </button>
        </div>
      </div>
    </>
  );
});

export default EditCourse;
