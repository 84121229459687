import './TextComps.scss';

type Props = {
  children: React.ReactElement;
};

export const Paragraph = ({ children }: Props) => {
  return <p className='tm-paragraph'>{children}</p>;
};

export default Paragraph;
