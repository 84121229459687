import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { videoUrlValidator } from 'utilities/urlValidators';

type Props = {
  setVideoIdError: (value: boolean) => void;
  videoIdError: boolean;
};

export const ParameterVideos = observer(({ setVideoIdError, videoIdError }: Props) => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section video-settings-wrapper'>
      <h2>Parameter videos</h2>
      <Paragraph>
        <span className={videoIdError ? 'error' : ''}>
          Please add only ID of the video, you can find it in the YouTube link eg. https://www.youtube.com/watch?v=
          <strong>xZoMV_ap4s4</strong>
        </span>
      </Paragraph>
      {ps.parameter.videoUrls.map((video, index) => {
        return (
          <div className='inputs-container' key={`video-${index}-${video.videoUrl}`}>
            <ProfileInput label='Video title' value={video.label ?? ''} onChange={(newValue) => (video.label = newValue)} />
            <ProfileInput
              className={videoIdError ? 'error' : ''}
              label='Video ID'
              value={video.videoUrl ?? ''}
              onChange={(newValue) => {
                setVideoIdError(videoUrlValidator(newValue));
                video.videoUrl = newValue;
              }}
            />
            <button className='delete-button' onClick={() => ps.parameter.videoUrls.splice(index, 1)}>
              <TrashIcon />
            </button>
          </div>
        );
      })}
      <button className='styled-button secondary' onClick={() => ps.parameter.videoUrls.push({ videoUrl: '', label: '' })}>
        Add video
      </button>
    </div>
  );
});

export default ParameterVideos;
