import '../../../../pages/Organization/Organization.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
};

export const RenameClassModal = observer(({ isModalOn, setIsModalOn }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      aria-labelledby={t('RenameClass', { ns: 'classPage' })}
      aria-describedby={t('RenameSelectedClass', { ns: 'classPage' })}
      className='organization-modal'
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <PencilIcon />
            {t('EditClassName', { ns: 'classPage' })}
          </h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <p className='name-your-class' style={{ fontSize: '15px' }}>
            <strong>{t('NameYourClass', { ns: 'organizationPage' })}</strong>
          </p>
          <ProfileInput
            label={t('NameYourClass', { ns: 'organizationPage' })}
            value={cs.editedClass.name ?? ''}
            onChange={(newValue) => (cs.editedClass.name = newValue)}
          />
        </div>
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => setIsModalOn(false)}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button
            className='styled-button primary'
            onClick={() => {
              cs.renameClassAndUpdate();
              setIsModalOn(false);
            }}
          >
            {t('Save', { ns: 'classPage' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default RenameClassModal;
