import '../SideMenu.scss';

import { getUserInitials, getUsername } from 'utilities/userInfo';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultRoutes } from 'Routes';
import MenuItem from 'components/MenuItem/MenuItem';
import { ReactComponent as Profile } from '../../../images/icons/menu/Profile.svg';
import { ReactComponent as SignOut } from '../../../images/icons/menu/Sign_Out.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const ProfileSubmenu = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState('');
  const { t } = useTranslation();
  const as = useStores().accountStore;
  const ps = useStores().profileStore;

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    ps.getProfile();
  }, [ps]);

  return (
    <div className='submenu-container'>
      <div className='submenu-heading'>
        {ps.profileData.pictureUrl && ps.profileData.pictureUrl !== '/Frontend/Images/no_avatar.svg' ? (
          <img className='submenu-heading--icon' src={ps.profileData.pictureUrl} alt={t('ProfilePicture', { ns: 'editProfile' })} />
        ) : (
          <div className='profile-picture-initials'>{getUserInitials(ps.profileData.firstName, ps.profileData.lastName)}</div>
        )}
        {getUsername(ps.profileData.firstName, ps.profileData.lastName)}
      </div>
      <div className='submenu-menu'>
        <MenuItem
          label={t('ProfileSettings', { ns: 'general' })}
          onClick={() => navigate(DefaultRoutes.Profile)}
          isActive={isActive.includes(DefaultRoutes.Profile)}
          icon={<Profile />}
          isSubmenu
        />
        <MenuItem label={t('LogOut', { ns: 'general' })} onClick={as.logOut} isActive={false} icon={<SignOut />} isSubmenu />
      </div>
    </div>
  );
});

export default ProfileSubmenu;
