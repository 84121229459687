import './Slider.scss';

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getNumericDisplayValue, getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';
import { useNumericConstants, useSliderLimits } from 'components/FullCalculator/hooks/useConverterConstants';

import BaseSlider from '@mui/material/Slider';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { SliderVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { displayToRadarUnits } from 'components/FullCalculator/helpers/unitConverters';
import { isSliderHidden } from '../helpers/hiddenVariables';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import useValueSetter from 'components/FullCalculator/hooks/useValueSetter';

type Props = {
  calcOptions: CalculatorOptions;
  setCalcOptions: Dispatch<SetStateAction<CalculatorOptions>>;
  sliderVariable: SliderVariable;
  htmlColor: string;
};

const Slider = ({ calcOptions, setCalcOptions, sliderVariable, htmlColor }: Props) => {
  const { setCalcVariable } = useValueSetter(setCalcOptions);
  const { clubType } = useClubType(calcOptions);
  const { label, unit, precision, symbol } = useNumericConstants(sliderVariable);
  const { sliderMin, sliderMax, sliderDefault } = useSliderLimits(sliderVariable, clubType);

  const initialValueInDisplayUnitsRounded = useMemo(() => {
    const initialValueInRadarUnits = getValueByPath({ readableObject: calcOptions, readPath: sliderVariable });
    return getNumericDisplayValue({ valueInRadarUnits: initialValueInRadarUnits, unit, precision });
  }, [calcOptions, sliderVariable, unit, precision]);

  const [valueInDisplayUnits, setValueInDisplayUnits] = useState(initialValueInDisplayUnitsRounded); // valueInDisplayUnits is supposed to live only in this component

  useEffect(() => {
    setValueInDisplayUnits(sliderDefault);
  }, [clubType]); // purposefully left out sliderDefault from dependency array

  useEffect(() => {
    const valueInRadarUnits = displayToRadarUnits(valueInDisplayUnits, unit);
    setCalcVariable(sliderVariable, valueInRadarUnits);
  }, [valueInDisplayUnits, setCalcVariable, sliderVariable, unit]);

  return isSliderHidden(clubType, sliderVariable) ? (
    <></>
  ) : (
    <div className='slider-container'>
      <label>
        <span className='label-title'>{label}</span>
        <span>
          {valueInDisplayUnits.toFixed(precision)}
          {symbol}
        </span>
      </label>
      <BaseSlider
        sx={{ color: htmlColor }}
        value={valueInDisplayUnits}
        aria-label={label}
        min={sliderMin}
        max={sliderMax}
        step={Math.pow(10, -1 * precision)}
        valueLabelDisplay='auto'
        onChange={(e, newValue) => {
          if (typeof newValue === 'number') setValueInDisplayUnits(newValue);
        }}
      />
    </div>
  );
};

export default Slider;
