import BallImg from './images/Ball.png';
import { CSSProperties } from 'react';
import TeeImg from './images/tee.png';

type Props = {
  type: Type;
  leftToRotationPoint: string;
  topToRotationPoint: string;
  rotation?: number;
  style?: CSSProperties;
  className?: string;
};

type Type = 'tee' | 'ball';

const typeToImg: { [key in Type]: string } = {
  tee: TeeImg,
  ball: BallImg,
};

const typeToRotationPoint: { [key in Type]: { x: string; y: string } } = {
  tee: {
    x: '20%',
    y: '90%',
  },
  ball: {
    x: '50%',
    y: '50%',
  },
};

const TeeBall = ({ type, leftToRotationPoint, topToRotationPoint, rotation = 0, style, className }: Props) => {
  return (
    <img
      src={typeToImg[type]}
      style={{
        position: 'absolute',
        left: leftToRotationPoint,
        top: topToRotationPoint,
        transform: `translate(-${typeToRotationPoint[type].x}, -${typeToRotationPoint[type].y}) rotate(${rotation}deg)`,
        transformOrigin: `${typeToRotationPoint[type].x} ${typeToRotationPoint[type].y}`,
        transition: 'transform 150ms ease',
        ...style,
      }}
      className={className}
      alt={`${type} type`}
    />
  );
};

export default TeeBall;
