import { AxiosInstance, AxiosResponse } from 'axios';

import { AdminLessonModel } from 'types/admin/lessons';

interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class LessonEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getLessons = async () => {
    const response = await this.axiosInstance.get(`/api/admin/lessons`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting lessons.');
  };

  getLesson = async (lessonId: string) => {
    const response = await this.axiosInstance.get(`/api/admin/lessons/${lessonId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting the lesson.');
  };

  saveNewLesson = async (lessonData: AdminLessonModel) => {
    const response = await this.axiosInstance.post<AdminLessonModel, AxiosResponse<ApiResponse<AdminLessonModel>>>(`/api/admin/lessons`, {
      ...lessonData,
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new lesson.');
  };

  saveEditedLesson = async (lessonId: string, lessonData: AdminLessonModel) => {
    const response = await this.axiosInstance.put(`/api/admin/lessons/${lessonId}`, { ...lessonData });

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while saving edited lesson.');
  };

  deleteLessonAndUpdate = async (lessonId: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/lessons/${lessonId}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting this lesson.');
  };
}

export default LessonEditorService;
