import { BillingInfo, BillingInfoUpdate } from 'types/billing';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class BillingService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getBillingInfo = async () => {
    const response = await this.axiosInstance.get<Result<BillingInfo | string>>(`/api/Payment/GetBillingInfo`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    } else if (response.status === 404) {
      return 'accountNotFound';
    }
    throw Error(i18next.t('BillingInfoFetchError', { ns: 'organizationPage' }));
  };

  updateBillingAddress = async (address: BillingInfoUpdate) => {
    const response = await this.axiosInstance.post(`/api/Payment/UpdateBillingAddress`, address);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('UpdateBillingAddressError', { ns: 'organizationPage' }));
  };

  updateCreditCard = async (cardTokenId: string) => {
    const response = await this.axiosInstance.post(`/api/Payment/UpdateCreditCard`, {
      CardTokenId: cardTokenId,
    });

    if (response.data) return response;

    throw Error(i18next.t('UpdateCreditCardError', { ns: 'organizationPage', responseData: response.data }));
  };

  sendThreeDSecureRequest = async (cardTokenId: string, actionTokenId: string) => {
    const response = await this.axiosInstance.post(`/api/Payment/UpdateCreditCard`, {
      CardTokenId: cardTokenId,
      ActionTokenId: actionTokenId,
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data;
    }
    throw Error(i18next.t('3DSecureRequestError', { ns: 'organizationPage' }));
  };

  getSubscriptions = async () => {
    const response = await this.axiosInstance.get(`/api/Payment/GetSubscription`);

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('FetchingSubscriptionsError', { ns: 'organizationPage' }));
  };
}

export default BillingService;
