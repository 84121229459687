import {
  InputVariable,
  SelectVariable,
  SliderVariable,
  allSelectVariables,
  allSliderVariables,
} from '../constants/supportedVariablesAndParameters';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { useMemo } from 'react';

const useGetVariables = (
  calcOptions: CalculatorOptions
): {
  allVariablesInThisCalc: InputVariable[];
  sliderVariablesInThisCalc: SliderVariable[];
  selectVariablesInThisCalc: SelectVariable[];
} => {
  const allVariablesInThisCalc = useMemo(() => calcOptions.variables.values.map((val) => val.Key), [calcOptions]);

  // Type casting needed due to limitations of TS on .includes() method
  const sliderVariablesInThisCalc = useMemo(
    () =>
      allVariablesInThisCalc.filter((variable) => allSliderVariables.includes(variable as unknown as SliderVariable)) as SliderVariable[],
    [allVariablesInThisCalc]
  );
  const selectVariablesInThisCalc = useMemo(
    () =>
      allVariablesInThisCalc.filter((variable) => allSelectVariables.includes(variable as unknown as SelectVariable)) as SelectVariable[],
    [allVariablesInThisCalc]
  );

  return { allVariablesInThisCalc, sliderVariablesInThisCalc, selectVariablesInThisCalc };
};

export default useGetVariables;
