import { useEffect, useMemo, useState } from 'react';

import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import { radarToDisplayUnits } from '../helpers/unitConverters';
import { useNumericConstants } from './useConverterConstants';

const useEdgeValuesOfTrajectories = (trajectories: TrajectoryPoint[][]) => {
  const trajectoriesFlat = trajectories.flat(1);
  const carry = trajectories[0] ?? [];

  const { unit: xUnit } = useNumericConstants('Distance');
  const { unit: yUnit } = useNumericConstants('LandingData.MaxHeight');

  const [xMaxTraj, setXMaxTraj] = useState({ current: 0, old: 0 });

  useEffect(() => {
    const trajectoriesFlat = trajectories.flat(1);
    const newXMaxTraj = radarToDisplayUnits(Math.max(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.X)), xUnit);
    setXMaxTraj((xMaxTraj) => ({
      current: Number.isFinite(newXMaxTraj) ? newXMaxTraj : 0,
      old: xMaxTraj.current,
    }));
  }, [xUnit, trajectories]);

  const trajLimits = useMemo(() => {
    if (trajectoriesFlat.length === 0) return { xMinTraj: 0, xCarryTraj: 0, yMinTraj: 0, yMaxTraj: 0, zMinTraj: 0, zMaxTraj: 0 }; //without this condition this hook is first returning Infinity values

    const xMinTraj = radarToDisplayUnits(Math.min(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.X)), xUnit);
    const xCarryTraj = radarToDisplayUnits(Math.max(...carry.map((trajectoryPoint) => trajectoryPoint.X)), xUnit);

    const yMinTraj = radarToDisplayUnits(Math.min(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.Y)), yUnit);
    const yMaxTraj = radarToDisplayUnits(Math.max(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.Y)), yUnit);

    const zMinTraj = radarToDisplayUnits(Math.min(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.Z)), 'UnitLess');
    const zMaxTraj = radarToDisplayUnits(Math.max(...trajectoriesFlat.map((trajectoryPoint) => trajectoryPoint.Z)), 'UnitLess');

    return { xMinTraj, xCarryTraj, yMinTraj, yMaxTraj, zMinTraj, zMaxTraj };
  }, [xUnit, yUnit, carry, trajectoriesFlat]);
  return { xMaxTraj: xMaxTraj.current, xMaxTrajOld: xMaxTraj.old, ...trajLimits };
};

export default useEdgeValuesOfTrajectories;
