import { configure, makeAutoObservable } from 'mobx';

import { AdminCoursesList } from 'types/admin/courses';
import CourseEditorService from 'services/Admin/Editors/CourseEditorService';
import { OrganizationList } from 'types/organization/organization';
import OrganizationsService from 'services/CoachSection/OrganizationsService';

configure({ enforceActions: 'never' });

type UserPermissions = {
  organizations: OrganizationList;
  courses: AdminCoursesList | string;
};

export class PermissionsStore {
  private courseEditorService: CourseEditorService;
  private orgService: OrganizationsService;

  constructor(courseEditorService: CourseEditorService, orgService: OrganizationsService) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.courseEditorService = courseEditorService;
    this.orgService = orgService;
  }

  isLoadingUserPermissions: boolean = true; // initialized as true to display loading immediately
  permissionsError: boolean = false;
  userPermissions: UserPermissions = {
    organizations: [],
    courses: '',
  };

  checkUserPermissions = async () => {
    try {
      this.isLoadingUserPermissions = true;

      const [organizationsResult, coursesResult] = await Promise.allSettled([
        this.orgService.getOrganizations(),
        this.courseEditorService.getCourses(),
      ]);

      if (organizationsResult.status === 'fulfilled') {
        this.userPermissions = {
          ...this.userPermissions,
          organizations: organizationsResult.value,
        };
      }

      if (coursesResult.status === 'fulfilled') {
        this.userPermissions = {
          ...this.userPermissions,
          courses: coursesResult.value,
        };
      }
    } catch (ex) {
      console.error(ex);
    }

    this.isLoadingUserPermissions = false;
  };
}

export default PermissionsStore;
