type Props = {
  swingPlaneAngle: number;
};
const SwingPlaneAngle = ({ swingPlaneAngle }: Props) => {
  const angle = Math.abs(swingPlaneAngle);
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = (angle / 360) * circumference;

  return (
    <div className='swing-plane-container'>
      <svg height='100%' width='50%' className='swing-plane-arc'>
        <circle
          stroke='orange'
          strokeWidth='5'
          fill='none'
          cx='0'
          cy='0'
          r={radius}
          strokeDasharray={`${strokeDasharray},${circumference - strokeDasharray}`} // svg trick to draw semicircle, comma separated values must add up to circumference
        />
      </svg>
      <div className='swing-plane-horizontal-line' />
      <div
        style={{
          transform: `translateX(-50%) rotate(${swingPlaneAngle}deg)`,
        }}
        className='swing-plane-rotating-line'
      />
    </div>
  );
};

export default SwingPlaneAngle;
