import './ManageUserData.scss';
import '../Admin.scss';

import { Checkbox } from '@trackman/web-shared-components';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const ManageUserData = observer(() => {
  useSetPageTitle({ title: 'User', subtitle: '' });

  const userStore = useStores().manageUserDataStore;

  return (
    <div className='admin-content-container manage-user-data-container'>
      <div className='admin-edit-section'>
        <h2>Membership</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.toggleMembershipUserId ?? ''}
              onChange={(newValue) => (userStore.toggleMembershipUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.toggleMembership()}>
              Toggle Premium
            </button>
          </div>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.removeMembershipUserId ?? ''}
              onChange={(newValue) => (userStore.removeMembershipUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.removeMembership()}>
              Remove Premium
            </button>
          </div>
        </div>
      </div>
      <div className='admin-edit-section'>
        <h2>Manage user certification</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.giveLevel1CertUserId ?? ''}
              onChange={(newValue) => (userStore.giveLevel1CertUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.updatePlayerLevel1Certification()}>
              Give Level 1 Certification
            </button>
          </div>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.giveLevel2CertUserId ?? ''}
              onChange={(newValue) => (userStore.giveLevel2CertUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.updatePlayerLevel2Certification()}>
              Give Level 2 Certification
            </button>
          </div>
        </div>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.resetLevel1CertUserId ?? ''}
              onChange={(newValue) => (userStore.resetLevel1CertUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.resetPlayerLevel1Certification()}>
              Reset Level 1 Certification
            </button>
          </div>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.resetLevel2CertUserId ?? ''}
              onChange={(newValue) => (userStore.resetLevel2CertUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.resetPlayerLevel2Certification()}>
              Reset Level 2 Certification
            </button>
          </div>
        </div>
      </div>
      <div className='admin-edit-section'>
        <h2>Manage Master role</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.giveLevelMasterUserId ?? ''}
              onChange={(newValue) => (userStore.giveLevelMasterUserId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.updatePlayerLevelMaster()}>
              Add Master role
            </button>
          </div>
        </div>
      </div>
      <div className='admin-edit-section'>
        <h2>User data</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User email'
              value={userStore.userSearchEmail ?? ''}
              onChange={(newValue) => (userStore.userSearchEmail = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.getUserIdByEmail()}>
              Get user ID by email
            </button>
            {userStore.foundUserId ? <p className='found-data'>User ID: {userStore.foundUserId}</p> : null}
          </div>
        </div>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput
              label='User ID'
              value={userStore.userSearchId ?? ''}
              onChange={(newValue) => (userStore.userSearchId = newValue)}
            />
            <button className='styled-button secondary' onClick={() => userStore.getUserEmailById()}>
              Get user email by ID
            </button>
            {userStore.foundUserEmail ? <p className='found-data'>User email: {userStore.foundUserEmail}</p> : null}
          </div>
        </div>
      </div>
      <div className='admin-edit-section'>
        <h2>Caches</h2>
        <div className='inputs-container'>
          <Checkbox label='[cache key]' placeholder='' withoutBorder reversed onChange={() => {}} checked={false} />{' '}
          {/* functionality to be added - separate task in backlog */}
        </div>
        <div className='inputs-container'>
          <button className='styled-button secondary' onClick={() => {}}>
            Invalidate selected caches
          </button>
        </div>
      </div>
    </div>
  );
});

export default ManageUserData;
