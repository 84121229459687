import { ClassModel, ClassesList } from 'types/organization/classes';
import { configure, makeAutoObservable } from 'mobx';

import ClassesService from '../../../services/CoachSection/classes/ClassesService';
import { ClassesTableRows } from 'types/organization/organization';
import { NotificationsStore } from '../../NotificationsStore';
import OrganizationsStore from '../OrganizationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });
export class ClassesStore {
  private classesService: ClassesService;
  private orgStore: OrganizationsStore;
  private ns: NotificationsStore;

  constructor(classesService: ClassesService, orgStore: OrganizationsStore, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.classesService = classesService;
    this.orgStore = orgStore;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  classesList: ClassesList = [];
  selectedClassId: string = '';

  isAddClassModalOpen: boolean = false;
  isCreatingNewClass: boolean = false;
  cleanClass: ClassModel = {
    id: '',
    organizationId: '',
    name: '',
    invites: [],
    students: [],
  };
  newClass = { ...this.cleanClass };
  editedClass: ClassModel = { ...this.cleanClass };
  addToSelectedClassId: string = '';
  studentsToAdd: string[] = [];
  invitesToAdd: string[] = [];
  isDeletingClass: boolean = false;
  searchInput: string = '';
  filteredList: ClassesTableRows[] = [];

  getClasses = async (includeStudents: boolean) => {
    try {
      this.isLoading = true;
      const response = await this.classesService.getClasses(this.orgStore.selectedOrg.id, includeStudents);

      this.classesList = response;
      this.selectedClassId = this.classesList.length ? this.classesList[0].id : '';
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  createNewClassAndUpdate = async () => {
    try {
      this.isCreatingNewClass = true;
      this.newClass.organizationId = this.orgStore.selectedOrg.id;
      await this.classesService.createNewClassAndUpdate(this.newClass);
      this.getClasses(true);
      this.ns.addToast('success', i18next.t('AddedNewClass', { ns: 'classPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.newClass = { ...this.cleanClass };
    this.isCreatingNewClass = false;
  };

  addSelectedStudentsToClass = async () => {
    try {
      await this.classesService.addSelectedStudentsToClass(this.addToSelectedClassId, this.invitesToAdd, this.studentsToAdd);
      this.ns.addToast('success', i18next.t('AddedStudentsToSelectedClass', { ns: 'classPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  renameClassAndUpdate = async () => {
    try {
      this.editedClass.organizationId = this.orgStore.selectedOrg.id;
      await this.classesService.renameClassAndUpdate(this.editedClass);
      this.getClasses(true);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.editedClass = { ...this.cleanClass };
  };

  deleteClassAndUpdate = async (selectedClassId: string) => {
    try {
      this.isDeletingClass = true;
      await this.classesService.deleteClassAndUpdate(selectedClassId);
      this.getClasses(true);
      this.ns.addToast('success', i18next.t('ClassDeleted', { ns: 'classPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isDeletingClass = false;
  };
}

export default ClassesStore;
