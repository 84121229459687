import { AxiosInstance } from 'axios';
import { InvoiceData } from 'types/invoices';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class InvoiceService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getInvoices = async () => {
    const response = await this.axiosInstance.get<Result<InvoiceData[] | string>>(`/api/Payment/GetInvoices`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    } else if (response.status === 404) {
      return 'accountNotFound';
    }
    throw Error(i18next.t('BillingInfoFetchError', { ns: 'organizationPage' }));
  };

  downloadPdfInvoice = async (invoiceNumber: number) => {
    const response = await this.axiosInstance.post(`/api/Payment/DownloadPdfInvoice`, `invoiceNumber=${invoiceNumber}`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
    });
    if (response.data && !response.data.isError) {
      return response.data;
    }
    throw Error(i18next.t('InvoiceDownloadError', { ns: 'organizationPage' }));
  };
}

export default InvoiceService;
