import './SwingDirectionChart.scss';

import Arrow from '../CommonComponents/Arrow/Arrow';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import CurrentClub from '../CommonComponents/CurrentClub/CurrentClub';
import Ground from '../CommonComponents/Ground/Ground';
import { SwingDirection } from 'services/Calculator/CalculatorService';
import SwingDirectionImgAndArc from './components/SwingDirectionImgAndArc';
import SwingPlaneAngle from './components/SwingPlaneAngle';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: SwingDirection;
};

const SwingDirectionChart = ({ calcOptions, calcResult }: Props) => {
  return calcOptions.calculators.d ? (
    <div className='swingDirectionChartContainer'>
      <div className='chart-subcontainer'>
        <Ground view='top' />
        <Arrow color={'blue'} leftToRotationPoint={'50%'} topToRotationPoint={'50%'} rotationInDegrees={calcResult.ClubPath} />
        <SwingDirectionImgAndArc
          swingDirectionAngle={calcOptions.calculators.d.SwingDirection}
          radiusInPx={250}
          calcOptions={calcOptions}
        />
        <CurrentClub view='top' calcOptions={calcOptions} leftToHitPoint='50%' topToHitPoint='50%' style={{ height: '70%' }} />
      </div>
      <div className='chart-subcontainer'>
        <Ground view='side' />
        <SwingPlaneAngle swingPlaneAngle={calcOptions.calculators.d.SwingPlane} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SwingDirectionChart;
