import './AddNewClassButton.scss';

import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const AddNewClassButton = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classesStore = useStores().classesStore;

  return (
    <button
      onClick={() => {
        classesStore.isAddClassModalOpen = true;
        if (location.pathname !== '/Organization/Classes') navigate('/Organization/Classes');
      }}
      className='add-button styled-button primary'
    >
      <PlusIcon />
      <span>{t('AddANewClass', { ns: 'organizationPage' })}</span>
    </button>
  );
});

export default AddNewClassButton;
