import { Unit } from '../constants/supportedUnits';

export const radarToDisplayUnits = (value: number, displayUnit: Unit) => {
  switch (displayUnit) {
    case 'UnitLess':
      return value;
    case 'MeterPerSecond':
      return value;
    case 'Meter':
      return value;
    case 'Degree':
      return value;
    case 'RotationsPerMinute':
      return value;
    case 'Second':
      return value;
    case 'Celsius':
      return value;
    case 'MilesPerHour':
      return value * (3600 / 1609.344);
    case 'Yard':
      return value * (1 / 0.9144);
    case 'Foot':
      return value * (1 / 0.3048);
    case 'Fahrenheit':
      return (9 / 5) * value + 32;
    case 'KilometersPerHour':
      return value * 3.6;
    case 'PercentageAsFloat':
      return value * 100;
    case 'Centimeter':
      return value * 100;
    case 'Inch':
      return value / 0.0254;
    default:
      return +value; // plus sign ensures that returned value is a number
  }
};

export const displayToRadarUnits = (value: number, displayUnit: Unit) => {
  switch (displayUnit) {
    case 'UnitLess':
      return value;
    case 'MeterPerSecond':
      return value;
    case 'Meter':
      return value;
    case 'Degree':
      return value;
    case 'RotationsPerMinute':
      return value;
    case 'Second':
      return value;
    case 'Celsius':
      return value;
    case 'MilesPerHour':
      return value * 0.44704;
    case 'KilometersPerHour':
      return value * 0.277777778;
    case 'Yard':
      return value * 0.9144;
    case 'Foot':
      return value * 0.3048;
    case 'Fahrenheit':
      return (value - 32) * (5 / 9);
    case 'PercentageAsFloat':
      return value / 100;
    case 'Centimeter':
      return value / 100;
    case 'Inch':
      return value * 0.0254;
    default:
      return +value; // plus sign ensures that returned value is a number
  }
};
