import { configure, makeAutoObservable } from 'mobx';

import GameProgressService from 'services/CoachSection/progress/GameProgressService';
import { NotificationsStore } from '../../NotificationsStore';
import { Students } from 'types/organization/students';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class GameProgressStore {
  private gameProgressService: GameProgressService;
  private ns: NotificationsStore;

  constructor(gameProgressService: GameProgressService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.gameProgressService = gameProgressService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  classProgressForSelectedGame: Students = [];
  filteredList: Students = [];
  searchStudentInput: string = '';
  isSearchForStudentsVisible: boolean = true;

  getClassProgressForSelectedCourse = async (classId: string, courseLessonsIds: Array<string>) => {
    try {
      this.isLoading = true;
      const response = await this.gameProgressService.getClassProgressForSelectedCourse(classId, courseLessonsIds);
      this.classProgressForSelectedGame = response;
      this.filteredList = [...this.classProgressForSelectedGame];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default GameProgressStore;
