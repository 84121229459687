export const chartPaddingSide = { x: 11.5, y: 22.5 };
// const chartPaddingSide = { x: 10, y: 21 };
export const chartPaddingTop = { x: 27.5, y: 0 };

export const pixelsPerMeterOnSvg = 2.83444444; // equal for both top and side view

export const svgWidth = 1417; // equal for both top and side view

export const maxSideInRadarUnits = 55;

export type CurrentView = 'club' | 'fullPath' | 'landing';
