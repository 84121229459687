import { configure, makeAutoObservable } from 'mobx';

import ClassesStore from '../classes/ClassesStore';
import { NotificationsStore } from '../../NotificationsStore';
import OrganizationsStore from '../OrganizationsStore';
import { Students } from 'types/organization/students';
import StudentsService from '../../../services/CoachSection/students/StudentsService';
import { filterUsersByClass } from 'utilities/organizationDataFilter';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });

export class StudentsStore {
  private studentsService: StudentsService;
  private orgStore: OrganizationsStore;
  private classesStore: ClassesStore;
  private ns: NotificationsStore;

  constructor(
    studentsService: StudentsService,
    orgStore: OrganizationsStore,
    classesStore: ClassesStore,
    notificationStore: NotificationsStore
  ) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.studentsService = studentsService;
    this.classesStore = classesStore;
    this.orgStore = orgStore;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  students: Students = [];
  searchInput: string = '';
  filteredList: Students = [];
  isSeeStudentsTabActive: boolean = true;
  numberofStudents: number = 0;

  getStudents = async () => {
    try {
      this.isLoading = true;
      const response = await this.studentsService.getStudents(this.orgStore.selectedOrg.id);
      this.students = response;
      this.filteredList = filterUsersByClass(this.students, this.classesStore.selectedClassId) as Students;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getNumberOfStudents = async () => {
    try {
      const response = await this.studentsService.getNumberOfStudents(this.orgStore.selectedOrg.id);
      this.numberofStudents = response;
      return response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  removeStudents = async (studentsToRemove: Array<string>) => {
    try {
      this.isLoading = true;
      await this.studentsService.removeStudents(this.orgStore.selectedOrg.id, studentsToRemove);
      this.getStudents();
      this.ns.addToast('success', i18next.t('StudentsRemovedSuccessfully', { ns: 'studentsPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default StudentsStore;
