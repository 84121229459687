import { ReactComponent as AddStudentsIcon } from 'images/icons/AddUsers.svg';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const InviteStudentsButton = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const iss = useStores().inviteStudentsStore;

  return (
    <button
      onClick={() => {
        location.pathname !== '/Organization/Students' && navigate('/Organization/Students');
        iss.isInviteModalOpen = true;
      }}
      className='styled-button primary invite-students-button add-button'
    >
      <AddStudentsIcon />
      <span>{t('InviteStudents', { ns: 'classPage' })}</span>
    </button>
  );
});

export default InviteStudentsButton;
