import { useEffect, useState } from 'react';

import ImageGalleryModal from 'components/Admin/Modals/ImageGalleryModal/ImageGalleryModal';
import { LanguageType } from 'types/languages';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type Props = {
  pictureId: string;
  countryCode: LanguageType;
};

export const LearningMaterialsPicture = observer(({ pictureId, countryCode }: Props) => {
  const ms = useStores().mediaStore;
  const [isModalOn, setIsModalOn] = useState(false);
  const language = getLanguageText(countryCode);
  const invalidPictureIdFromDB = '00000000-0000-0000-0000-000000000000'; // fix for the TM learning materials added in the past

  useEffect(() => {
    if (pictureId && pictureId !== invalidPictureIdFromDB) {
      ms.getMedia(pictureId, language);
    }
  }, [ms, pictureId, language]);

  return (
    <div className='media-item'>
      <h2>File for {language}</h2>
      {pictureId && pictureId !== invalidPictureIdFromDB ? (
        <img
          src={ms.mediaCollection[language]?.fileUrl ? ms.mediaCollection[language].fileUrl : ''}
          alt={ms.mediaCollection[language]?.fileName ? ms.mediaCollection[language].fileName : ''}
        />
      ) : (
        <></>
      )}
      <div className='input-wrapper'>
        <ProfileInput
          label={ms.mediaCollection[language]?.fileName ? ms.mediaCollection[language].fileName : 'Select a file'}
          value={ms.mediaCollection[language]?.fileName ? ms.mediaCollection[language].fileName : ''}
          onChange={() => {}}
        />
        <button
          className='styled-button secondary'
          onClick={() => {
            setIsModalOn(true);
            ms.getMediaInSelectedFolder();
          }}
        >
          Select
        </button>
      </div>
      <ImageGalleryModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} countryCode={countryCode} />
    </div>
  );
});

export default LearningMaterialsPicture;
