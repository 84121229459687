import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import SendInvitationsTestService from '../../services/StudentSection/SendInvitationsTestService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class SendInvitationsTestStore {
  private sendInvitationsTestService: SendInvitationsTestService;
  private ns: NotificationsStore;

  constructor(sendInvitationsTestService: SendInvitationsTestService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.sendInvitationsTestService = sendInvitationsTestService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;

  sendInvitation = async () => {
    try {
      this.isLoading = true;
      await this.sendInvitationsTestService.sendInvitation();
      this.ns.addToast('success', 'Student invitation has been sent', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  resendInvitation = async () => {
    try {
      this.isLoading = true;
      await this.sendInvitationsTestService.resendInvitation();
      this.ns.addToast('success', 'Student invitation has been resent', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  sendInstructorInvitation = async () => {
    try {
      this.isLoading = true;
      await this.sendInvitationsTestService.sendInstructorInvitation();
      this.ns.addToast('success', 'Instructor invitation has been sent', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default SendInvitationsTestStore;
