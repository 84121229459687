import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useTranslation } from 'react-i18next';

export const Feedback = observer(() => {
  const { t } = useTranslation();
  useSetPageTitle({ title: t('Feedback', { ns: 'general' }), subtitle: '' });

  return <div style={{ margin: 50 }}>Feedback</div>;
});

export default Feedback;
