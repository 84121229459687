import { NumericParameter } from './supportedVariablesAndParameters';
import { UnitSystem } from './supportedUnitSystems';

// todo - delete commented code and add translations where needed

// Only numeric parameters can have precision, hence NumericParameter type used
export const parameterToPrecisionByUnitSystem: { [key in NumericParameter]: { [key2 in UnitSystem]: number } } = {
  ClubSpeed: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 0, 'en-GB': 1 },
  AttackAngle: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  ClubPath: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  DynamicLoft: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  FaceAngle: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  SpinLoft: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  FaceToPath: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  SwingPlane: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  SwingDirection: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  // LowPoint: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  BallSpeed: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 0, 'en-GB': 1 },
  SmashFactor: { 'en-US': 2, 'ja-JP': 2, 'ko-KR': 2, 'en-GB': 2 },
  LaunchAngle: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  LaunchDirection: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  SpinRate: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  SpinAxis: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  MaxHeight: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Carry: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Total: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  CarrySide: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  TotalSide: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  LandingAngle: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // FromPin: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // Targetdistance: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // HangTime: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // LastData: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // Score: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // WindDirection: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
  WindSpeed: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Humidity: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Temperature: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Altitude: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // LandingSpeed: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  LandingBallSpeed: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  // LandingSpinRate: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  LandingHeight: { 'en-US': 0, 'ja-JP': 0, 'ko-KR': 0, 'en-GB': 0 },
  Distance: { 'en-US': 1, 'ja-JP': 1, 'ko-KR': 1, 'en-GB': 1 },
};
