import '../../../../pages/Admin/Admin.scss';
import '../AdminModal.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import FullCalculator from 'components/FullCalculator/FullCalculator';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
};

export const CalculatorPreviewModal = observer(({ isModalOn, setIsModalOn }: ModalProps) => {
  const lms = useStores().learningMaterialStore;

  const closeModal = useCallback(() => {
    localStorage.removeItem('calculatorPreviewCulture');
    setIsModalOn(false);
  }, [setIsModalOn]);

  return (
    <Modal open={isModalOn} onClose={() => closeModal()} className='admin-modal admin-calculator-preview-modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>Calculator Preview</h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <FullCalculator
            initialCalculatorOptions={JSON.parse(lms.learningMaterial?.calculatorOptions)}
            calcKind={lms.learningMaterial?.calculatorKind}
            htmlColor='#467d80'
          />
        </div>
        <div className='modal-footer'>
          <button className='styled-button tertiary' onClick={() => closeModal()}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default CalculatorPreviewModal;
