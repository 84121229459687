import './Footer.scss';

import { DefaultRoutes } from 'Routes';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const policies = [
    {
      target: DefaultRoutes.CookiePolicy,
      name: t('CookiePolicy', { ns: 'general' }),
    },
    {
      target: DefaultRoutes.TermsAndConditions,
      name: t('TermsAndConditions', { ns: 'general' }),
    },
    {
      target: DefaultRoutes.PrivacyPolicy,
      name: t('PrivacyPolicy', { ns: 'general' }),
    },
  ];

  return (
    <div className='footer-container'>
      <div className='column'>
        <h3>{t('FooterPolicies', { ns: 'general' })}</h3>
        <ul>
          {policies.map(({ target, name }) => (
            <li key={name} onClick={() => navigate(target)}>
              {name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default Footer;
