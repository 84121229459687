import './WelcomeModal.scss';

import { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import SectionTitle from 'components/TextComps/SectionTitle';
import Subtitle from 'components/TextComps/Subtitle';
import WelcomeModalPagesShortcut from 'images/WelcomeModalPagesShortcut.png';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const WelcomeModal = observer(() => {
  const [isModalOn, setIsModalOn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.isNewSiteVisited) {
      setIsModalOn(true);
      localStorage.isNewSiteVisited = 'true';
    }
  }, []);

  return (
    <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='welcome-modal'>
      <div className='modal-container'>
        <div className='modal-body'>
          <div className='modal-text-container'>
            <Subtitle>{t('WelcomeModalSubtitle', { ns: 'general' })}</Subtitle>
            <SectionTitle>{t('WelcomeModalSectionTitle', { ns: 'general' })}</SectionTitle>
            <Paragraph>{t('WelcomeToOurUpdatedWebsite', { ns: 'general' })}</Paragraph>
            <Paragraph>{t('ExploreEnhancedTrackmanParameterDefinitions', { ns: 'general' })}</Paragraph>
            <Paragraph>{t('AdditionallyWeveIntroducedNewPuttingModules', { ns: 'general' })}</Paragraph>
            <Paragraph>{t('WeWillContinueWorkingOnNewUpdates', { ns: 'general' })}</Paragraph>
            <Button className='styled-button primary' onClick={() => setIsModalOn(false)}>
              {t('StartExploring', { ns: 'general' })}
            </Button>
          </div>
          <div className='modal-image-container'>
            <div className='pages-shortcut'>
              <img src={WelcomeModalPagesShortcut} alt='Pages shortcut' />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default WelcomeModal;
