import './TrackmanParameters.scss';

import { ParameterModel, ParameterPrimaryCategory, ParametersList, VideoUrlModel } from 'types/trackmanParameters';
import { useEffect, useMemo, useState } from 'react';

import ArticlesInfo from 'components/TrackmanParameters/ArticlesInfo/ArticlesInfo';
import DOMPurify from 'dompurify';
import InfoBar from 'components/InfoBar/InfoBar';
import NoParametersFound from 'components/TrackmanParameters/NoParametersFound/NoParametersFound';
import Paragraph from 'components/TextComps/Paragraph';
import ParameterDefinition from 'components/TrackmanParameters/ParameterDefinition/ParameterDefinition';
import ParameterVideos from 'components/TrackmanParameters/ParameterVideos/ParameterVideos';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TrackmanParameterImage from 'components/TrackmanParameters/TrackmanParameterImage/TrackmanParameterImage';
import UsageInfo from 'components/TrackmanParameters/UsageInfo/UsageInfo';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const findIndexByKey = (list: ParametersList | ParameterPrimaryCategory, key: string) => {
  return Object.keys(list).findIndex((item) => item === key);
};

const findPrimaryCategory = (list: ParametersList, primaryCategory: string) => {
  return Object.keys(list).find((item) => item === primaryCategory);
};

const findCategory = (primaryCategory: ParameterPrimaryCategory, category: string) => {
  return Object.keys(primaryCategory).find((item) => item === category);
};

const findParameterTitle = (list: ParametersList, primaryCategory: string, category: string, parameter: string): string | null => {
  let foundParam = list[primaryCategory][category].find((item) => item.title?.replace(/\s/g, '') === parameter);

  return foundParam ? foundParam.title?.replace(/\s/g, '') : null;
};

const formatStringForTranslation = (input: string): string => {
  return input
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const TrackmanParameters = observer(() => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleVideo, setVisibleVideo] = useState<VideoUrlModel | null>(null);
  const primaryCategoryParam = searchParams.get('primaryCategoryKey');
  const categoryParam = searchParams.get('categoryKey');
  const parameterParam = searchParams.get('parameterKey');
  const [visibleParameter, setVisibleParameter] = useState<ParameterModel | null>(null);
  const tps = useStores().trackmanParametersStore;

  useSetPageTitle({
    title: t('TrackmanParameters', { ns: 'trackmanParameters' }),
    subtitle: t('CheckOutTheDefinitions', { ns: 'learningMaterials' }),
  });

  useEffect(() => {
    tps.getTrackmanParameters();
  }, [tps]);

  useEffect(() => {
    if (Object.keys(tps.parametersList).length) {
      const primaryCategory =
        (primaryCategoryParam && findPrimaryCategory(tps.parametersList, primaryCategoryParam)) || Object.keys(tps.parametersList)[0];
      const category =
        (categoryParam && findCategory(tps.parametersList[primaryCategory], categoryParam)) ||
        Object.keys(tps.parametersList[primaryCategory])[0];
      const parameter =
        (parameterParam && findParameterTitle(tps.parametersList, primaryCategory, category, parameterParam)) ||
        tps.parametersList[primaryCategory][category][0].title?.replace(/\s/g, '');
      const visibleParameter =
        tps.parametersList[primaryCategory][category].find((item) => item.title?.replace(/\s/g, '') === parameter) ||
        tps.parametersList[primaryCategory][category][0];

      setVisibleParameter(visibleParameter);
      setSearchParams({ primaryCategoryKey: primaryCategory, categoryKey: category, parameterKey: parameter });
    }
  }, [tps.parametersList, primaryCategoryParam, categoryParam, parameterParam, setSearchParams]);

  useEffect(() => {
    visibleParameter?.videoUrls?.length ? setVisibleVideo(visibleParameter.videoUrls[0]) : setVisibleVideo(null);
  }, [visibleParameter]);

  const activePrimaryCategoryTab = useMemo(() => {
    let index = primaryCategoryParam ? findIndexByKey(tps.parametersList, primaryCategoryParam) : 0;
    return index >= 0 ? index : 0;
  }, [primaryCategoryParam, tps.parametersList]);

  const activeCategoryTab = useMemo(() => {
    let index = 0;
    if (primaryCategoryParam && categoryParam && tps.parametersList[primaryCategoryParam]) {
      index = findIndexByKey(tps.parametersList[primaryCategoryParam], categoryParam);
    }
    return index >= 0 ? index : 0;
  }, [primaryCategoryParam, categoryParam, tps.parametersList]);

  const activeTitleTab = useMemo(() => {
    let index = 0;
    if (
      primaryCategoryParam &&
      categoryParam &&
      tps.parametersList[primaryCategoryParam] &&
      tps.parametersList[primaryCategoryParam][categoryParam] &&
      parameterParam
    ) {
      index = tps.parametersList[primaryCategoryParam][categoryParam].findIndex(
        (parameter: ParameterModel) => parameter.title?.replace(/\s/g, '') === parameterParam
      );
    }
    return index >= 0 ? index : 0;
  }, [parameterParam, primaryCategoryParam, categoryParam, tps.parametersList]);

  DOMPurify.addHook('afterSanitizeAttributes', (node: HTMLElement) => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
    if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
      node.setAttribute('xlink:show', 'new');
    }
  });

  if (!tps.isParametersLoading && Object.keys(tps.parametersList).length === 0) {
    return <NoParametersFound />;
  }

  return (
    <>
      <div className='trackman-parameters-primary-categories'>
        <Tabs value={activePrimaryCategoryTab}>
          {Object.keys(tps.parametersList).map((primaryCategory, index) => {
            return (
              <Tab
                label={t(formatStringForTranslation(primaryCategory), { ns: 'trackmanParameters' })}
                key={`${primaryCategory}-${index}`}
                onClick={() => setSearchParams({ primaryCategoryKey: Object.keys(tps.parametersList)[index] })}
              />
            );
          })}
        </Tabs>
      </div>
      <div className='trackman-parameters-wrapper'>
        <div className='content-container'>
          <InfoBar text={t('TranslationsComingSoonInfo', { ns: 'trackmanParameters' })} />
          {visibleParameter && primaryCategoryParam && categoryParam && tps.parametersList[primaryCategoryParam] ? (
            tps.parametersList[primaryCategoryParam][categoryParam]?.map((parameter: ParameterModel, index: number) => {
              return (
                <div
                  className='definitions-subject-wrapper'
                  key={`${parameter.title}-${index}`}
                  role='tabpanel'
                  hidden={activeTitleTab !== index}
                  id={`simple-tabpanel-${index}`}
                >
                  <div className='parameter-content-box'>
                    <h2>{visibleParameter.title}</h2>
                    <TrackmanParameterImage visibleParameter={visibleParameter} />
                    <div className='parameter-details'>
                      {visibleParameter.definition && <ParameterDefinition visibleParameter={visibleParameter} />}
                      {visibleParameter.usageInfo && <UsageInfo visibleParameter={visibleParameter} />}
                      {visibleVideo && (
                        <ParameterVideos
                          visibleParameter={visibleParameter}
                          visibleVideo={visibleVideo}
                          setVisibleVideo={setVisibleVideo}
                        />
                      )}
                      {visibleParameter.articlesInfo && <ArticlesInfo visibleParameter={visibleParameter} />}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className='categories-and-parameters-container'>
          <div className='categories-container'>
            <Paragraph>
              <>{t('Categories', { ns: 'trackmanParameters' })}</>
            </Paragraph>
            <Tabs value={activeCategoryTab}>
              {primaryCategoryParam &&
                tps.parametersList[primaryCategoryParam] &&
                Object.entries(tps.parametersList[primaryCategoryParam]).map(([category, values], index) => {
                  return (
                    values && (
                      <Tab
                        label={t(category, { ns: 'trackmanParameters' })}
                        key={`${category}-${index}`}
                        onClick={() =>
                          setSearchParams({
                            primaryCategoryKey: primaryCategoryParam,
                            categoryKey: category,
                            parameterKey: values[0]?.title?.replace(/\s/g, ''),
                          })
                        }
                      />
                    )
                  );
                })}
            </Tabs>
          </div>
          <ul className='parameters-list'>
            {visibleParameter && primaryCategoryParam && categoryParam && tps.parametersList[primaryCategoryParam] ? (
              tps.parametersList[primaryCategoryParam][categoryParam]?.map((parameter: ParameterModel) => {
                return (
                  <li
                    key={parameter.id}
                    className={visibleParameter.title === parameter.title ? 'active' : ''}
                    onClick={() => {
                      setSearchParams({
                        primaryCategoryKey: primaryCategoryParam || Object.keys(tps.parametersList)[0],
                        categoryKey: categoryParam || Object.keys(tps.parametersList[primaryCategoryParam])[0],
                        parameterKey: parameter.title?.replace(/\s/g, ''),
                      });
                      parameter.videoUrls?.length ? setVisibleVideo(parameter.videoUrls[0]) : setVisibleVideo(null);
                    }}
                  >
                    {t(parameter.title, { ns: 'trackmanParameters' })}
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
});

export default TrackmanParameters;
