import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { getLanguageText } from '../../../../utilities/languages';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  title: string;
  kind: string;
  languages: string;
  description: string;
};

export const LearningMaterialsList = observer(() => {
  useSetPageTitle({ title: 'Learning Materials List', subtitle: '' });
  const lms = useStores().learningMaterialStore;
  const [searchValue, setSearchValue] = useState('');
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    lms.getLearningMaterials();
  }, [lms]);

  useEffect(() => {
    lms.filteredList = lms.learningMaterialsList.filter((material) =>
      material.titles
        .find((title) => title.countryCode === 'en-US')
        ?.text.toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }, [lms, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      lms.filteredList.map((material) => {
        return {
          id: material.id,
          title: material.titles.find((title) => title.countryCode === 'en-US')?.text,
          kind: material.kind,
          languages: material.languages ? material.languages.map((lng) => getLanguageText(lng)).join(', ') : '',
          description: material.descriptions.find((description) => description.countryCode === 'en-US')?.text,
        };
      }) as RowsDisplay[],
    [lms.filteredList]
  );

  const deleteLearningMaterial = useCallback(() => {
    lms.deleteLearningMaterialAndUpdate();
    setIsModalOn(false);
  }, [lms, setIsModalOn]);

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field'>
            <input
              className='search-input'
              placeholder={'Search'}
              name='search-for-lesson'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.LearningMaterialsEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New learning material</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 300 }}>Name</TableCell>
              <TableCell sx={{ width: 300 }}>Kind</TableCell>
              <TableCell sx={{ width: 900 }}>Languages</TableCell>
              <TableCell sx={{ width: 200 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>
                      <div className='description-tooltip'>
                        {row.title.toLowerCase()}
                        <Tooltip
                          title={row.description ? row.description : 'No description'}
                          placement='bottom'
                          className='table-cell-tooltip'
                        >
                          <InfoIcon />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>{row.kind}</TableCell>
                    <TableCell>{row.languages}</TableCell>
                    <TableCell>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.LearningMaterialsEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className={`row-button ${lms.isLoading ? 'disabled' : ''}`}
                          onClick={() => {
                            lms.selectedMaterialId = row.id;
                            setIsModalOn(true);
                          }}
                          disabled={lms.isLoading}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No learning materials found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal
        isModalOn={isModalOn}
        setIsModalOn={setIsModalOn}
        deleteAction={deleteLearningMaterial}
        itemName='learning material'
      />
    </div>
  );
});

export default LearningMaterialsList;
