import '../AdminModal.scss';

import { AdminCourseModel, AdminCoursesList, AdminItemTitleType } from 'types/admin/courses';
import { AdminLessonModel, AdminLessonsList } from 'types/admin/lessons';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { LanguageType } from 'types/languages';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

type ModalProps = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  modalTitle: string;
  noDataText: string;
  dataList: AdminLessonsList | AdminCoursesList;
  dataItem: AdminLessonModel | AdminCourseModel;
};

type RowsDisplay = {
  id: string;
  title: string;
  languages: string;
};

export const PrerequisitesModal = observer(({ isModalOn, setIsModalOn, modalTitle, noDataText, dataList, dataItem }: ModalProps) => {
  const rowsDisplay: RowsDisplay[] = useMemo(() => {
    let prerequisites: AdminLessonsList | AdminCoursesList = [];
    if (dataList[0] && 'lessonIds' in dataList[0]) {
      prerequisites = (dataList as AdminCoursesList).filter((item) => item.id && !dataItem.prerequisiteIds.includes(item.id));
    } else {
      prerequisites = (dataList as AdminLessonsList).filter((item) => item.id && !dataItem.prerequisiteIds.includes(item.id));
    }
    return prerequisites.map((prerequisite) => {
      return {
        id: prerequisite.id,
        title: prerequisite.titles.find((title: AdminItemTitleType) => title.countryCode === 'en-US')?.text,
        languages: prerequisite.languages ? prerequisite.languages.map((lng: LanguageType) => getLanguageText(lng)).join(', ') : '',
      };
    }) as RowsDisplay[];
  }, [dataItem.prerequisiteIds, dataList, JSON.stringify(dataItem.prerequisiteIds), JSON.stringify(dataList)]);

  return (
    <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='admin-modal admin-content-container'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>{modalTitle}</h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body tmu-table-wrapper admin-edit-section'>
          <Table sx={{ minWidth: 750 }} aria-labelledby='coursesTable' className='tmu-table admin-table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 500 }}>Name</TableCell>
                <TableCell sx={{ width: 500 }}>Languages</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsDisplay.length ? (
                rowsDisplay.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>
                        <button className='row-button' onClick={() => dataItem.prerequisiteIds?.push(row.id)}>
                          <PlusIcon />
                        </button>
                        {row.title.toLowerCase()}
                      </TableCell>
                      <TableCell>{row.languages}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Paragraph>
                      <>{noDataText}</>
                    </Paragraph>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className='modal-footer'>
          <button className='styled-button tertiary' onClick={() => setIsModalOn(false)}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default PrerequisitesModal;
