import '../AdminEditWarning.scss';

import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as WarningIcon } from '../../../../images/icons/Warning.svg';
import { observer } from 'mobx-react-lite';

export const DevelopmentEnvWarning = observer(() => {
  return (
    <div className='warning-wrapper'>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>Please be aware you are running this app on the development enviroment.</>
        </Paragraph>
      </div>
    </div>
  );
});

export default DevelopmentEnvWarning;
