import { AdminCourseModel, AdminCoursesList } from 'types/admin/courses';
import { configure, makeAutoObservable } from 'mobx';

import CourseEditorService from '../../../services/Admin/Editors/CourseEditorService';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class CourseEditorStore {
  private courseEditorService: CourseEditorService;
  private ns: NotificationsStore;

  constructor(courseEditorService: CourseEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.courseEditorService = courseEditorService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  coursesList: AdminCoursesList = [];
  cleanCourse: AdminCourseModel = {
    lessonIds: [],
    color: '#009688',
    sortOrder: 0,
    certification: false,
    organizationIds: [],
    prerequisiteIds: [],
    visible: false,
    comingSoon: false,
    id: '',
    titles: [{ countryCode: 'en-US', text: '' }],
    descriptions: [{ countryCode: 'en-US', text: '' }],
    languages: ['en-US'],
  };
  course: AdminCourseModel = { ...this.cleanCourse };
  selectedCourseId: string = '';

  getCourses = async () => {
    try {
      this.isLoading = true;
      const response = await this.courseEditorService.getCourses();

      if (response !== 'unauthorized' && typeof response === 'object') {
        this.coursesList = response;
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getCourse = async (courseId: string) => {
    try {
      this.isLoading = true;
      const response = await this.courseEditorService.getCourse(courseId);
      this.course = response;
      this.course.prerequisiteIds = this.course.prerequisiteIds || [];
      this.course.organizationIds = this.course.organizationIds || [];
      this.course.lessonIds = this.course.lessonIds || [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteCourseAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.courseEditorService.deleteCourseAndUpdate(this.selectedCourseId);
      this.getCourses();
      this.ns.addToast('success', 'Course has been deleted successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedCourseId = '';
    this.isLoading = false;
  };

  saveNewCourseAndUpdate = async () => {
    try {
      this.isLoading = true;
      delete this.course.id;
      const response = await this.courseEditorService.saveNewCourse(this.course);
      this.course = response;
      this.ns.addToast('success', 'New course has been saved successfully', 'success');
      this.course = { ...this.cleanCourse };
      this.getCourses();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedCourseAndUpdateCourses = async () => {
    try {
      this.isLoading = true;
      await this.courseEditorService.saveEditedCourse(this.course);
      this.getCourses();
      this.ns.addToast('success', 'Course has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  updateSortOrder = async (id: string, sortDirection: boolean) => {
    try {
      this.isLoading = true;
      await this.courseEditorService.updateSortOrder(id, sortDirection);
      this.getCourses();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedCourseId = '';
    this.isLoading = false;
  };
}

export default CourseEditorStore;
