import { StudyRoomLessonModel, StudyRoomLessonsList } from 'types/admin/studyroomLessons';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class StudyroomLessonEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getLessons = async () => {
    const response = await this.axiosInstance.get<Result<StudyRoomLessonsList>>(`/api/admin/study-room-lessons/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting study lessons list.');
  };

  getLesson = async (studyRoomLessonId: string) => {
    const response = await this.axiosInstance.get<Result<StudyRoomLessonModel>>(`/api/admin/study-room-lessons/${studyRoomLessonId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting study lesson.');
  };

  saveNewLesson = async (lesson: StudyRoomLessonModel) => {
    const response = await this.axiosInstance.post<Result<StudyRoomLessonModel>>(`/api/admin/study-room-lessons/`, { ...lesson });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new study lesson.');
  };

  saveEditedLesson = async (lesson: StudyRoomLessonModel) => {
    const response = await this.axiosInstance.put<Result<StudyRoomLessonModel>>(`/api/admin/study-room-lessons`, { ...lesson });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new study lesson.');
  };

  deleteLesson = async (studyRoomLessonId: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/study-room-lessons/${studyRoomLessonId}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting the lesson.');
  };
}

export default StudyroomLessonEditorService;
