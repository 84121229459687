import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { useEffect, useState } from 'react';

import { Loader } from '@trackman/web-shared-components';
import OrderConfirmationPage from '../OrderConfirmationPage/OrderConfirmationPage';
import PurchaseMembershipForm from 'components/PurchaseMembership/PurchaseMembershipForm/PurchaseMembershipForm';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const PurchaseMembershipPage = observer(() => {
  const { t } = useTranslation();
  const bs = useStores().buySubscriptionStore;
  const [subscriptionPurchased, setSubscriptionPurchased] = useState(false);

  useSetPageTitle({
    title: t('GoPremium', { ns: 'aboutCertification' }),
    subtitle: t('UnlockTheFullPotential', { ns: 'purchaseMembership' }),
  });

  useEffect(() => {
    setSubscriptionPurchased(bs.subscriptionPurchased);
  }, [bs.subscriptionPurchased]);

  return (
    <>
      {!subscriptionPurchased ? (
        <div className='go-premium-wrapper' style={bs.isTokenHandling ? { opacity: '0.5' } : {}}>
          <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
            <Elements>
              <PurchaseMembershipForm />
            </Elements>
          </RecurlyProvider>
          {bs.isTokenHandling && (
            <div className='loader-wrapper'>
              <Loader size='large' />
            </div>
          )}
        </div>
      ) : (
        <OrderConfirmationPage />
      )}
    </>
  );
});

export default PurchaseMembershipPage;
