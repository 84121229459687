import './LearningMaterialsSectionHeading.scss';

import { observer } from 'mobx-react-lite';

type LearningMaterialsSectionHeadingProps = {
  title: string;
  description: string;
};

export const LearningMaterialsSectionHeading = observer(({ title, description }: LearningMaterialsSectionHeadingProps) => {
  return (
    <div className='learning-materials-section-heading'>
      <h2>{title.toLowerCase()}</h2>
      <p>{description}</p>
    </div>
  );
});

export default LearningMaterialsSectionHeading;
