import './PricingTable.scss';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const PricingTable = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      <div className='pricing-table'>
        <table>
          <tbody>
            <tr>
              <th>{t('Year', { ns: 'aboutCertification' })}</th>
              <th>{t('YearlyPrice', { ns: 'aboutCertification' })}</th>
            </tr>
            <tr>
              <td className='table-orange'>{t('1', { ns: 'aboutCertification' })}</td>
              <td>{t('Price475', { ns: 'aboutCertification' })}</td>
            </tr>
            <tr>
              <td className='table-orange'>{t('2', { ns: 'aboutCertification' })}</td>
              <td>{t('Price175', { ns: 'aboutCertification' })}</td>
            </tr>
            <tr>
              <td className='table-orange'>{t('3', { ns: 'aboutCertification' })}</td>
              <td>{t('Price175', { ns: 'aboutCertification' })}</td>
            </tr>
            <tr>
              <td className='table-orange'>{t('4WithAsterisk', { ns: 'aboutCertification' })}</td>
              <td>{t('Price175', { ns: 'aboutCertification' })}</td>
            </tr>
            <tr>
              <td className='table-orange'>{t('5WithGreaterSign', { ns: 'aboutCertification' })}</td>
              <td>{t('Price175', { ns: 'aboutCertification' })}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='pricing-table-asterisk'>{t('RecertificationRequiredEvery3rdYear', { ns: 'aboutCertification' })}</div>
    </>
  );
});
