export enum ProfessionalLevel {
  Level1 = '1',
  Level2 = '2',
  Master = 'Master',
}

export type AvailableCertificates = {
  'tmu-Level1': boolean;
  'tmu-Level2': boolean;
  'tmu-Master': boolean;
};
