export interface IConfigurationService {
  getWindowOrigin(): string;
}

export class ConfigurationService implements IConfigurationService {
  getWindowOrigin(): string {
    if (process.env.NODE_ENV === 'development') {
      return 'https://localhost:' + process.env.REACT_APP_DEV_PORT;
    } else if (process.env.NODE_ENV === 'production') {
      if (!window.location.origin) {
        (window.location as any).origin =
          window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
    }
    return window.location.origin;
  }
}
