import i18n from 'i18next';
import { langResources } from './locales/index';

const isLangAvailable = (lang: string) => {
  return Object.keys(langResources).includes(lang) ? lang : '';
};

export async function i18nInit() {
  await i18n.init({
    lng: isLangAvailable(localStorage.i18nextLng ?? '') || 'en-US',
    debug: false,
    resources: langResources,
    fallbackLng: 'en-US',
    interpolation: { escapeValue: false },
  });
}
