import './Stacks.scss';

import CertificateTile from 'components/CertificateTile/CertificateTile';
import certLevel1Icon from 'images/Certification/cert_lvl_1.png';
import certLevel2Icon from 'images/Certification/cert_lvl_2.png';
import { useTranslation } from 'react-i18next';

export const CertificationsStack = () => {
  const { t } = useTranslation();

  return (
    <div className='stack-container'>
      <div className='stack-certification-tile stack-certification-tile--first'>
        <CertificateTile
          subtitle={t('ProfessionalLevel', { level: 1, ns: 'certificate' })}
          altText={t('CertificateLevelBadgeAltText', { level: 1, ns: 'certificate' })}
          icon={certLevel1Icon}
        />
      </div>
      <div className='stack-certification-tile stack-certification-tile--second'>
        <CertificateTile
          subtitle={t('ProfessionalLevel', { level: 2, ns: 'certificate' })}
          altText={t('CertificateLevelBadgeAltText', { level: 2, ns: 'certificate' })}
          icon={certLevel2Icon}
        />
      </div>
    </div>
  );
};

export default CertificationsStack;
