import Arrow from '../../CommonComponents/Arrow/Arrow';
import { BallLaunch } from 'services/Calculator/CalculatorService';
import BallWithArrow from '../../CommonComponents/BallWithArrow/BallWithArrow';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import CurrentClub from '../../CommonComponents/CurrentClub/CurrentClub';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStores } from 'index';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: BallLaunch;
};

const SideClub = observer(({ calcOptions, calcResult }: Props) => {
  const cs = useStores().calculatorStore;
  const isLoadingCalcResult = useMemo(() => cs.isLoading, [cs.isLoading]);

  return (
    <>
      <Arrow
        color={'blue'}
        leftToRotationPoint={`6px`}
        topToRotationPoint={`calc(100% - 23px)`}
        rotationInDegrees={-(calcOptions.calculators.o?.AttackAngle ?? 0)}
        length={`${((calcOptions.calculators.o?.ClubSpeed ?? 0) / 100) * 35}%`}
        scale={0.017}
      />
      <BallWithArrow
        leftToRotationPoint={`6px`}
        topToRotationPoint={`calc(100% - 23px)`}
        length={`${(calcResult.LaunchData.BallSpeed / 100) * 35}%`}
        rotation={-calcResult.LaunchData.LaunchAngle}
        className={isLoadingCalcResult ? 'animate-pulse' : ''}
        scale={0.017}
      />
      <CurrentClub
        view='side'
        calcOptions={calcOptions}
        leftToHitPoint={`6px`}
        topToHitPoint={`calc(100% - 23px)`}
        style={{ height: '6px' }}
      />
    </>
  );
});

export default SideClub;
