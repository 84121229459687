import '../AdminEditWarning.scss';

import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as WarningIcon } from '../../../../images/icons/Warning.svg';
import { observer } from 'mobx-react-lite';

export const ReleaseEnvWarning = observer(() => {
  return (
    <div className='warning-wrapper'>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>Please note: Changes made on release.trackmanuniversity.com will at some point have an impact on the trackmanuniversity.com</>
        </Paragraph>
      </div>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>
            If you want to test go to <a href='https://dev.trackmanuniversity.com/'>dev.trackmanuniversity.com/</a>.
          </>
        </Paragraph>
      </div>
    </div>
  );
});

export default ReleaseEnvWarning;
