import './ModulesAndTestsIframe.scss';

import { useEffect, useRef } from 'react';

import { Loader } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const ModulesAndTestsIframe = observer(() => {
  const ls = useStores().lessonStore;

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (ls.lesson && formRef.current) formRef.current.submit();
  }, [ls.lesson, formRef]);

  return (
    <div className='iframe-container'>
      {ls.lesson ? (
        <>
          <form ref={formRef} method='post' encType='application/x-www-form-urlencoded' target='a9frame' action={ls.lesson.area9LaunchUrl}>
            {ls.lesson.parameters.map((param) => (
              <input type='hidden' name={param.name} value={param.value} key={param.name} />
            ))}
          </form>
          <iframe title='a9frame' name='a9frame' id='a9frame' src='about:blank' />
        </>
      ) : (
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      )}
    </div>
  );
});

export default ModulesAndTestsIframe;
