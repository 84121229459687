import '../Admin.scss';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useSetPageTitle from 'utilities/useSetPageTitle';

export const ExcelCalculator = observer(() => {
  useSetPageTitle({ title: 'Excel Calculator', subtitle: '' });

  useEffect(() => {}, []);

  return <div className='admin-list-container admin-content-container'>Excel Calculator</div>;
});

export default ExcelCalculator;
