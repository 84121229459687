import './CourseSectionSkeleton.scss';
import '../LessonTile/TilesSectionContainer.scss';

import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';

export const CourseSectionSkeleton = observer(() => {
  return (
    <div className='skeleton-section-container tiles-section-container'>
      <div className='heading-container'>
        <Skeleton variant='text' width={225} animation='wave' />
        <Skeleton variant='text' width={300} animation='wave' />
      </div>
      <ul className='items-list tiles-section-lessons-list'>
        {Array(6)
          .fill(1)
          .map((item, index) => (
            <li className='item-container' key={`skeleton-item-${index}`}>
              <Skeleton variant='rectangular' width={204} height={105} animation='wave' />
              <div className='item-description'>
                <Skeleton variant='rectangular' width={180} height={66} animation='wave' />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
});

export default CourseSectionSkeleton;
