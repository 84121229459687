import { NumericVariable, SelectVariable, SliderVariable, variableToParameter } from '../constants/supportedVariablesAndParameters';

import { ClubType } from '../constants/selectOptions';
import { getNumericDisplayValue } from '../helpers/valueManipulators';
import { parameterToName } from '../constants/parameterToName';
import { parameterToPrecisionByUnitSystem } from '../constants/parameterToPrecisionByUnitSystem';
import { parameterToUnitByUnitSystem } from '../constants/parameterToUnitByUnitSystem';
import { sliderLimitsByClubTypeAndParameter } from '../constants/sliderLimitsByClubTypeAndParameter';
import { unitToSymbol } from '../constants/unitToSymbol';
import { useMemo } from 'react';
import useUnitSystem from './useUnitSystem';

export const useSelectConstants = (variable: SelectVariable) => {
  const parameter = useMemo(() => variableToParameter[variable], [variable]);
  const label = useMemo(() => parameterToName[parameter], [parameter]);

  return { parameter, label };
};

export const useNumericConstants = (variable: NumericVariable) => {
  const { unitSystem } = useUnitSystem();

  const parameter = useMemo(() => variableToParameter[variable], [variable]);
  const label = useMemo(() => parameterToName[parameter], [parameter]);
  const unit = useMemo(() => parameterToUnitByUnitSystem[parameter]?.[unitSystem], [parameter, unitSystem]);
  const precision = useMemo(() => parameterToPrecisionByUnitSystem[parameter]?.[unitSystem] ?? 1, [parameter, unitSystem]);
  const symbol = useMemo(() => unitToSymbol[unit], [unit]);

  return { parameter, label, unit, precision, symbol };
};

export const useSliderLimits = (variable: SliderVariable, clubType: ClubType) => {
  const { parameter, unit, precision } = useNumericConstants(variable);

  const sliderMin = useMemo(() => {
    const sliderMinInRadarUnits = sliderLimitsByClubTypeAndParameter[clubType]?.[parameter]?.min ?? 0;
    return getNumericDisplayValue({ valueInRadarUnits: sliderMinInRadarUnits, unit, precision });
  }, [parameter, unit, precision, clubType]);

  const sliderMax = useMemo(() => {
    const sliderMaxInRadarUnits = sliderLimitsByClubTypeAndParameter[clubType]?.[parameter]?.max ?? 1;
    return getNumericDisplayValue({ valueInRadarUnits: sliderMaxInRadarUnits, unit, precision });
  }, [parameter, unit, precision, clubType]);

  const sliderDefault = useMemo(() => {
    const sliderDefaultInRadarUnits = sliderLimitsByClubTypeAndParameter[clubType]?.[parameter]?.default ?? 1;
    return getNumericDisplayValue({ valueInRadarUnits: sliderDefaultInRadarUnits, unit, precision });
  }, [parameter, unit, precision, clubType]);

  return { sliderMin, sliderMax, sliderDefault };
};
