import '../../../../pages/Organization/Organization.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const AddNewClassModal = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  return (
    <Modal
      open={cs.isAddClassModalOpen}
      onClose={() => (cs.isAddClassModalOpen = false)}
      aria-labelledby={t('AddNewClass', { ns: 'classPage' })}
      aria-describedby={t('AddNewClassToTheList', { ns: 'classPage' })}
      className='organization-modal'
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <PlusIcon />
            {t('CreateNewClass', { ns: 'organizationPage' })}
          </h2>
          <CloseIcon onClick={() => (cs.isAddClassModalOpen = false)} />
        </div>
        <div className='modal-body'>
          <p className='name-your-class'>
            <strong>{t('NameYourClass', { ns: 'organizationPage' })}</strong>
          </p>
          <ProfileInput
            label={t('ClassName', { ns: 'organizationPage' })}
            value={cs.newClass.name ?? ''}
            onChange={(newValue) => (cs.newClass.name = newValue)}
          />
        </div>
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => (cs.isAddClassModalOpen = false)}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button
            className='styled-button primary'
            onClick={() => {
              cs.createNewClassAndUpdate();
              cs.isAddClassModalOpen = false;
              cs.newClass.name = '';
            }}
            disabled={cs.newClass.name === '' || cs.isCreatingNewClass}
          >
            {t('Create', { ns: 'organizationPage' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default AddNewClassModal;
