import '../../../../pages/Admin/Admin.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditTrackmanParameter.scss';

import { useCallback, useEffect, useState } from 'react';

import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import JSONPreview from 'components/Admin/JSONPreview/JSONPreview';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import ParameterAdditionalArticles from 'components/Admin/ParameterSettings/ParameterAdditionalArticles/ParameterAdditionalArticles';
import ParameterCategories from 'components/Admin/ParameterSettings/ParameterCategories/ParameterCategories';
import ParameterDefinition from 'components/Admin/ParameterSettings/ParameterDefinition/ParameterDefinition';
import ParameterLegendMedia from 'components/Admin/ParameterSettings/ParameterMedia/ParameterLegendMedia/ParameterLegendMedia';
import ParameterMedia from 'components/Admin/ParameterSettings/ParameterMedia/ParameterMedia';
import ParameterPrimaryCategory from 'components/Admin/ParameterSettings/ParameterPrimaryCategory/ParameterPrimaryCategory';
import ParameterUsageInfo from 'components/Admin/ParameterSettings/ParameterUsageInfo/ParameterUsageInfo';
import ParameterVideos from 'components/Admin/ParameterSettings/ParameterVideos/ParameterVideos';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import VisibleSetting from 'components/Admin/StatusSettings/VisibleSetting';
import { cleanParameter } from 'stores/Admin/Editors/ParametersStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditTrackmanParameter = observer(() => {
  useSetPageTitle({ title: 'Edit Trackman Parameter', subtitle: '' });
  const parameterId = useParams().parameterId;
  const ps = useStores().parametersStore;
  const [videoIdError, setVideoIdError] = useState(false);
  const [threeDModelUrlError, setThreeDModelUrlError] = useState(false);

  useEffect(() => {
    if (parameterId) {
      ps.getParameter(parameterId);
    }
    return () => {
      ps.parameter = structuredClone(cleanParameter);
    };
  }, [ps, parameterId]);

  const disableButton = useCallback(
    () =>
      ps.parameter.titles.find((title) => title.countryCode === 'en-US')?.text === '' ||
      ps.parameter.titles.length === 0 ||
      videoIdError ||
      (ps.parameter.mediaType === 'ThreeDModel' && ps.parameter.threeDModelUrl === '') ||
      threeDModelUrlError,
    [ps.parameter.titles, videoIdError, ps.parameter.mediaType, ps.parameter.threeDModelUrl, threeDModelUrlError]
  );

  useEffect(() => {
    parameterId && disableButton();
  }, [parameterId, disableButton]);

  const validateAndSaveParameter = () => {
    const validatedVideoUrls = ps.parameter.videoUrls.filter((video) => video.videoUrl !== '');
    ps.parameter.videoUrls = validatedVideoUrls;

    if (parameterId) {
      ps.saveExistingParameterAndUpdate();
    } else {
      ps.saveNewParameterAndUpdate();
    }
  };

  return (
    <div className='admin-content-container'>
      <AdminEditWarning />
      <LanguageSettings dataItem={ps.parameter} />
      <TitleInputs dataItem={ps.parameter} />
      <div className='admin-edit-section'>
        <h2>Settings</h2>
        <div className='checkboxes-container'>
          <VisibleSetting dataItem={ps.parameter} />
        </div>
      </div>
      <ParameterPrimaryCategory />
      <ParameterCategories />
      <ParameterMedia setThreeDModelUrlError={setThreeDModelUrlError} threeDModelUrlError={threeDModelUrlError} />
      <ParameterLegendMedia />
      <ParameterDefinition />
      <ParameterUsageInfo />
      <ParameterVideos setVideoIdError={setVideoIdError} videoIdError={videoIdError} />
      <ParameterAdditionalArticles />
      <JSONPreview jsonPreview={JSON.stringify(ps.parameter, null, 2)} />
      <div className='admin-footer'>
        <button className='styled-button primary' onClick={() => validateAndSaveParameter()} disabled={disableButton()}>
          Save changes
        </button>
      </div>
    </div>
  );
});

export default EditTrackmanParameter;
