//
// THIS FILE IS THE ULTIMATE SOURCE OF TRUTH - MODIFY WITH GREAT CAUTION
//
// It defines types for both Variables and Parameters based on the mapping objects.
//
// If you want to add new Variable or Parameter modify the appropriate object, the types will propagate through repository
// and after fixing all TypeScript errors calculators should wrok as expected.
//

type ValueOf<T> = T[keyof T]; // helper

export const selectVariableToParameter = {
  'calculators.c.ClubType': 'ClubType',
  'calculators.o.ClubType': 'ClubType',
  'simulatorInput.ClubType': 'ClubType',
  'calculators.o.OptimizationType': 'OptimizationType',
  'calculators.o.TrajectoryType': 'TrajectoryType',
  'calculators.s.WindDirection': 'WindDirection',
  'simulatorInput.WindDirection': 'WindDirection',
  'calculators.s.SurfaceType': 'SurfaceType',
  'simulatorInput.SurfaceType': 'SurfaceType',
  'calculators.s.SurfaceHardness': 'SurfaceHardness',
  'simulatorInput.SurfaceHardness': 'SurfaceHardness',
} as const;
export const allSelectVariables = Object.keys(selectVariableToParameter) as SelectVariable[];

export type SelectVariable = keyof typeof selectVariableToParameter;
export type SelectParameter = ValueOf<typeof selectVariableToParameter>;

export const sliderVariableToParameter = {
  'calculators.c.AttackAngle': 'AttackAngle',
  'calculators.c.ClubPath': 'ClubPath',
  'calculators.c.ClubSpeed': 'ClubSpeed',
  'calculators.c.DynamicLoft': 'DynamicLoft',
  'calculators.c.FaceAngle': 'FaceAngle',
  'calculators.d.AttackAngle': 'AttackAngle',
  'calculators.d.SwingDirection': 'SwingDirection',
  'calculators.d.SwingPlane': 'SwingPlane',
  'calculators.f.SmashFactor': 'SmashFactor',
  'calculators.o.AttackAngle': 'AttackAngle',
  'calculators.o.ClubSpeed': 'ClubSpeed',
  'calculators.s.LandingHeight': 'LandingHeight',
  'calculators.s.WindSpeed': 'WindSpeed',
  'simulatorInput.Altitude': 'Altitude',
  'simulatorInput.Humidity': 'Humidity',
  'simulatorInput.LandingHeight': 'LandingHeight',
  'simulatorInput.SpinAxis': 'SpinAxis',
  'simulatorInput.Temperature': 'Temperature',
  'simulatorInput.WindSpeed': 'WindSpeed',
} as const;
export const allSliderVariables = Object.keys(sliderVariableToParameter) as SliderVariable[];

export type SliderVariable = keyof typeof sliderVariableToParameter;
export type SliderParameter = ValueOf<typeof sliderVariableToParameter>;

export const outputVariableToParameter = {
  AttackAngle: 'AttackAngle',
  ClubPath: 'ClubPath',
  Distance: 'Distance',
  FaceAngle: 'FaceAngle',
  'LandingData.Carry': 'Carry',
  'LandingData.CarrySide': 'CarrySide',
  'LandingData.LandingAngle': 'LandingAngle',
  'LandingData.MaxHeight': 'MaxHeight',
  'LandingData.Total': 'Total',
  'LandingData.TotalSide': 'TotalSide',
  'LaunchData.BallSpeed': 'BallSpeed',
  'LaunchData.LaunchAngle': 'LaunchAngle',
  'LaunchData.LaunchDirection': 'LaunchDirection',
  'LaunchData.SpinAxis': 'SpinAxis',
  'LaunchData.SpinLoft': 'SpinLoft',
  'LaunchData.SpinRate': 'SpinRate',
  SpinLoft: 'SpinLoft',
  'c:DynamicLoft': 'DynamicLoft',
  'co:FaceToPath': 'FaceToPath',
  'co:SpinLoft': 'SpinLoft',
  'do:AttackAngle': 'AttackAngle',
  'do:ClubPath': 'ClubPath',
  'do:SwingDirection': 'SwingDirection',
  'do:SwingPlane': 'SwingPlane',
  'fo:ClubSpeed': 'ClubSpeed',
  'fo:SmashFactor': 'SmashFactor',
  'oo:LandingData.Carry': 'Carry',
  'oo:LaunchData.SpinLoft': 'SpinLoft',
  'so:LandingData.Carry': 'Carry',
  'so:LandingData.LandingAngle': 'LandingAngle',
  'so:LandingData.LandingBallSpeed': 'LandingBallSpeed',
  'so:LandingData.Total': 'Total',
} as const;

export type OutputVariable = keyof typeof outputVariableToParameter;
export type OutputParameter = ValueOf<typeof outputVariableToParameter>;

export const variableToParameter = { ...selectVariableToParameter, ...sliderVariableToParameter, ...outputVariableToParameter };

export type InputVariable = SelectVariable | SliderVariable;
export type NumericVariable = SliderVariable | OutputVariable;
export type Variable = SelectVariable | SliderVariable | OutputVariable;

export type InputParameter = SelectParameter | SliderParameter;
export type NumericParameter = SliderParameter | OutputParameter;
export type Parameter = SelectParameter | SliderParameter | OutputParameter;
