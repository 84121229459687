import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { ClubType } from '../constants/selectOptions';
import { getValueByPath } from '../helpers/valueManipulators';
import { useMemo } from 'react';

const useClubType = (calcOptions: CalculatorOptions): { clubType: ClubType } => {
  const clubType = useMemo(() => {
    // different calculators might use different paths for setting club type

    const clubTypeC = getValueByPath({ readableObject: calcOptions, readPath: 'calculators.c.ClubType' });
    if (clubTypeC) return clubTypeC;

    const clubTypeO = getValueByPath({ readableObject: calcOptions, readPath: 'calculators.o.ClubType' });
    if (clubTypeO) return clubTypeO;

    const clubTypeSim = getValueByPath({ readableObject: calcOptions, readPath: 'simulatorInput.ClubType' });
    if (clubTypeSim === 'driver') return 'Driver'; // missing capitalization exception handling
    else if (clubTypeSim) return clubTypeSim;

    return 'Driver';
  }, [calcOptions]);

  return { clubType };
};

export default useClubType;
