import '../../../pages/Admin/Admin.scss';
import '../../../styles/_table.scss';

import { useCallback, useMemo } from 'react';

import InviteInstructorsModal from '../Modals/InviteInstructorsModal/InviteInstructorsModal';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  name: string;
  email: string;
};

export const InstructorsTable = observer(() => {
  const os = useStores().organizationsEditorStore;

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      os.organization.instructors
        .map((instructor) => {
          return {
            id: instructor.id,
            name: instructor.fullName,
            email: instructor.email,
          };
        })
        .filter((row) => row !== undefined) as RowsDisplay[],
    [JSON.stringify(os.organization.instructors), os.organization]
  );

  const removeInstructor = useCallback(
    (id: string) => {
      os.organization.instructors = os.organization.instructors.filter((instructor) => instructor.id !== id);
    },
    [os.organization]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong>Instructors</strong>
        <button onClick={() => (os.isInviteInstructorsModalOpen = true)} className='add-button styled-button primary'>
          <PlusIcon />
          <span>Invite Instructors</span>
        </button>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 300 }}>Name</TableCell>
            <TableCell sx={{ width: 900 }}>Email</TableCell>
            <TableCell sx={{ width: 200 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length ? (
            rowsDisplay.map((instructor) => {
              return (
                <TableRow hover key={instructor.id}>
                  <TableCell>{instructor.name}</TableCell>
                  <TableCell className='instructor-email'>{instructor.email}</TableCell>
                  <TableCell>
                    <div className='action-buttons'>
                      <button className='row-button' onClick={() => removeInstructor(instructor.id)}>
                        <TrashIcon />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Paragraph>
                  <>No instructors added.</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <InviteInstructorsModal />
    </div>
  );
});

export default InstructorsTable;
