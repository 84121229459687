import { AdminCourseModel, AdminCoursesList } from 'types/admin/courses';
import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../../NotificationsStore';
import StudyroomCourseEditorService from '../../../services/Admin/Editors/StudyroomCourseEditorService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class StudyroomCourseEditorStore {
  //This store is used to manage the state of the Learning Materials component.
  private studyroomCourseEditorService: StudyroomCourseEditorService;
  private ns: NotificationsStore;

  constructor(studyroomCourseEditorService: StudyroomCourseEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.studyroomCourseEditorService = studyroomCourseEditorService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  studyCoursesList: AdminCoursesList = [];
  cleanStudyCourse: AdminCourseModel = {
    lessonIds: [],
    color: '#009688',
    sortOrder: 0,
    certification: false,
    organizationIds: [],
    prerequisiteIds: [],
    visible: false,
    comingSoon: false,
    id: '',
    titles: [{ countryCode: 'en-US', text: '' }],
    descriptions: [{ countryCode: 'en-US', text: '' }],
    languages: ['en-US'],
  };
  studyCourse: AdminCourseModel = { ...this.cleanStudyCourse };
  selectedCourseId: string = '';

  getStudyCourses = async () => {
    try {
      this.isLoading = true;
      const response = await this.studyroomCourseEditorService.getStudyroomCourses();
      this.studyCoursesList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getStudyCourse = async (studyCourseId: string) => {
    try {
      this.isLoading = true;
      const response = await this.studyroomCourseEditorService.getStudyroomCourse(studyCourseId);
      this.studyCourse = response;
      this.studyCourse.prerequisiteIds = this.studyCourse.prerequisiteIds || [];
      this.studyCourse.organizationIds = this.studyCourse.organizationIds || [];
      this.studyCourse.lessonIds = this.studyCourse.lessonIds || [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewCourseAndUpdate = async () => {
    try {
      this.isLoading = true;
      delete this.studyCourse.id;
      await this.studyroomCourseEditorService.saveNewCourse(this.studyCourse);
      this.studyCourse = { ...this.cleanStudyCourse };
      this.getStudyCourses();
      this.ns.addToast('success', 'Course has been created successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedCourseAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.studyroomCourseEditorService.saveEditedCourse(this.studyCourse);
      this.getStudyCourses();
      this.ns.addToast('success', 'Course has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteStudyCourseAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.studyroomCourseEditorService.deleteStudyCourse(this.selectedCourseId);
      this.getStudyCourses();
      this.ns.addToast('success', 'Course has been deleted successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
    this.selectedCourseId = '';
  };

  updateSortOrder = async (id: string, sortDirection: boolean) => {
    try {
      this.isLoading = true;
      await this.studyroomCourseEditorService.updateSortOrder(id, sortDirection);
      this.getStudyCourses();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedCourseId = '';
    this.isLoading = false;
  };
}

export default StudyroomCourseEditorStore;
