import { configure, makeAutoObservable } from 'mobx';

configure({ enforceActions: 'never' });
export class PageLayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  pageTitle: string = '';
  pageSubtitle: string = '';
}

export default PageLayoutStore;
