import './OrganizationSearchbar.scss';

import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

type Props = {
  heading: string;
  children: React.ReactNode[];
};

export const OrganizationSearchbar = observer(({ heading, children }: Props) => {
  return (
    <div className='organization-section searchbar-section'>
      <Paragraph>
        <strong>{heading}</strong>
      </Paragraph>
      <div className='searchbars'>
        {children.map((child) => {
          return child;
        })}
      </div>
    </div>
  );
});

export default OrganizationSearchbar;
