import '../Policies.scss';

import { Trans, useTranslation } from 'react-i18next';

import Paragraph from 'components/TextComps/Paragraph';
import Subtitle from 'components/TextComps/Subtitle';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';

export const CookiePolicyPage = observer(() => {
  const { t } = useTranslation();

  useSetPageTitle({ title: t('CookiePolicy', { ns: 'general' }), subtitle: '' });

  return (
    <div className='policies-container'>
      <div className='policies-section'>
        <Subtitle>{t('Cookies', { ns: 'cookiePolicy' })}</Subtitle>
        <Paragraph>{t('HowWeUseCookiesToImprove', { ns: 'cookiePolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        {' '}
        <Subtitle>{t('HowToUseCookies', { ns: 'cookiePolicy' })}</Subtitle>
        <Paragraph>{t('CookiesOnOurWebsiteAreUsedFor', { ns: 'cookiePolicy' })}</Paragraph>
        <ol>
          <li>{t('StrictlyNecessaryCookies', { ns: 'cookiePolicy' })}</li>
          <li>{t('AnalyticalPerformanceCookies', { ns: 'cookiePolicy' })}</li>
          <li>{t('FunctionalityCookies', { ns: 'cookiePolicy' })}</li>
        </ol>
      </div>
      <div className='policies-section'>
        {' '}
        <Subtitle>{t('ManagingCookies', { ns: 'cookiePolicy' })}</Subtitle>
        <Paragraph>{t('YouCanManageCookies', { ns: 'cookiePolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        {' '}
        <Subtitle>{t('ChangesToThisCookiesPolicy', { ns: 'cookiePolicy' })}</Subtitle>
        <Paragraph>{t('OurPoliciesAndProceduresAre', { ns: 'cookiePolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        {' '}
        <Subtitle>{t('Contact', { ns: 'cookiePolicy' })}</Subtitle>
        <Paragraph>
          <Trans i18nKey='QuestionsCommentsAndRequestsRegardingThis' ns='cookiePolicy'>
            Questions, comments and requests regarding this Cookie Policy should be addressed to&nbsp;
            <a href='mailto:privacy@trackman.com'>privacy@trackman.com</a>&nbsp;or to Trackman A/S, Stubbeled 2, DK-2950 Vedbæk, Denmark.
          </Trans>
        </Paragraph>
      </div>
    </div>
  );
});

export default CookiePolicyPage;
