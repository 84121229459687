import { AxiosInstance, AxiosResponse } from 'axios';

import { AdminPartnerOrMasterModel } from 'types/admin/partners';
import { Result } from 'services/Result';

interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class PartnersEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getPartner = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminPartnerOrMasterModel>>(`/api/admin/partners/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching a partner.');
  };

  deletePartner = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/partners/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting a partner.');
  };

  saveNewPartner = async (partner: AdminPartnerOrMasterModel) => {
    const response = await this.axiosInstance.post<AdminPartnerOrMasterModel, AxiosResponse<ApiResponse<AdminPartnerOrMasterModel>>>(
      `/api/admin/partners`,
      {
        file: partner.file?.data,
        fullName: partner.fullName,
        description: partner.description,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (response.status !== 200) throw Error('There was an internal error while saving this partner.');
  };

  saveExistingPartner = async (partner: AdminPartnerOrMasterModel) => {
    const response = await this.axiosInstance.put<AdminPartnerOrMasterModel, AxiosResponse<ApiResponse<AdminPartnerOrMasterModel>>>(
      `/api/admin/partners`,
      { id: partner.id, file: partner.file?.data, fileUrl: partner.imageUrl, fullName: partner.fullName, description: partner.description },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (response.status !== 200) throw Error('There was an internal error while saving this partner.');
  };
}

export default PartnersEditorService;
