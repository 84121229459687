import '../../../../pages/Admin/Admin.scss';
import './EditLearningMaterial.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useCallback, useEffect, useState } from 'react';

import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import AdminWYSIWYGEditor from 'components/Admin/AdminWYSIWYGEditor/AdminWYSIWYGEditor';
import JSONPreview from 'components/Admin/JSONPreview/JSONPreview';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import { LanguageType } from 'types/languages';
import LearningMaterialPictures from 'components/Admin/LearningMaterialsType/LearningMaterialPictures/LearningMaterialPictures';
import LearningMaterialTypes from 'components/Admin/LearningMaterialTypes/LearningMaterialTypes';
import LearningMaterialsCalculator from 'components/Admin/LearningMaterialsType/LearningMaterialsCalculator/LearningMaterialsCalculator';
import LearningMaterialsVideo from 'components/Admin/LearningMaterialsType/LearningMaterialsVideo/LearningMaterialsVideo';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import { cleanLearningMaterial } from 'stores/Admin/Editors/LearningMaterialStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditLearningMaterial = observer(() => {
  useSetPageTitle({ title: 'Edit Learning Material', subtitle: '' });
  const learningMaterialId = useParams().learningMaterialId;
  const lms = useStores().learningMaterialStore;
  const [mediaError, setMediaError] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  useEffect(() => {
    if (learningMaterialId) {
      lms.getLearningMaterial(learningMaterialId);
    }
    return () => {
      lms.learningMaterial = structuredClone(cleanLearningMaterial);
    };
  }, [lms, learningMaterialId]);

  useEffect(() => {
    lms.getAllLearningMaterialTypes();
  }, [lms]);

  const validateMedias = useCallback(() => {
    const invalidPictureIdFromDB = '00000000-0000-0000-0000-000000000000'; // case for the TM learning materials added in the past
    const enUSMedia = lms.learningMaterial.medias.find((media) => media.countryCode === 'en-US');

    if (!enUSMedia) {
      setSaveButtonDisabled(true);
      setMediaError(true);
      return;
    }

    lms.learningMaterial.languages.forEach((language: LanguageType) => {
      const mediaIndex = lms.learningMaterial.medias.findIndex((media) => media.countryCode === language);

      if (mediaIndex !== -1) {
        const media = lms.learningMaterial.medias[mediaIndex];
        if (!media.mediaId || media.mediaId === invalidPictureIdFromDB) {
          lms.learningMaterial.medias[mediaIndex] = { ...media, mediaId: enUSMedia.mediaId };
        }
      } else {
        lms.learningMaterial.medias.push({ mediaId: enUSMedia.mediaId, countryCode: language });
      }
    });
  }, [lms.learningMaterial.languages, lms.learningMaterial.medias]);

  const saveLearningMaterial = useCallback(() => {
    if (lms.learningMaterial.kind === 'Picture') validateMedias();

    if (learningMaterialId) {
      lms.saveEditedLearningMaterialAndUpdate();
    } else {
      lms.saveNewLearningMaterialAndUpdate();
    }
  }, [learningMaterialId, lms, validateMedias]);

  return (
    <div className='admin-content-container'>
      <AdminEditWarning />
      <LanguageSettings dataItem={lms.learningMaterial} />
      <TitleInputs dataItem={lms.learningMaterial} />
      <div className='admin-edit-section'>
        <h2>Question text</h2>
      </div>
      {lms.learningMaterial.descriptions.map((description) => {
        return (
          <AdminWYSIWYGEditor
            key={description.countryCode}
            text={description.text}
            dataItems={lms.learningMaterial.descriptions}
            language={description.countryCode}
          />
        );
      })}
      <div className='admin-edit-section'>
        <LearningMaterialTypes setSaveButtonDisabled={setSaveButtonDisabled} />
        {lms.learningMaterial.kind === 'Video' ? <LearningMaterialsVideo setSaveButtonDisabled={setSaveButtonDisabled} /> : <></>}
        {lms.learningMaterial.kind === 'Picture' ? (
          <LearningMaterialPictures mediaError={mediaError} setSaveButtonDisabled={setSaveButtonDisabled} />
        ) : (
          <></>
        )}
        {lms.learningMaterial.kind === 'Calculator' ? <LearningMaterialsCalculator /> : <></>}
      </div>
      <JSONPreview jsonPreview={JSON.stringify(lms.learningMaterial, null, 2)} />
      <div className='admin-footer'>
        <button
          className='styled-button primary'
          onClick={() => saveLearningMaterial()}
          disabled={lms.learningMaterial.titles.find((title) => title.countryCode === 'en-US')?.text === '' || saveButtonDisabled}
        >
          Save changes
        </button>
      </div>
    </div>
  );
});

export default EditLearningMaterial;
