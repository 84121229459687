import AddedStudyLessonsTable from 'components/Admin/StudyLessonsTables/AddedStudyLessonsTable/AddedStudyLessonsTable';
import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import AvailableStudyLessonsTable from 'components/Admin/StudyLessonsTables/AvailableStudyLessonsTable/AvailableStudyLessonsTable';
import ComingSoonSetting from 'components/Admin/StatusSettings/ComingSoonSetting';
import { DescriptionInputs } from 'components/Admin/DescriptionInputs/DescriptionInputs';
import HighlightColor from 'components/Admin/HighlightColor/HighlightColor';
import JSONPreview from 'components/Admin/JSONPreview/JSONPreview';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import VisibleSetting from 'components/Admin/StatusSettings/VisibleSetting';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditStudyCourse = observer(() => {
  useSetPageTitle({ title: 'Edit Study Course', subtitle: '' });

  const studyCoursesStore = useStores().studyroomCourseEditorStore;
  const studyLessonsStore = useStores().studyroomLessonEditorStore;
  const { studyCourseId } = useParams();

  useEffect(() => {
    studyCoursesStore.studyCoursesList.length === 0 && studyCoursesStore.getStudyCourses();
  }, [studyCoursesStore]);

  useEffect(() => {
    if (studyCourseId) {
      studyCoursesStore.getStudyCourse(studyCourseId);
    }
    return () => {
      studyCoursesStore.studyCourse = { ...studyCoursesStore.cleanStudyCourse };
    };
  }, [studyCoursesStore, studyCourseId]);

  useEffect(() => {
    studyLessonsStore.getLessons();
  }, [studyLessonsStore]);

  const handleSaveCourse = () => {
    if (studyCourseId) {
      studyCoursesStore.saveEditedCourseAndUpdate();
    } else {
      studyCoursesStore.saveNewCourseAndUpdate();
    }
  };

  return (
    <>
      <div className='admin-content-container'>
        <AdminEditWarning />
        <LanguageSettings dataItem={studyCoursesStore.studyCourse} />
        <TitleInputs dataItem={studyCoursesStore.studyCourse} />
        <DescriptionInputs dataItem={studyCoursesStore.studyCourse} />
        <HighlightColor dataItem={studyCoursesStore.studyCourse} />
        <div className='admin-edit-section'>
          <h2>Settings</h2>
          <div className='checkboxes-container'>
            <VisibleSetting dataItem={studyCoursesStore.studyCourse} />
            <ComingSoonSetting dataItem={studyCoursesStore.studyCourse} />
          </div>
        </div>
        <AddedStudyLessonsTable />
        <AvailableStudyLessonsTable />
        <JSONPreview jsonPreview={JSON.stringify(studyCoursesStore.studyCourse, null, 2)} />
        <div className='admin-footer'>
          <button
            className='styled-button primary'
            onClick={() => handleSaveCourse()}
            disabled={studyCoursesStore.studyCourse.titles.find((title) => title.countryCode === 'en-US')?.text === ''}
          >
            Save changes
          </button>
        </div>
      </div>
    </>
  );
});

export default EditStudyCourse;
