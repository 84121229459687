import { useEffect } from 'react';
import { useStores } from 'index';

type Props = {
  title: string;
  subtitle: string;
};

const useSetPageTitle = ({ title, subtitle }: Props) => {
  const pls = useStores().pageLayoutStore;

  useEffect(() => {
    pls.pageTitle = title;
    pls.pageSubtitle = subtitle;
    return () => {
      pls.pageTitle = '';
      pls.pageSubtitle = '';
    };
  }, [pls, title, subtitle]);
};

export default useSetPageTitle;
