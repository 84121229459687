import CourseSectionSkeleton from 'components/CourseSectionSkeleton/CourseSectionSkeleton';
import LearningMaterialsSectionsList from 'components/LearningMaterialsRoom/LearningMaterialsSectionsList/LearningMaterialsSectionsList';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const LearningMaterials = observer(() => {
  const { t } = useTranslation();
  const ss = useStores().learningMaterialsRoomStore;
  const sections = ss.learningMaterialsSectionsCollection.courses;

  useSetPageTitle({
    title: t('LearningMaterials', { ns: 'general' }),
    subtitle: t('GetABetterUnderstanding', { ns: 'learningMaterials' }), //@todo translate
  });

  useEffect(() => {
    ss.getLearningMaterialsData();
  }, [ss]);

  return (
    <>
      {!sections.length ? (
        <div style={{ padding: '40px' }}>
          {Array(3)
            .fill(1)
            .map((item, i) => (
              <CourseSectionSkeleton key={`section-${i}`} />
            ))}
        </div>
      ) : (
        <>
          {sections.map((section) => {
            return <LearningMaterialsSectionsList key={section.id} section={section} />;
          })}
        </>
      )}
    </>
  );
});

export default LearningMaterials;
