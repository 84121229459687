import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import { ParametersList } from 'types/trackmanParameters';
import TrackmanParametersService from '../../services/TrackmanParameters/TrackmanParameters';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class TrackmanParametersStore {
  private trackmanParametersService: TrackmanParametersService;
  private ns: NotificationsStore;

  constructor(trackmanParametersService: TrackmanParametersService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.trackmanParametersService = trackmanParametersService;
    this.ns = notificationStore;
  }

  parametersList: ParametersList | Record<string, never> = {};
  isParametersLoading: boolean = true;

  getTrackmanParameters = async () => {
    try {
      this.isParametersLoading = true;
      const response = await this.trackmanParametersService.getTrackmanParameters();
      const desiredOrder = ['Tee-to-green', 'Putting'];
      const sortedParametersList: ParametersList = {};

      desiredOrder.forEach((primaryCategory) => {
        if (response[primaryCategory]) {
          sortedParametersList[primaryCategory] = response[primaryCategory];
        }
      });

      this.parametersList = sortedParametersList;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isParametersLoading = false;
  };
}

export default TrackmanParametersStore;
