import './PageLayout.scss';

import { useEffect, useMemo, useRef, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import Footer from 'components/Footer/Footer';
import SideMenu from 'components/SideMenu/SideMenu';
import WelcomeModal from 'components/WelcomeModal/WelcomeModal';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useStores } from 'index';

type PageLayoutProps = {
  children?: React.ReactNode;
};

export const PageLayout = observer(({ children }: PageLayoutProps) => {
  const pls = useStores().pageLayoutStore;
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const layoutPageRef = useRef<HTMLDivElement>(null);
  const scrollBarContainer = useRef<HTMLDivElement>(null);
  const [footerVisible, setFooterVisible] = useState(false);

  const isLandingPage = useMemo(() => location.pathname === DefaultRoutes.LandingPage, [location.pathname]);
  const isNoAccessPage = useMemo(() => location.pathname === DefaultRoutes.NoAccess, [location.pathname]);
  const isNotFoundPage = useMemo(() => location.pathname === DefaultRoutes.NotFound, [location.pathname]);
  const isAdminPage = useMemo(() => location.pathname.includes(DefaultRoutes.Admin), [location.pathname]);

  useEffect(() => {
    document.title = (pls.pageTitle ? `${pls.pageTitle} – ` : '') + 'Trackman University';
  }, [pls.pageTitle]);

  useEffect(() => {
    if (layoutPageRef.current) {
      layoutPageRef.current.scrollTo(0, 0);
    }

    const timeout = setTimeout(() => {
      if (
        (containerRef.current &&
          scrollBarContainer.current &&
          containerRef.current.clientHeight >= scrollBarContainer.current.clientHeight) ||
        isNoAccessPage ||
        isNotFoundPage ||
        isAdminPage
      ) {
        setFooterVisible(false);
      } else {
        setFooterVisible(true);
      }
    }, 1000);
    setFooterVisible(false);
    return () => {
      clearTimeout(timeout);
    };
  }, [location.pathname, isNoAccessPage, isNotFoundPage, isAdminPage]);

  return (
    <div className='layout-container' ref={containerRef}>
      {!isLandingPage && !isNoAccessPage && !isNotFoundPage && <SideMenu />}
      <div className={`layout-page ${isLandingPage ? 'is-landing' : ''}`} ref={layoutPageRef}>
        <div className='layout-page--scrollbar-container' ref={scrollBarContainer}>
          {!isLandingPage && !isNoAccessPage && !isNotFoundPage && (
            <div className='layout-page--page-title-bar'>
              <h1 className='page-title'>{pls.pageTitle}</h1>
              <div className='page-subtitle'>{pls.pageSubtitle}</div>
            </div>
          )}
          <div className='layout-page--content-container'>{children}</div>
          {footerVisible ? <Footer /> : <></>}
        </div>
      </div>
      <WelcomeModal />
    </div>
  );
});

export default PageLayout;
