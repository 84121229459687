import '../../../pages/Admin/Admin.scss';
import '../../../pages/Admin/Organizations/EditOrganization/EditOrganization.scss';

import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const OrganizationDetails = observer(() => {
  const os = useStores().organizationsEditorStore;

  return (
    <>
      <div className='admin-edit-section'>
        <h2>Organization name*</h2>
        <div className='inputs-container wide-input'>
          <ProfileInput
            label='Name'
            type='text'
            value={os.organization.name ?? ''}
            onChange={(newValue) => (os.organization.name = newValue)}
          />
        </div>
      </div>
      <div className='admin-edit-section'>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <Paragraph>
              <>Number of licenses to add*</>
            </Paragraph>
            <ProfileInput
              label='Number of licenses'
              type='number'
              value={os.numberOfLicensesToAdd ?? ''}
              onChange={(newValue) => (os.numberOfLicensesToAdd = +newValue)}
            />
          </div>
        </div>
        <Paragraph>
          <strong className='current-licenses-info'>Current number of licenses: {os.organization.numberOfInvites ?? ''}</strong>
        </Paragraph>
      </div>
      <div className='admin-edit-section'>
        <h2>Student duration (month)*</h2>
        <div className='inputs-container wide-input'>
          <ProfileInput
            label='Student duration'
            type='number'
            value={os.organization.membershipDurationMonth ?? 0}
            onChange={(newValue) => (os.organization.membershipDurationMonth = Number(newValue))}
          />
        </div>
        <Paragraph>
          <span className='required-info'>*required field</span>
        </Paragraph>
      </div>
    </>
  );
});

export default OrganizationDetails;
