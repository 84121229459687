import { observer } from 'mobx-react-lite';

interface TabPanelProps {
  index: number;
  value: number;
  tabPrefix: string;
  children?: React.ReactNode;
}

export const TableTab = observer(({ index, value, tabPrefix, children }: TabPanelProps) => {
  return (
    <div role='tabpanel' hidden={value !== index} id={`${tabPrefix}-${index}`} aria-labelledby={`${tabPrefix}-${index}`}>
      {value === index && <>{children}</>}
    </div>
  );
});

export default TableTab;
