import './OfferDescriptionItem.scss';

import { ReactComponent as Checkmark } from '../../images/icons/Checkmark.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

type Props = {
  text: string;
};

export const OfferDescriptionItem = observer(({ text }: Props) => {
  return (
    <div className='offer-description-item'>
      <Checkmark />
      <Paragraph>
        <>{text}</>
      </Paragraph>
    </div>
  );
});
