import './Profile.scss';

import { useCallback, useEffect } from 'react';

import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import { Loader } from '@trackman/web-shared-components';
import { ProfessionalLevel } from 'types/certificates';
import { ProfilePicture } from 'components/ProfilePicture/ProfilePicture';
import { ProfileTabsRelativeRoutes } from 'Routes';
import { Skeleton } from '@mui/material';
import { ReactComponent as StarIcon } from 'images/icons/Star.svg';
import Tabs from 'components/Tabs/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { getUsername } from 'utilities/userInfo';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type TabRouteKeys = keyof typeof ProfileTabsRelativeRoutes;

export const Profile = observer(() => {
  const ps = useStores().profileStore;
  const cs = useStores().certificateStore;
  const bs = useStores().billingStore;
  const { t } = useTranslation();

  useSetPageTitle({ title: t('ProfileSettings', { ns: 'general' }), subtitle: t('CheckEditYourProfile', { ns: 'editProfile' }) });

  useEffect(() => {
    cs.checkAvailableCertificates();
  }, [cs]);

  useEffect(() => {
    bs.getBillingInfo();
  }, [bs]);

  useEffect(() => {
    ps.getSignUpLists();
  }, [ps]);

  let profileTabNamesByRoutes: { [key in TabRouteKeys]: string } = {
    PlayerInformation: i18next.t('PlayerInformation', { ns: 'editProfile' }),
    PlayerSettings: i18next.t('PlayerSettings', { ns: 'editProfile' }),
    BillingAndPayment: i18next.t('BillingInformation', { ns: 'billingInformation' }),
    Subscriptions: i18next.t('Subscriptions', { ns: 'billingInformation' }),
  };

  if (bs.billingInfoErrorCode) {
    profileTabNamesByRoutes.BillingAndPayment = '';
    profileTabNamesByRoutes.Subscriptions = '';
  }

  profileTabNamesByRoutes = { ...profileTabNamesByRoutes } as const;

  const setProfessionalLevelTxt = useCallback(() => {
    if (cs.availableCertificates['tmu-Master']) return ProfessionalLevel.Master;
    if (cs.availableCertificates['tmu-Level2']) return ProfessionalLevel.Level2;
    if (cs.availableCertificates['tmu-Level1']) return ProfessionalLevel.Level1;
    return null;
  }, [cs.availableCertificates]);

  return (
    <>
      <div className='profile-title'>{t('EditProfileHeader', { ns: 'editProfile' })}</div>
      <div className='profile-picture-section'>
        <div className='profile-picture-container'>
          {ps.isHandlingPicChange ? (
            <div className='loader-wrapper'>
              <Loader size='large' />
            </div>
          ) : (
            <></>
          )}
          <ProfilePicture />
        </div>
        <div className='profile-user-info'>
          <div className='profile-user-name'>
            <p>{t('PlayerName', { ns: 'editProfile' })}</p>
            {ps.isLoaded && getUsername(ps.profileData.firstName, ps.profileData.lastName)}
          </div>
          <div className='profile-certification-level'>
            <p>
              {t('TrackmanUniversityCertificationLevel', { ns: 'editProfile' })}
              {!cs.availableCertificates['tmu-Master'] && (
                <Tooltip title={t('CompleteCertificationTestsToLevelUp', { ns: 'editProfile' })} placement='bottom'>
                  <InfoIcon />
                </Tooltip>
              )}
            </p>
            {cs.isAvailableCertsLoading ? (
              <Skeleton variant='text' width={200} animation='wave' />
            ) : (
              <strong>
                {t('ProfessionalLevel', { ns: 'editProfile', level: setProfessionalLevelTxt() })}
                <StarIcon style={!cs.availableCertificates['tmu-Level1'] ? { opacity: 0.2 } : {}} />
                <StarIcon style={!cs.availableCertificates['tmu-Level2'] ? { opacity: 0.2 } : {}} />
                <StarIcon style={!cs.availableCertificates['tmu-Master'] ? { opacity: 0.2 } : {}} />
              </strong>
            )}
          </div>
        </div>
      </div>
      <div className='profile-tabs-wrapper'>
        {!bs.isBillingInfoLoading && <Tabs tabs={profileTabNamesByRoutes} defaultTabName={profileTabNamesByRoutes.PlayerInformation} />}
      </div>
    </>
  );
});

export default Profile;
