import '../../../../pages/Admin/Admin.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useCallback, useEffect } from 'react';

import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import ImageUploader from 'components/Admin/ImageUploader/ImageUploader';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { TextArea } from '@trackman/web-shared-components';
import { cleanMaster } from 'stores/Admin/Editors/MastersEditorStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditMaster = observer(() => {
  useSetPageTitle({ title: 'Edit Master', subtitle: '' });
  const masterId = useParams().masterId;
  const mes = useStores().mastersEditorStore;

  useEffect(() => {
    if (masterId) {
      mes.getMaster(masterId);
    }
    return () => {
      mes.master = structuredClone(cleanMaster);
    };
  }, [mes, masterId]);

  const handleUploadImage = useCallback(
    (data: File) => {
      mes.master.file = {
        id: '',
        data: data,
      };
    },
    [mes.master]
  );

  const saveMaster = useCallback(() => {
    if (masterId) {
      mes.saveExistingMasterAndUpdate();
    } else {
      mes.saveNewMasterAndUpdate();
    }
  }, [masterId, mes]);

  return (
    <div className='admin-content-container'>
      <AdminEditWarning />
      <div className='admin-edit-section'>
        <h2>Master's name</h2>
        <div className='inputs-container'>
          <div className='input-wrapper'>
            <ProfileInput label={`Full name`} value={mes.master.fullName ?? ''} onChange={(newValue) => (mes.master.fullName = newValue)} />
          </div>
        </div>
      </div>

      <div className='admin-edit-section'>
        <h2>Description</h2>
        <TextArea value={mes.master.description} onChange={(e) => (mes.master.description = e.currentTarget.value)} />
      </div>

      <div className='admin-edit-section'>
        <h2>Master's image</h2>
        <ImageUploader imageData={mes.master.file} imageUrl={mes.master.imageUrl} handleUploadImage={handleUploadImage} />
      </div>
      <div className='admin-footer'>
        <button
          className='styled-button primary'
          onClick={() => saveMaster()}
          disabled={mes.master.fullName === '' || (!mes.master.imageUrl && mes.master.file === undefined)}
        >
          Save changes
        </button>
      </div>
    </div>
  );
});

export default EditMaster;
