import { useCallback, useEffect, useMemo } from 'react';

import { BillingInfoUpdate } from 'types/billing';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { trimTooManySpaces } from '../../../utilities/inputValidation';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type BillingAddressProps = {
  isModalOn: boolean;
  setIsModalOn: (arg0: boolean) => void;
};

export const BillingAddress = observer(({ isModalOn, setIsModalOn }: BillingAddressProps) => {
  const ps = useStores().profileStore;
  const bs = useStores().billingStore;
  const { t } = useTranslation();

  const isFormValid = useMemo(
    () =>
      !!bs.billingInfoUpdate.Company &&
      !!bs.billingInfoUpdate.FirstName &&
      !!bs.billingInfoUpdate.LastName &&
      !!bs.billingInfoUpdate.Address1 &&
      !!bs.billingInfoUpdate.PostalCode &&
      !!bs.billingInfoUpdate.City &&
      !!bs.billingInfoUpdate.Country &&
      (bs.billingInfoUpdate.Country === 'US' ? !!bs.billingInfoUpdate.State : true),

    [
      bs.billingInfoUpdate.Company,
      bs.billingInfoUpdate.FirstName,
      bs.billingInfoUpdate.LastName,
      bs.billingInfoUpdate.Address1,
      bs.billingInfoUpdate.PostalCode,
      bs.billingInfoUpdate.City,
      bs.billingInfoUpdate.Country,
      bs.billingInfoUpdate.State,
    ]
  );

  const inputChangeHandler = useCallback(
    (newValue: string, key: keyof BillingInfoUpdate) => {
      bs.billingInfoUpdate[key] = trimTooManySpaces(newValue);
      if (!newValue.replace(/\s/g, '').length) bs.billingInfoUpdate[key] = '';
    },
    [bs.billingInfoUpdate]
  );

  useEffect(() => {
    ps.getSignUpLists();
  }, [ps]);

  return (
    <Modal open={isModalOn} onClose={() => setIsModalOn(false)} style={{ padding: 0 }} className='profile-modal edit-payment-method-modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <PencilIcon />
            {t('EditBillingInformation', { ns: 'billingInformation' })}
          </h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <div className='profile-inputs-column'>
            <h3 className='section-subtitle'>{t('BillingDetails', { ns: 'billingInformation' })}</h3>
            <ProfileInput
              label={t('CompanyWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.Company ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'Company');
              }}
            />
            <ProfileInput
              label={t('FirstNameWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.FirstName ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'FirstName');
              }}
            />
            <ProfileInput
              label={t('LastNameWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.LastName ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'LastName');
              }}
            />
            <ProfileInput
              label={t('StreetName1WithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.Address1 ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'Address1');
              }}
            />
            <ProfileInput
              label={t('StreetName2', { ns: 'editProfile' })}
              value={bs.billingInfoUpdate.Address2 ?? ''}
              type='text'
              onChange={(newValue) => (bs.billingInfoUpdate.Address2 = newValue)}
            />
            <ProfileInput
              label={t('PostalCodeWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.PostalCode ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'PostalCode');
              }}
            />
            <ProfileInput
              label={t('CityWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.City ?? ''}
              type='text'
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'City');
              }}
            />
            <ProfileInput
              label={t('CountryWithAsterisk', { ns: 'billingInformation' })}
              value={bs.billingInfoUpdate.Country ?? ''}
              onChange={(newValue) => {
                inputChangeHandler(newValue, 'Country');
              }}
              selectOptions={ps.signUpLists.Countries.map((country) => {
                return {
                  label: country.name === ' ' ? t('PleaseSelectACountry', { ns: 'editProfile' }) : country.name,
                  value: country.twoLetterIsoCode,
                };
              })}
            />
            {bs.billingInfoUpdate.Country === 'US' && (
              <ProfileInput
                label={t('StateWithAsterisk', { ns: 'billingInformation' })}
                value={bs.billingInfoUpdate.State ?? ' ' ?? ''}
                onChange={(newValue) => {
                  inputChangeHandler(newValue, 'State');
                }}
                selectOptions={ps.signUpLists.States.map((state) => {
                  return {
                    label: state === ' ' ? t('PleaseSelectAState', { ns: 'editProfile' }) : state,
                    value: state,
                  };
                })}
              />
            )}
          </div>
        </div>
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => setIsModalOn(false)}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button
            className='styled-button primary'
            onClick={() => {
              bs.updateBillingAddress();
              setIsModalOn(false);
            }}
            disabled={bs.isBillingInfoUpdating || !isFormValid}
          >
            {t('SaveChanges', { ns: 'editProfile' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default BillingAddress;
