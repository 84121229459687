import './Stacks.scss';

import LessonTile from 'components/LessonTile/LessonTile';

const exampleLesson1 = {
  lesson: {
    id: '',
    title: 'Launch Angle',
    description: '',
    image: {
      id: '',
      fileUrl: '',
      mimeType: '',
      data: '',
    },
    progress: 0.33,
    comingSoon: false,
    done: false,
  },
  lessonNumber: 4,
  sectionTitle: '',
  color: '#467d80',
  courseId: '',
};

const exampleLesson2 = {
  lesson: {
    id: '',
    title: 'Weather',
    description: '',
    image: {
      id: '',
      fileUrl: '',
      mimeType: '',
      data: '',
    },
    progress: 1.0,
    comingSoon: false,
    done: false,
  },
  lessonNumber: 2,
  sectionTitle: '',
  color: '#158AAC',
  courseId: '',
};

const exampleLesson3 = {
  lesson: {
    id: '',
    title: 'Club I',
    description: '',
    image: {
      id: '',
      fileUrl: '',
      mimeType: '',
      data: '',
    },
    progress: 0.4,
    comingSoon: false,
    done: false,
  },
  lessonNumber: 1,
  sectionTitle: '',
  color: '#1C91D0',
  courseId: '',
};

export const ModulesAndTestsStack = () => {
  return (
    <div className='stack-container'>
      <div className='stack-modules-and-tests-tile stack-modules-and-tests-tile--first'>
        <LessonTile {...exampleLesson1} />
      </div>
      <div className='stack-modules-and-tests-tile stack-modules-and-tests-tile--second'>
        <LessonTile {...exampleLesson2} />
      </div>
      <div className='stack-modules-and-tests-tile stack-modules-and-tests-tile--third'>
        <LessonTile {...exampleLesson3} />
      </div>
    </div>
  );
};

export default ModulesAndTestsStack;
