import '../Policies.scss';

import Paragraph from 'components/TextComps/Paragraph';
import Subtitle from 'components/TextComps/Subtitle';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useTranslation } from 'react-i18next';

export const TermsAndConditionsPage = observer(() => {
  const { t } = useTranslation();
  useSetPageTitle({ title: t('TermsAndConditions', { ns: 'general' }), subtitle: '' });

  return (
    <div className='policies-container terms-and-conditions'>
      <div className='policies-section'>
        <Subtitle>{t('TermsAndConditionsForUseOfAccount', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('TheUseOfTrackmansServices', { ns: 'termsAndConditions' })}</Paragraph>
      </div>

      <div className='policies-section'>
        <Subtitle>{t('PrivacyAndDataPolicy', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('YourPrivacyIsVeryImportantToUs', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('PleaseReferToOurPrivacyNotice', { ns: 'termsAndConditions' })}</Paragraph>
      </div>

      <div className='policies-section'>
        <Subtitle>{t('Cookies', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('WeUseCookiesToImproveUserExperience', { ns: 'termsAndConditions' })}</Paragraph>
      </div>

      <div className='policies-section'>
        <Subtitle>{t('Safety', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('WeDoOurBestToKeepYourTMAccountSafe', { ns: 'termsAndConditions' })}</Paragraph>
        <ul>
          <li>{t('YouWillNotUploadViruses', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillNotSolicitLogin', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillNotUseYourTMAccountTo', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillNotFacilitate', { ns: 'termsAndConditions' })}</li>
        </ul>
      </div>

      <div className='policies-section'>
        <Subtitle>{t('RegistrationAndAccountSecurity', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('HereAreSomeCommitments', { ns: 'termsAndConditions' })}</Paragraph>
        <ul>
          <li>{t('YouWillNotProvideAnyFalsePersonalInfo', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillKeepYourContactInfoAccurate', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillNotShareYourPassword', { ns: 'termsAndConditions' })}</li>
          <li>{t('YouWillNotTransferYourAccount', { ns: 'termsAndConditions' })}</li>
        </ul>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('ProtectingOtherPeoplesRights', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('WeRespectOtherPeoplesRights', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('IfYouCollectDataFromOtherPlayers', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('ExceptForTheStandardMaterials', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('SpecialProvisionsApplicableToSoftware', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('IfYouDownloadOrUseOurSoftware', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('YouWillNotModify', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('DetailsOfTransfers', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('YourPersonalDataMayBeStored', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('PromotionalEmail', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('IfYouConsentToThisSpecifically', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('YouCanAlwaysWithdraw', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('UseOfTrackmanAccount', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('EachAccountIsForTheExclusiveUse', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('OnlyRegisteredUsersHaveAccessTo', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('AnyUnauthorizedUseOrAbuse', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('AlthoughTrackmanAttemptsToEnsure', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('TrackmanReservesTheRightToMakeChanges', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('TrackmanHoldsFullCopyright', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('IfYourTrackmanProductIsMadeAvailableToOtherPlayersOrDataIsCollected', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>
          {t('IfYourTrackmanProductIsMadeAvailableToOtherPlayersWhoTakeTheTMCombineTest', { ns: 'termsAndConditions' })}
        </Paragraph>
        <Paragraph>{t('IfAnyPlayerWishesToDelete', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('Indemnification', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('IfAnyoneBringsAClaimAgainstUs', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('AlthoughWeProvideRulesForUserConduct', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('LimitationOfLiability', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('WeWillUseReasonableSkillAndCare', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('TrackmansLiabilityCannotExceed', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('TrackmanIsLiableForDirectLosses', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('Other', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('TheseTermsAndConditionsMakeUp', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('IfWeFailToEnforceAnyOfOurRIghts', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('YouWillNotTransferAnyOfYourRights', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('AllOfOurRightsAndObligations', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('NothingInTheseTerms', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('TheseTermsAndConditionsDoNotConfer', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('YouWillComplyWithAll', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('DisputeResolution', { ns: 'termsAndConditions' })}</Subtitle>
        <Paragraph>{t('DanishLawShallGovernThisAgreement', { ns: 'termsAndConditions' })}</Paragraph>
        <Paragraph>{t('IfAnyPortionOfTheseTerms', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>
          <>***</>
        </Subtitle>
        <Paragraph>{t('StayUpdatedOnTheseTerms', { ns: 'termsAndConditions' })}</Paragraph>
      </div>
    </div>
  );
});

export default TermsAndConditionsPage;
