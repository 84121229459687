import '../../../pages/Admin/Admin.scss';

import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const Area9EntrypointInput = observer(() => {
  const ls = useStores().lessonEditorStore;

  return (
    <div className='admin-edit-section'>
      <h2>Area 9 entrypoint</h2>
      <div className='inputs-container wide-input'>
        <ProfileInput
          label='Entrypoint'
          type='text'
          value={ls.lesson.enterpoint ?? ''}
          onChange={(newValue) => (ls.lesson.enterpoint = newValue)}
        />
      </div>
    </div>
  );
});

export default Area9EntrypointInput;
