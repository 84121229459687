import './OutputDataBoxes.scss';

import { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from 'services/Calculator/CalculatorService';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import OutputBox from './OutputBox/OutputBox';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: BallLaunch | ClubDelivery | SwingDirection | BounceAndRoll;
  htmlColor: string;
};

const OutputDataBoxes = observer(({ calcOptions, calcResult, htmlColor }: Props) => {
  const outputVariables = useMemo(
    () => [...calcOptions.showData.values, ...(calcOptions.showDataExtra?.values ?? [])].map((output) => output.Key),
    [calcOptions]
  );

  return (
    <div className='output-data-boxes'>
      <div className='boxes-list'>
        {outputVariables.map((outputVariable, i) => {
          return (
            <div className='output-box' key={`${outputVariable}-${i}`}>
              <OutputBox outputVariable={outputVariable} calcResult={calcResult} htmlColor={htmlColor} />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default OutputDataBoxes;
