import { CSSProperties, memo } from 'react';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { ClubType } from 'components/FullCalculator/constants/selectOptions';
import driverSideImg from './images/driver-side.png';
import driverTopImg from './images/driver-top.png';
import ironSideImg from './images/iron-side.png';
import ironTopImg from './images/iron-top.png';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useTranslation } from 'react-i18next';
import wedgeSideImg from './images/wedge-side.png';
import wedgeTopImg from './images/wedge-top.png';

type Props = {
  view: View;
  calcOptions: CalculatorOptions;
  leftToHitPoint: string;
  topToHitPoint: string;
  rotation?: number;
  className?: string;
  style?: CSSProperties;
};

type View = 'top' | 'side';

const clubImgByTypeAndView: { [key in ClubType]: { [key in View]: string } } = {
  Driver: { top: driverTopImg, side: driverSideImg },
  '6Iron': { top: ironTopImg, side: ironSideImg },
  PitchingWedge: { top: wedgeTopImg, side: wedgeSideImg },
};

const clubOffsetsByTypeAndView: { [key in ClubType]: { [key in View]: { x: string; y: string } } } = {
  Driver: { top: { x: '15%', y: '20%' }, side: { x: '10%', y: '11%' } },
  '6Iron': { top: { x: '30%', y: '12%' }, side: { x: '31%', y: '6%' } },
  PitchingWedge: { top: { x: '33%', y: '15%' }, side: { x: '25%', y: '8%' } },
};

const CurrentClub = memo(({ view, calcOptions, leftToHitPoint, topToHitPoint, rotation = 0, className, style }: Props) => {
  const { clubType } = useClubType(calcOptions);
  const { t } = useTranslation();

  return (
    <img
      src={clubImgByTypeAndView[clubType][view]}
      style={{
        position: 'absolute',
        left: leftToHitPoint,
        top: topToHitPoint,
        transform: `translate(calc(-100% + ${clubOffsetsByTypeAndView[clubType][view].x}), calc(-100% + ${clubOffsetsByTypeAndView[clubType][view].y})) rotate(${rotation}deg)`,
        transformOrigin: `calc(100% - ${clubOffsetsByTypeAndView[clubType][view].x}) calc(100% - ${clubOffsetsByTypeAndView[clubType][view].y})`,
        transition: 'transform 150ms ease',
        ...style,
      }}
      className={className}
      alt={t(clubType, { ns: 'calculators' })}
    />
  );
});

export default CurrentClub;
