import './OrderConfirmationPage.scss';

import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as Checkmark } from '../../../images/icons/CheckmarkCircle.svg';
import Separator from 'components/Separator/Separator';
import { formatDate } from 'utilities/formatDate';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';

export const OrderConfirmationPage = observer(() => {
  const { t } = useTranslation();
  const billingStore = useStores().billingStore;
  const buySubscriptionStore = useStores().buySubscriptionStore;

  useEffect(() => {
    billingStore.getSubscriptions();
  }, [billingStore, billingStore.subscriptionData.length]);

  return (
    <>
      <div className='success-info-wrapper'>
        <h2>
          <Checkmark /> {t('YouHaveSuccessfullySubscribedToPremium', { ns: 'purchaseMembership' })}
        </h2>
        <strong>
          {t('CofirmationEmailHasBeenSentTo', { ns: 'purchaseMembership' })} {buySubscriptionStore.subscriptionEmail}
        </strong>
        <p>{t('CheckYourSpamMailFolder', { ns: 'purchaseMembership' })}</p>
      </div>
      {billingStore.subscriptionData[0] && (
        <div className='plan-info-wrapper'>
          <div className='plan-info'>
            <h3 className='section-subtitle'>{t('YourCurrentPlan', { ns: 'billingInformation' })}</h3>
            <p>
              <strong className='plan-name'>{billingStore.subscriptionData[0].planName}</strong>
              <strong className='expiry-info valid'>
                {t('ValidUntil', { ns: 'billingInformation' })}&nbsp;
                {formatDate(billingStore.subscriptionData[0].currentPeriodEnds)}
              </strong>
            </p>

            <Separator />
            <h3 className='section-subtitle'>{t('PaymentDate', { ns: 'billingInformation' })}</h3>
            <p>
              <strong>{formatDate()}</strong>
            </p>
            <Separator />
            <h3 className='section-subtitle'>{t('Rate', { ns: 'billingInformation' })}</h3>
            <p>
              <strong>${billingStore.subscriptionData[0].annualRate}</strong>
            </p>
            {buySubscriptionStore.discountData.couponCode && (
              <>
                <Separator />
                <h3 className='section-subtitle'>{t('Discount', { ns: 'billingInformation' })}</h3>
                <p>
                  <strong>
                    {t('DiscountOnCode', { ns: 'purchaseMembership' })} {buySubscriptionStore.discountData.couponCode}:{' '}
                    {buySubscriptionStore.discountData.currencySymbol}
                    {buySubscriptionStore.discountData.discount}
                  </strong>
                </p>
              </>
            )}
            <Separator />
            <h3 className='section-subtitle'>{t('Price', { ns: 'billingInformation' })}</h3>
            <p>
              <strong>${billingStore.subscriptionData[0].price}</strong>
            </p>
            <Separator />
          </div>
          <p className='subscription-contact'>
            <Trans i18nKey='ContactWithBillingAndPaymentRelatedQuestions' ns='billingInformation'>
              Contact <a href='mailto:support@trackman.com'>support@trackman.com</a> with Billing & Payment related questions
            </Trans>
          </p>
        </div>
      )}
    </>
  );
});

export default OrderConfirmationPage;
