import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { getLanguageText } from '../../../../utilities/languages';
import { mapBooleanToString } from 'utilities/booleanMapper';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  name: string;
  primaryCategory: string;
  languages: string;
  visible: string;
};

export const TrackmanParametersList = observer(() => {
  useSetPageTitle({ title: 'Trackman Parameters List', subtitle: '' });
  const ps = useStores().parametersStore;
  const [searchValue, setSearchValue] = useState('');
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    ps.getParameters();
  }, [ps]);

  useEffect(() => {
    ps.filteredList = ps.parametersList.filter((parameter) => parameter.title?.toLowerCase().includes(searchValue.toLowerCase()));
  }, [ps, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      ps.filteredList.map((parameter) => {
        return {
          id: parameter.id,
          name: parameter.title?.toLowerCase(),
          primaryCategory: parameter.primaryCategory,
          languages: parameter.languages ? parameter.languages.map((lng) => getLanguageText(lng)).join(', ') : '',
          visible: mapBooleanToString(parameter.visible),
        };
      }) as RowsDisplay[],
    [ps.filteredList]
  );

  const deleteParameter = useCallback(() => {
    ps.deleteParameterAndUpdate();
    setIsModalOn(false);
  }, [ps, setIsModalOn]);

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field'>
            <input
              className='search-input'
              placeholder={'Search'}
              name='search-for-parameter'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.TrackmanParametersEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New parameter</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 300 }}>Name</TableCell>
              <TableCell sx={{ width: 300 }}>Primary Category</TableCell>
              <TableCell sx={{ width: 600 }}>Languages</TableCell>
              <TableCell sx={{ width: 300 }}>Visible</TableCell>
              <TableCell sx={{ width: 200 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.primaryCategory}</TableCell>
                    <TableCell>{row.languages}</TableCell>
                    <TableCell>{row.visible}</TableCell>
                    <TableCell>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.TrackmanParametersEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className={`row-button ${ps.isLoading ? 'disabled' : ''}`}
                          onClick={() => {
                            ps.selectedParameterId = row.id;
                            setIsModalOn(true);
                          }}
                          disabled={ps.isLoading}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No parameters found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deleteParameter} itemName='Trackman Parameter' />
    </div>
  );
});

export default TrackmanParametersList;
