import { AxiosInstance } from 'axios';

export class AccountService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  logOut = async () => {
    window.location.assign(this.axiosInstance.defaults.baseURL + `/account/logout`);
  };
}

export default AccountService;
