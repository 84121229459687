import { Course, CourseLesson, CoursesList } from 'types/courses';
import { configure, makeAutoObservable } from 'mobx';

import CoursesService from '../../services/CoachSection/CoursesService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class CoursesStore {
  private coursesService: CoursesService;
  private ns: NotificationsStore;

  constructor(coursesService: CoursesService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.coursesService = coursesService;
    this.ns = notificationStore;
  }

  couseLesson: CourseLesson = { id: '', title: '' };
  coursesData: Course = {
    courseName: '',
    id: '',
    lessons: [this.couseLesson],
  };
  coursesList: CoursesList = [this.coursesData] || [];
  selectedCourseId: string = '';
  selectedCourseName: string = '';

  getCourses = async () => {
    try {
      const response = await this.coursesService.getCourses();
      this.coursesList = response;
      this.selectedCourseId = this.coursesList.length ? this.coursesList[0].id : '';
      this.selectedCourseName = this.coursesList.length ? this.coursesList[0].courseName : '';
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  getSelectedCourseLessonIds = () => {
    let lessonsIds: Array<string> = [];

    this.coursesList.filter((course) => {
      if (course.id === this.selectedCourseId) {
        course.lessons.filter((lesson) => {
          return lessonsIds.push(lesson.id);
        });
      }
      return lessonsIds;
    });
    return lessonsIds;
  };
}

export default CoursesStore;
