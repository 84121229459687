import { AxiosInstance } from 'axios';
import { LearningMaterialsSectionsCollection } from 'types/learningMaterials';
import { Result } from 'services/Result';

export class LearningMaterialsRoomService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  loadCourses = async () => {
    const response = await this.axiosInstance.get<Result<LearningMaterialsSectionsCollection>>(`/api/library`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while loading courses.');
  };
}

export default LearningMaterialsRoomService;
