import './Forbidden.scss';

import forbidden from './images/forbidden@2x.png';
import { useTranslation } from 'react-i18next';

export const Forbidden = () => {
  const { t } = useTranslation();
  return (
    <div className='forbidden-page'>
      <div className='text-container'>
        <p className='header'>{t('NoPermission', { ns: 'general' })}</p>
        <p className='subtext'>{t('SeemsLikeYouDontHavePermission', { ns: 'general' })}</p>
      </div>
      <div className='image-container'>
        <img src={forbidden} alt='forbidden' />
      </div>
    </div>
  );
};

export default Forbidden;
