import './BallLaunchChart.scss';

import { CurrentView, chartPaddingSide, chartPaddingTop, pixelsPerMeterOnSvg, svgWidth } from './helpers/constants';
import { useMemo, useRef, useState } from 'react';

import { BallLaunch } from 'services/Calculator/CalculatorService';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import FlipContainer from '../CommonComponents/FlipContainer/FlipContainer';
import { ReactComponent as SideBgSvg } from './backgrounds/side.svg';
import SideCart from './SvgComponents/SideCart';
import SideClub from './SvgComponents/SideClub';
import SideHill from './SvgComponents/SideHill';
import SideTicksAndTrajectory from './SvgComponents/SideTicksAndTrajectory';
import { ReactComponent as TopBgSvg } from './backgrounds/top.svg';
import TopClub from './SvgComponents/TopClub';
import TopTicksAndTrajectory from './SvgComponents/TopTicksAndTrajectory';
import { observer } from 'mobx-react-lite';
import usePathFunctionsAndProps from './helpers/usePathFunctionsAndProps';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: BallLaunch;
};

const BallLaunchChart = observer(({ calcOptions, calcResult }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const cs = useStores().calculatorStore;
  const isLoadingCalcResult = useMemo(() => cs.isLoading, [cs.isLoading]);

  const chartDimensions = useMemo(
    () => ({
      width: (containerRef.current?.clientWidth ?? 1414) - 2 * chartPaddingSide.x,
      height: (containerRef.current?.clientHeight ?? 522) - 2 * chartPaddingSide.y,
    }),
    [containerRef.current, isLoadingCalcResult] // needed
  );

  const [currentView, setCurrentView] = useState<CurrentView>('fullPath');

  const { backgroundScale, xMinDisplay } = usePathFunctionsAndProps({
    chartDimensions,
    trajectories: calcResult.Trajectory,
    currentView,
  });

  const sideViewSetups: { [key: string]: { [key2 in CurrentView]: string } } = {
    translate: { club: '', fullPath: '', landing: `translateX(${-xMinDisplay * pixelsPerMeterOnSvg}px)` },
    transformOrigin: {
      club: `0px calc(100% - ${chartPaddingSide.y - 2}px)`,
      fullPath: `${chartPaddingSide.x}px calc(100% - ${chartPaddingSide.y}px)`,
      landing: `${chartPaddingSide.x}px calc(100% - ${chartPaddingSide.y}px)`,
    },
    scale: {
      club: `scale(${(chartDimensions.width / svgWidth) * 90})`,
      fullPath: `scale(${backgroundScale})`,
      landing: `scale(${backgroundScale})`,
    },
  };

  const topViewSetups: { [key: string]: { [key2 in CurrentView]: string } } = {
    translate: {
      club: `translate(-13px, 0)`,
      fullPath: `translate(-${(chartPaddingTop.x - chartPaddingSide.x) / backgroundScale}px, 0)`,
      landing: '',
    },
    transformOrigin: {
      club: `0px 50%`,
      fullPath: `${chartPaddingTop.x}px 50%`,
      landing: '',
    },
    scale: {
      club: `scale(${(chartDimensions.width / svgWidth) * 60})`,
      fullPath: `scale(${backgroundScale})`,
      landing: `scale(${(chartDimensions.width / svgWidth) * 60})`,
    },
  };

  return (
    <>
      <div className='calc-view-buttons'>
        <button onClick={() => setCurrentView('fullPath')} className={`${currentView === 'fullPath' ? 'active' : ''}`}>
          {t('TrajectoryView', { ns: 'learningMaterials' })}
        </button>
        <button onClick={() => setCurrentView('club')} className={`${currentView === 'club' ? 'active' : ''}`}>
          {t('ClubView', { ns: 'learningMaterials' })}
        </button>
      </div>

      <div className='BallLaunchChart-container' ref={containerRef}>
        <FlipContainer
          front={
            <div className={`ball-launch-flip-container ${currentView !== 'club' && isLoadingCalcResult ? 'animate-pulse' : ''}`}>
              <div
                className='svgs-container'
                style={{
                  transformOrigin: sideViewSetups.transformOrigin[currentView],
                  transform: `${sideViewSetups.scale[currentView]} ${sideViewSetups.translate[currentView]}`,
                }}
              >
                <SideBgSvg />
                <SideCart trajectories={calcResult.Trajectory} />
                <SideHill
                  position='club'
                  chartDimensions={chartDimensions}
                  currentView={currentView}
                  trajectories={calcResult.Trajectory}
                />
                <SideHill
                  position='landing'
                  chartDimensions={chartDimensions}
                  currentView={currentView}
                  trajectories={calcResult.Trajectory}
                />
                <SideClub calcOptions={calcOptions} calcResult={calcResult} />
                <SideTicksAndTrajectory chartDimensions={chartDimensions} currentView={currentView} trajectories={calcResult.Trajectory} />
              </div>
            </div>
          }
          back={
            <div className={`ball-launch-flip-container ${currentView !== 'club' && isLoadingCalcResult ? 'animate-pulse' : ''}`}>
              <div
                className='svgs-container'
                style={{
                  transform: `${topViewSetups.scale[currentView]} ${topViewSetups.translate[currentView]}`,
                  transformOrigin: topViewSetups.transformOrigin[currentView],
                }}
              >
                <TopBgSvg />
                <TopClub calcOptions={calcOptions} calcResult={calcResult} />
                <TopTicksAndTrajectory chartDimensions={chartDimensions} currentView={currentView} trajectories={calcResult.Trajectory} />
              </div>
            </div>
          }
          startInTopView={calcOptions.startInTopView}
        />
      </div>
    </>
  );
});

export default BallLaunchChart;
