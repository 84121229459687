import './PartnersTab.scss';

import PartnerCard, { PartnerCardProps } from 'components/PartnerCard/PartnerCard';

import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const PartnersTab = observer(() => {
  const { t } = useTranslation();
  const ps = useStores().partnersStore;

  useEffect(() => {
    typeof ps.partners[0] === 'number' && ps.getPartners();
  }, [ps]);

  return (
    <>
      <div className='gallery-title'>
        <h2>{t('PartnerGalleryTitle', { ns: 'partnersGallery' })}</h2>
        <Paragraph>
          <>{t('LearnMoreAboutPartners', { ns: 'partnersGallery' })}</>
        </Paragraph>
      </div>
      <div className='gallery-container'>
        {ps.partners.map((partner, i) => {
          const props: PartnerCardProps = partner?.id
            ? {
                profileUrl: partner.imageUrl,
                name: partner.fullName,
                description: partner.description,
                skeleton: false,
              }
            : { skeleton: true };
          return <PartnerCard key={`partner-${i}`} {...props} delay={i * 50} />;
        })}
      </div>
    </>
  );
});

export default PartnersTab;
