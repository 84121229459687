export const handleSearchStudent = (value: string, searchList) => {
  const filteredData = searchList.filter((data) => {
    const searchTerm: string = value.toLocaleLowerCase().replace(/\s+/g, ' ');

    if (searchTerm == null || searchTerm === '' || searchTerm === ' ') {
      return true;
    }

    let name: string = `${data.email} ${data.name}`.toLowerCase();
    const words: Array<string> = searchTerm.split(' ');

    if (name.indexOf(words[0]) > -1 && words[0] !== '') {
      if (words.length > 1) {
        words.map((word) => {
          if (name.indexOf(word) > -1) {
            return name;
          }
          return name;
        });
      }

      return name;
    }
    return false;
  });

  return filteredData;
};
