import AdminWYSIWYGEditor from 'components/Admin/AdminWYSIWYGEditor/AdminWYSIWYGEditor';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const ParameterTopic = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>What is the parameter used for?</h2>
      {ps.parameter.languages.map((language) => {
        const usageInfo = ps.parameter.usageInfo.find((usedFor) => usedFor.countryCode === language);
        return (
          <AdminWYSIWYGEditor
            key={language}
            text={usageInfo?.text ? usageInfo.text : ''}
            dataItems={ps.parameter.usageInfo}
            language={usageInfo?.countryCode ? usageInfo?.countryCode : language}
          />
        );
      })}
    </div>
  );
});

export default ParameterTopic;
