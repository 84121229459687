import { AxiosInstance, AxiosResponse } from 'axios';

export class AxiosServiceConfig {
  static WaitingForToken: boolean = false;
  static setDefaultDecorators = (axiosInstance: AxiosInstance) => {
    const requestDefaultErrorHandler = (error: any) => {
      // Do something with request error
      return Promise.reject(error);
    };

    const responseDefaultErrorHandler = (error: any) => {
      // let errorTransformed = errorHandler.handleError(error);
      return Promise.resolve(error.response);
    };

    const responseDefaultDecorator = (response: AxiosResponse<any>) => {
      if (response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') > -1) {
        response.data = {
          isError: false,
          data: response.data,
        };
      }
      return response;
    };

    const requestDefaultDecorator = (request: any) => {
      return request;
    };

    // Add a request and response interceptor
    axiosInstance.interceptors.request.use(requestDefaultDecorator, requestDefaultErrorHandler);
    axiosInstance.interceptors.response.use(responseDefaultDecorator, responseDefaultErrorHandler);
  };
}
