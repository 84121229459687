import '../AdminEditWarning.scss';

import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as WarningIcon } from '../../../../images/icons/Warning.svg';
import { observer } from 'mobx-react-lite';

export const LocalEnvWarning = observer(() => {
  return (
    <div className='warning-wrapper'>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>You are working on the local version of the application. Please make sure you are also connected to the development database.</>
        </Paragraph>
      </div>
    </div>
  );
});

export default LocalEnvWarning;
