import CalculatorService, { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from '../../services/Calculator/CalculatorService';
import { configure, makeAutoObservable } from 'mobx';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class CalculatorStore {
  private calculatorService: CalculatorService;
  private ns: NotificationsStore;

  constructor(calculatorService: CalculatorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.calculatorService = calculatorService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;

  ballLaunchSimulation: BallLaunch = {
    Trajectory: [],
    LandingData: {
      Carry: 0,
      CarrySide: 0,
      Total: 0,
      TotalSide: 0,
      MaxHeight: 0,
      HangTime: 0,
      LandingAngle: 0,
      LandingBallSpeed: 0,
      LandingSpinRate: 0,
    },
    LaunchData: {
      BallSpeed: 0,
      LaunchAngle: 0,
      LaunchDirection: 0,
      SpinRate: 0,
      SpinAxis: 0,
    },
  };
  clubDeliverySimulation: ClubDelivery = {
    LaunchData: {
      BallSpeed: 0,
      LaunchAngle: 0,
      LaunchDirection: 0,
      SpinRate: 0,
      SpinAxis: 0,
    },
    LandingData: {
      Carry: 0,
      CarrySide: 0,
      Total: 0,
      TotalSide: 0,
      MaxHeight: 0,
      HangTime: 0,
      LandingAngle: 0,
      LandingBallSpeed: 0,
      LandingSpinRate: 0,
    },
    Trajectory: null,
    ClubType: '',
    ClubPath: 0,
    AttackAngle: 0,
    SpinLoft: 0,
    FaceAngle: 0,
    FaceToPath: 0,
  };
  swingDirectionSimulation: SwingDirection = {
    SwingPlane: 0,
    SwingDirection: 0,
    AttackAngle: 0,
    ClubPath: 0,
  };
  bounceAndRollSimulation: BounceAndRoll = {
    Distance: 0,
    Trajectory: [],
  };

  simulateBallLaunch = async (data: CalculatorOptions, abortController: AbortController) => {
    try {
      this.isLoading = true;
      const response = await this.calculatorService.simulateBallLaunch(data, abortController);
      if (response) this.ballLaunchSimulation = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    if (!abortController.signal.aborted) this.isLoading = false;
  };

  simulateClubDelivery = async (data: CalculatorOptions, abortController: AbortController) => {
    try {
      this.isLoading = true;
      const response = await this.calculatorService.simulateClubDelivery(data, abortController);
      if (response) this.clubDeliverySimulation = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    if (!abortController.signal.aborted) this.isLoading = false;
  };

  simulateSwingDirection = async (data: CalculatorOptions, abortController: AbortController) => {
    try {
      this.isLoading = true;
      const response = await this.calculatorService.simulateSwingDirection(data, abortController);
      if (response) this.swingDirectionSimulation = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    if (!abortController.signal.aborted) this.isLoading = false;
  };

  simulateBounceAndRoll = async (data: CalculatorOptions, abortController: AbortController) => {
    try {
      this.isLoading = true;
      const response = await this.calculatorService.simulateBounceAndRoll(data, abortController);
      if (response) this.bounceAndRollSimulation = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    if (!abortController.signal.aborted) this.isLoading = false;
  };
}

export default CalculatorStore;
