import { CalculatorKind } from 'types/learningMaterials';
import { LanguageType } from 'types/languages';
import { UnitSystem } from 'components/FullCalculator/constants/supportedUnitSystems';

export const mapUnitSystemToCulture = (language: LanguageType): UnitSystem => {
  switch (language) {
    case 'en-US':
      return 'en-US';
    case 'es-ES':
      return 'en-GB';
    case 'de-DE':
      return 'en-GB';
    case 'ja-JP':
      return 'ja-JP';
    case 'ko-KR':
      return 'ko-KR';
    case 'zh-CN':
      return 'en-GB';
    default:
      return 'en-GB';
  }
};

export const getCalcOptionsByType = (oType: CalculatorKind): string => {
  switch (oType) {
    case 'CALC_NUM':
      return JSON.stringify({
        calculators: {
          o: {
            ClubSpeed: 42,
            TrajectoryType: 'medium',
            ClubType: 'Driver',
            OptimizationType: 'combined',
            AttackAngle: 3.5,
            FairwayHardness: 'normal',
            Carry: 1,
            Total: 1,
            MaxHeight: 1,
            LaunchAngle: 10,
            BallSpeed: 10,
            Altitude: 0,
            Humidity: 0.75,
            Temperature: 25,
          },
        },
        simulatorInput: {
          BallSpeed: '{{oo:LaunchData.BallSpeed}}',
          LaunchAngle: '{{oo:LaunchData.LaunchAngle}}',
          SpinRate: '{{oo:LaunchData.SpinRate}}',
          WindSpeed: 0,
          WindDirection: 0,
          SpinAxis: 10,
          LaunchDirection: 0,
        },
        showData: {
          values: [
            {
              Title: 'HEIGHT',
              Key: 'LandingData.MaxHeight',
            },
            {
              Title: 'CARRY',
              Key: 'LandingData.Carry',
            },
            {
              Title: 'LAND ANGLE',
              Key: 'LandingData.LandingAngle',
            },
          ],
        },
        showDataExtra: {
          values: [
            {
              Title: 'TOTAL',
              Key: 'LandingData.Total',
            },
          ],
        },
        variables: {
          values: [
            {
              Title: 'CLUB SPEED',
              Key: 'calculators.o.ClubSpeed',
              UnitFrom: 10,
              UnitTo: 40,
            },
            {
              Title: 'Club',
              Key: 'ClubType',
              DisplayType: 'Dropdown',
            },
            {
              Title: 'Trajectory',
              Key: 'Trajectory',
              DisplayType: 'Dropdown',
            },
          ],
        },
      }).replace("'", '"');
    case 'CALC_BR':
      return JSON.stringify({
        calculators: {
          o: {
            ClubSpeed: 42,
            TrajectoryType: 'medium',
            ClubType: 'Driver',
            OptimizationType: 'combined',
            AttackAngle: 3.5,
            FairwayHardness: 'normal',
            Carry: 1,
            Total: 1,
            MaxHeight: 1,
            LaunchAngle: 10,
            BallSpeed: 10,
            Altitude: 0,
            Humidity: 0.75,
            Temperature: 25,
          },
          b: {
            Altitude: '{{o:Altitude}}',
            Temperature: '{{o:Temperature}}',
            Humidity: '{{o:Humidity}}',
            BallType: 'Premium',
            SurfaceType: 'fairway',
            SurfaceHardness: 'normal',
            LandingAngle: '{{oo:LandingData.LandingAngle}}',
            LandingSpeed: '{{oo:LandingData.LandingBallSpeed}}',
            SpinRate: '{{oo:LaunchData.SpinRate}}',
          },
        },
        simulatorInput: {
          BallType: 'premium',
          LandingAngle: 24,
          LandingSpeed: 40,
          SpinRate: 2800,
          SurfaceHardness: 'normal',
          SurfaceType: 'green',
        },
        showData: {
          values: [
            {
              Title: 'DISTANCE',
              Key: 'LandingData.Distance',
            },
          ],
        },
        showDataExtra: {
          values: [
            {
              Title: 'TOTAL',
              Key: 'LandingData.Total',
            },
          ],
        },
        variables: {
          values: [
            {
              Title: 'Landing Speed',
              Key: 'simulatorInput.LandingSpeed',
              UnitFrom: 30,
              UnitTo: 50,
            },
            {
              Title: 'Club',
              Key: 'ClubType',
              DisplayType: 'Dropdown',
            },
            {
              Title: 'Surface Type',
              Key: 'SurfaceType',
              DisplayType: 'Dropdown',
            },
            {
              Title: 'SurfaceHardness',
              Key: 'SurfaceHardness',
              DisplayType: 'Dropdown',
            },
          ],
        },
      }).replace("'", '"');

    case 'CALC_UD':
      return JSON.stringify({
        calculators: {
          o: {
            ClubSpeed: 42,
            TrajectoryType: 'medium',
            ClubType: 'Driver',
            OptimizationType: 'combined',
            AttackAngle: 3.5,
            FairwayHardness: 'normal',
            Carry: 1,
            Total: 1,
            MaxHeight: 1,
            LaunchAngle: 10,
            BallSpeed: 10,
            Altitude: 0,
            Humidity: 0.75,
            Temperature: 25,
          },
        },
        simulatorInput: {
          BallSpeed: '{{oo:LaunchData.BallSpeed}}',
          LaunchAngle: '{{oo:LaunchData.LaunchAngle}}',
          SpinRate: '{{oo:LaunchData.SpinRate}}',
          WindSpeed: 0,
          WindDirection: 0,
          SpinAxis: 10,
          LaunchDirection: 0,
          LandingHeight: 0,
        },
        showData: {
          values: [
            {
              Title: 'HEIGHT',
              Key: 'LandingData.MaxHeight',
            },
            {
              Title: 'CARRY',
              Key: 'LandingData.Carry',
            },
            {
              Title: 'LAND ANGLE',
              Key: 'LandingData.LandingAngle',
            },
          ],
        },
        showDataExtra: {
          values: [
            {
              Title: 'TOTAL',
              Key: 'LandingData.Total',
            },
          ],
        },
        variables: {
          values: [
            {
              Title: 'Landing Height',
              Key: 'simulatorInput.LandingHeight',
              UnitFrom: -30,
              UnitTo: 30,
            },
            {
              Title: 'Club',
              Key: 'ClubType',
              DisplayType: 'Dropdown',
            },
            {
              Title: 'Trajectory',
              Key: 'Trajectory',
              DisplayType: 'Dropdown',
            },
          ],
        },
      }).replace("'", '"');

    case 'CALC_CD':
      return JSON.stringify({
        calculators: {
          o: {
            ClubSpeed: 42,
            TrajectoryType: 'medium',
            ClubType: 'Driver',
            OptimizationType: 'combined',
            AttackAngle: 3.5,
            FairwayHardness: 'normal',
            Carry: 1,
            Total: 1,
            MaxHeight: 1,
            LaunchAngle: 10,
            BallSpeed: 10,
            Altitude: 0,
            Humidity: 0.75,
            Temperature: 25,
          },
          c: {
            ClubType: '{{o:ClubType}}',
            ClubSpeed: '{{o:ClubSpeed}}',
            AttackAngle: '{{o:AttackAngle}}',
            DynamicLoft: 10,
            ClubPath: 3.2,
            FaceAngle: -1.7,
            BallType: 'Premium',
          },
        },
        simulatorInput: {
          ClubType: 'Driver',
          ClubSpeed: 42,
          BallType: 'Premium',
          ClubPath: 0,
          AttackAngle: 0,
          DynamicLoft: 0,
          FaceAngle: 0,
        },
        startInTopView: false,
        showData: {
          values: [
            {
              Title: 'LAUNCH ANGLE',
              Key: 'LaunchData.LaunchAngle',
            },
            {
              Title: 'SPIN AXIS',
              Key: 'LaunchData.SpinAxis',
            },
            {
              Title: 'SIDE TOTAL',
              Key: 'LandingData.TotalSide',
            },
          ],
        },
        showDataExtra: {
          values: [
            {
              Title: 'CARRY',
              Key: 'LandingData.Carry',
            },
          ],
        },
        variables: {
          values: [
            {
              Title: 'Attack Angle',
              Key: 'calculators.o.AttackAngle',
              UnitFrom: -10,
              UnitTo: 10,
            },
            {
              Title: 'Dynamic Loft',
              Key: 'calculators.c.DynamicLoft',
              UnitFrom: -10,
              UnitTo: 10,
            },
          ],
        },
      }).replace("'", '"');
    case 'CALC_SD':
      return JSON.stringify({
        calculators: {
          o: {
            ClubSpeed: 42,
            TrajectoryType: 'medium',
            ClubType: 'Driver',
            OptimizationType: 'combined',
            AttackAngle: 3.5,
            FairwayHardness: 'normal',
            Carry: 1,
            Total: 1,
            MaxHeight: 1,
            LaunchAngle: 10,
            BallSpeed: 10,
            Altitude: 0,
            Humidity: 0.75,
            Temperature: 25,
          },
          d: {
            SwingPlane: 45,
            SwingDirection: -2,
            AttackAngle: 2,
            ClubPath: null,
          },
        },
        simulatorInput: {
          BallSpeed: 42,
          LaunchAngle: 22,
          SpinRate: 2800,
          WindSpeed: 0,
          WindDirection: 0,
          SpinAxis: 10,
          LaunchDirection: 0,
          LandingHeight: 0,
        },
        showData: {
          values: [
            {
              Title: 'SWING PLANE',
              Key: 'SwingPlane',
            },
            {
              Title: 'SWING DIRECTION',
              Key: 'SwingDirection',
            },
            {
              Title: 'ATTACK ANGLE',
              Key: 'AttackAngle',
            },
            {
              Title: 'CLUB PATH',
              Key: 'ClubPath',
            },
          ],
        },
        variables: {
          values: [
            {
              Title: 'Swing Direction',
              Key: 'calculators.d.SwingDirection',
              UnitFrom: -5,
              UnitTo: 5,
            },
          ],
        },
      }).replace("'", '"');
  }
};
