import { configure, makeAutoObservable } from 'mobx';

import { Area9ProgressParameters } from 'types/organization/classes';
import Area9ProgressService from '../../../services/CoachSection/progress/Area9ProgressService';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class Area9ProgressStore {
  private area9ProgressService: Area9ProgressService;
  private ns: NotificationsStore;

  constructor(area9ProgressService: Area9ProgressService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.area9ProgressService = area9ProgressService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  area9Progress: Area9ProgressParameters | undefined = undefined;

  showArea9Progress = async (selectedClassId: string, lessonId: string) => {
    try {
      this.isLoading = true;

      const response = await this.area9ProgressService.showArea9Progress(selectedClassId, lessonId);
      this.area9Progress = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default Area9ProgressStore;
