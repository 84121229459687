import { ProfileData, ProfilePictureDataType, SignUpLists } from 'types/profile';
import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import ProfileService from '../../services/UserAccount/ProfileService';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });
export class ProfileStore {
  private profileService: ProfileService;
  private ns: NotificationsStore;

  constructor(profileService: ProfileService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.profileService = profileService;
    this.ns = notificationStore;
  }

  cleanProfile: ProfileData = {
    firstName: '',
    lastName: '',
    pictureId: '',
    pictureUrl: '',
    gender: '',
    birthDay: null,
    email: '',
    phone: null,
    dexterity: 'Unknown',
    nationality: null,
    handicap: 0,
    category: '',
    address: {
      street1: null,
      street2: null,
      postalCode: null,
      city: null,
      state: null,
      country: ' ',
    },
    culture: '',
    language: '',
    acceptedEmails: false,
  } as const;

  profileData: ProfileData = this.cleanProfile;
  isLoading: boolean = false;
  isLoaded: boolean = false;
  isSavingProfile: boolean = false;

  signUpLists: SignUpLists = {
    Categories: [],
    Countries: [
      {
        name: '',
        twoLetterIsoCode: '',
      },
    ],
    States: [],
  };

  pictureDataUrl: string = '';
  isHandlingPicChange: boolean = false;
  cleanProfilePicData: ProfilePictureDataType = {
    id: '',
    fileUrl: '',
    mimeType: '',
    data: null,
  };
  newPicture: ProfilePictureDataType = { ...this.cleanProfilePicData };

  isUserMember: boolean = false;

  getProfile = async () => {
    try {
      this.isLoading = true;
      const response = await this.profileService.getProfile();
      this.isLoaded = true;
      this.profileData = response;
      localStorage.culture = this.profileData.culture;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getSignUpLists = async () => {
    try {
      this.isLoading = true;
      const response = await this.profileService.getSignUpLists();
      this.signUpLists = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveProfile = async () => {
    try {
      this.isSavingProfile = true;
      await this.profileService.saveProfile(this.profileData);
      await this.getProfile();
      this.ns.addToast('success', i18next.t('YourProfileHasBeenSaved', { ns: 'editProfile' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isSavingProfile = false;
  };

  processProfilePicAndUpdateProfile = async () => {
    try {
      this.isHandlingPicChange = true;
      await this.profileService
        .processProfilePicAndUpdateProfile(this.pictureDataUrl)
        .then((response) => {
          const blob = new Blob([response], { type: response.type });

          const modifiedBlob = new Blob([blob], { type: blob.type });
          modifiedBlob['lastModified'] = new Date().getTime();

          const picFile = new File([blob], this.newPicture.fileUrl, {
            type: this.newPicture.mimeType,
          });

          this.newPicture = {
            id: '',
            fileUrl: picFile.name,
            mimeType: picFile.type,
            data: picFile,
          };
        })
        .then(() => this.uploadProfilePicAndUpdateProfile());
    } catch {
      this.ns.addToast('error', i18next.t('ErrorWhileConvertingProfilePic', { ns: 'editProfile' }), 'error');
    }
  };

  uploadProfilePicAndUpdateProfile = async () => {
    try {
      await this.profileService.uploadProfilePicAndUpdateProfile(this.newPicture);
      this.getProfile();
    } catch (ex) {
      this.ns.addToast('error', i18next.t('ErrorWhileUploadingProfilePic', { ns: 'editProfile' }), 'error');
    }
    this.isHandlingPicChange = false;
    this.newPicture = this.cleanProfilePicData;
  };

  checkIfUserIsMember = async () => {
    try {
      const response = await this.profileService.checkIfUserIsMember();
      this.isUserMember = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default ProfileStore;
