import '../../../../pages/Organization/Organization.scss';
import './InviteStudentsModal.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { ReactComponent as SendIcon } from 'images/icons/Send.svg';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { isValidEmail } from 'utilities/inputValidation';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const InviteStudentsModal = observer(() => {
  const { t } = useTranslation();
  const iss = useStores().inviteStudentsStore;
  const orgStore = useStores().organizationsStore;
  const cs = useStores().classesStore;
  const [inputsVisible, setInputsVisible] = useState(true);
  const [emailError, setEmailError] = useState<string>('');

  const isFormValid = useMemo(
    () => !!iss.newInvite.name && !!iss.newInvite.email && !emailError,
    [iss.newInvite.name, iss.newInvite.email, emailError]
  );

  useEffect(() => {
    const classItem = cs.classesList.find((classItem) => classItem.id === cs.selectedClassId);
    iss.selectedClass = { id: cs.selectedClassId, name: classItem ? classItem.name : '' };
  }, [iss, cs.classesList, cs.selectedClassId]);

  const handleSaveStudent = useCallback(() => {
    if (!isValidEmail(iss.newInvite.email)) {
      setEmailError(t('EmailFormatIsNotValid', { ns: 'studentsPage' }));
      return;
    }

    if (iss.newInvites.find((invite) => invite.email === iss.newInvite.email)) {
      setEmailError(t('ThisEmailIsAlreadyAdded', { ns: 'studentsPage' }));
      return;
    }

    iss.newInvite.classes = [{ ...iss.selectedClass }];
    iss.newInvite.organisationName = orgStore.selectedOrg.name;
    iss.newInvites.push(iss.newInvite);
    iss.newInvite = { ...iss.cleanNewInvite };

    iss.getInvites();
    setInputsVisible(false);
  }, [iss, orgStore.selectedOrg.name, t]);

  const handleDeleteStudent = useCallback(
    (email: string) => {
      iss.newInvites = iss.newInvites.filter((invite) => invite.email !== email);
      !iss.newInvites.length && setInputsVisible(true);
    },
    [iss]
  );

  const handleModalClose = useCallback(() => {
    iss.isInviteModalOpen = false;
    iss.newInvite = { ...iss.cleanNewInvite };
    iss.newInvites = [];
    setInputsVisible(true);
  }, [iss]);

  return (
    <Modal
      open={iss.isInviteModalOpen}
      onClose={() => handleModalClose()}
      aria-labelledby={t('InviteStudents', { ns: 'studentsPage' })}
      aria-describedby={t('InviteStudentsToJoinYourClass', { ns: 'studentsPage' })}
      className='organization-modal invite-modal'
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <SendIcon />
            {t('InviteStudents', { ns: 'studentsPage' })}
          </h2>
          <CloseIcon onClick={() => handleModalClose()} />
        </div>
        {iss.numberOfInvitesLeft < 1 ? (
          <div className='no-data-found modal-body'>
            <h2>{t('NoInvitesLeft', { ns: 'studentsPage' })}</h2>
            <Paragraph>
              <>{t('YouHaveNoInvitesLeft', { ns: 'studentsPage' })}</>
            </Paragraph>
          </div>
        ) : (
          <>
            <div className='invite-form modal-body'>
              <Paragraph>
                <>{t('StudentsWillBeNotifiedToJoin', { ns: 'studentsPage', className: iss.selectedClass.name })}</>
              </Paragraph>
              <div className='invite-inputs'>
                {iss.newInvites.length ? (
                  <>
                    <ul className='new-invites-list'>
                      {iss.newInvites.map((invite, index) => (
                        <li key={`invite-${index}`}>
                          <strong>{index + 1}</strong>
                          <div className='invite-details'>
                            <strong>{invite.name}</strong>
                            <span>{invite.email}</span>
                          </div>
                          <button
                            className='delete-button'
                            onClick={() => {
                              handleDeleteStudent(invite.email);
                            }}
                          >
                            <TrashIcon />
                          </button>
                        </li>
                      ))}
                    </ul>
                    {!inputsVisible ? (
                      <button
                        className='styled-button secondary'
                        onClick={() => setInputsVisible(true)}
                        disabled={iss.numberOfInvitesLeft < 1 || iss.numberOfInvitesLeft === iss.newInvites.length}
                      >
                        {t('AddStudent', { ns: 'studentsPage' })}
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {inputsVisible ? (
                  <>
                    <div className='inputs-wrapper'>
                      <ProfileInput
                        label={t('Name', { ns: 'studentsPage' })}
                        value={iss.newInvite.name ?? ''}
                        onChange={(newValue) => (iss.newInvite.name = newValue)}
                      />
                      <ProfileInput
                        label={t('Email', { ns: 'studentsPage' })}
                        value={iss.newInvite.email ?? ''}
                        onChange={(newValue) => {
                          setEmailError('');
                          iss.newInvite.email = newValue;
                        }}
                      />
                      {emailError && (
                        <p className='error-info'>
                          <ErrorIcon />
                          <span>{emailError}</span>
                        </p>
                      )}
                    </div>
                    <button className='styled-button secondary' onClick={() => handleSaveStudent()} disabled={!isFormValid}>
                      {t('Save', { ns: 'general' })}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className='modal-summary'>
              <Paragraph>
                <strong>
                  {t('NumberOfInvitesLeft', { ns: 'studentsPage' })} {iss.numberOfInvitesLeft}
                </strong>
              </Paragraph>
            </div>
          </>
        )}
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => handleModalClose()}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button
            className='styled-button primary'
            disabled={iss.numberOfInvitesLeft < 1 || !iss.newInvites.length || iss.isLoading}
            onClick={() => {
              iss.submitInvitesAndUpdate();
              handleModalClose();
            }}
          >
            {t('SendInvitation', { ns: 'studentsPage' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default InviteStudentsModal;
