import { BillingInfo, BillingInfoUpdate, SubscriptionData } from 'types/billing';
import { configure, makeAutoObservable } from 'mobx';

import BillingService from '../../services/Subscription/BillingService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });

export class BillingStore {
  private billingService: BillingService;
  private ns: NotificationsStore;

  constructor(billingService: BillingService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.billingService = billingService;
    this.ns = notificationStore;
  }

  subscriptionData: SubscriptionData[] = [];

  cleanBillingInfo: BillingInfo = {
    address: {
      city: '',
      country: '',
      postal_code: '',
      region: '',
      street1: '',
      street2: '',
    },
    company: '',
    first_name: '',
    last_name: '',
    payment_method: {
      card_type: 'Visa',
      exp_month: 0,
      exp_year: 0,
      first_six: '',
      last_four: '',
    },
    vat_number: '',
  };

  billingInfo: BillingInfo = this.cleanBillingInfo;
  billingInfoErrorCode: boolean = false;

  cleanBillingInfoUpdate: BillingInfoUpdate = {
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Country: '',
    FirstName: '',
    LastName: '',
    PostalCode: '',
    Company: '',
  } as const;

  billingInfoUpdate: BillingInfoUpdate = this.cleanBillingInfoUpdate;
  CCInfoUpdate: BillingInfoUpdate = this.cleanBillingInfoUpdate;

  isSubscriptionsLoading: boolean = false;
  isLoadingReActivateSubscription: boolean = false;
  isBillingInfoLoading: boolean = false;
  isBillingInfoLoaded: boolean = false;
  isBillingInfoUpdating: boolean = false;
  isCreditCardUpdating: boolean = false;

  getSubscriptions = async () => {
    try {
      this.isSubscriptionsLoading = true;
      const response = await this.billingService.getSubscriptions();
      this.subscriptionData = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isSubscriptionsLoading = false;
  };

  getBillingInfo = async () => {
    try {
      this.isBillingInfoLoading = true;
      const response = await this.billingService.getBillingInfo();

      if (typeof response === 'object') {
        this.billingInfo = response;
      } else if (response === 'accountNotFound') {
        this.billingInfoErrorCode = true;
      }
      this.isBillingInfoLoaded = true;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isBillingInfoLoading = false;
  };

  updateBillingAddress = async () => {
    try {
      this.isBillingInfoUpdating = true;
      const response = await this.billingService.updateBillingAddress(this.billingInfoUpdate);
      this.billingInfoUpdate = response;
      this.ns.addToast('success', i18next.t('YourBillingAddressHasBeenUpdated', { ns: 'billingInformation' }), 'success');
      this.getBillingInfo();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isBillingInfoUpdating = false;
  };

  updateCreditCard = async (cardTokenId: string) => {
    try {
      this.isCreditCardUpdating = true;
      const response = await this.billingService.updateCreditCard(cardTokenId);

      if (response.status === 200) {
        this.billingInfo = response.data.data;
        this.isCreditCardUpdating = false;
      }
      return response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  sendThreeDSecureRequest = async (cardTokenId: string, actionTokenId: string) => {
    try {
      const response = await this.billingService.sendThreeDSecureRequest(cardTokenId, actionTokenId);

      this.billingInfo = response.data;
      this.isCreditCardUpdating = false;
      return response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default BillingStore;
