import '../AdminEditWarning.scss';

import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as WarningIcon } from '../../../../images/icons/Warning.svg';
import { observer } from 'mobx-react-lite';

export const ProductionEnvWarning = observer(() => {
  return (
    <div className='warning-wrapper'>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>Please note: Changes made on trackmanuniversity.com will be overriden!</>
        </Paragraph>
      </div>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>
            To make changes go to <a href='https://release.trackmanuniversity.com/'>release.trackmanuniversity.com</a>
          </>
        </Paragraph>
      </div>
    </div>
  );
});

export default ProductionEnvWarning;
