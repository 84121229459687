import { AdminLessonsList } from 'types/admin/lessons';
import { LearningMaterialsList } from 'types/admin/learningMaterials';
import { StudyRoomLessonsList } from 'types/admin/studyroomLessons';

export const moveLessonId = (
  currentMaterialIds: string[],
  allMaterials: LearningMaterialsList | AdminLessonsList | StudyRoomLessonsList,
  learningMaterialId: string,
  indexChange: number
) => {
  const currentIndex = currentMaterialIds.indexOf(learningMaterialId);
  let newIndex = currentIndex + indexChange;
  // Since only new lessons are displayed, we need to move the index to the next/previous lesson that is displayed
  while (true) {
    const targetId = currentMaterialIds[newIndex];

    if (targetId) {
      let found = false;

      if ('kind' in allMaterials[0]) {
        found = (allMaterials as LearningMaterialsList).some((material) => material.id === targetId);
      } else if ('enterpoint' in allMaterials[0]) {
        found = (allMaterials as AdminLessonsList).some((lesson) => lesson.id === targetId);
      } else if ('learningMaterialIds' in allMaterials[0]) {
        found = (allMaterials as StudyRoomLessonsList).some((lesson) => lesson.id === targetId);
      }

      if (found) break;
    }

    // Adjust newIndex based on the direction of indexChange
    indexChange > 0 ? newIndex++ : newIndex--;
  }
  currentMaterialIds.splice(currentIndex, 1);
  currentMaterialIds.splice(newIndex, 0, learningMaterialId);
};
