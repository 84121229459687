import './HomePage.scss';

import { DefaultRoutes } from 'Routes';
import InvitationModal from 'components/InvitationModal/InvitationModal';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import learningMaterialsShortcutImg from 'images/HomePage/LearningMaterialsShortcut.png';
import modulesAndTestsShortcutImg from 'images/HomePage/ModulesAndTestsShortcut.png';
import { observer } from 'mobx-react-lite';
import parametersShortcutImg from 'images/HomePage/ParametersShortcut.png';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const HomePage = observer(() => {
  const { t } = useTranslation();
  const ps = useStores().profileStore;

  useSetPageTitle({
    title: t('WelcomeUserMessage', {
      ns: 'homePage',
      name: ps.profileData.firstName ? ps.profileData.firstName : '',
    }),
    subtitle: t('WelcomeToTrackmanUniversity', { ns: 'homePage' }),
  });

  return (
    <div className='homepage-container'>
      <div className='shortcut-tiles'>
        <div className='shortcut-tile'>
          <Link to={DefaultRoutes.TrackmanParameters}>
            <div className='shortcut-tile-image'>
              <img src={parametersShortcutImg} alt={t('TrackmanParameters', { ns: 'trackmanParameters' })} />
              <div className='shortcut-tile-label'>{t('TrackmanParameters', { ns: 'trackmanParameters' })}</div>
            </div>
            <div className='shortcut-tile-description'>
              <Paragraph>{t('TrackmanParametersDescription', { ns: 'homePage' })}</Paragraph>
            </div>
          </Link>
        </div>
        <div className='shortcut-tile'>
          <Link to={DefaultRoutes.LearningMaterials}>
            <div className='shortcut-tile-image'>
              <img src={learningMaterialsShortcutImg} alt={t('LearningMaterials', { ns: 'general' })} />
              <div className='shortcut-tile-label'>{t('LearningMaterials', { ns: 'general' })}</div>
            </div>
            <div className='shortcut-tile-description'>
              <Paragraph>{t('LearningMaterialsDescription', { ns: 'homePage' })}</Paragraph>
            </div>
          </Link>
        </div>
        <div className='shortcut-tile'>
          <Link to={DefaultRoutes.ModulesAndTests}>
            <div className='shortcut-tile-image'>
              <img src={modulesAndTestsShortcutImg} alt={t('ModulesAndTests', { ns: 'general' })} />
              <div className='shortcut-tile-label'>{t('ModulesAndTests', { ns: 'general' })}</div>
            </div>
            <div className='shortcut-tile-description'>
              <Paragraph>{t('ModulesAndTestsDescription', { ns: 'homePage' })}</Paragraph>
            </div>
          </Link>
        </div>
      </div>
      <InvitationModal />
    </div>
  );
});

export default HomePage;
