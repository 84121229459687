import { useEffect, useMemo, useState } from 'react';

import { CalcResultWithKind } from 'services/Calculator/CalculatorService';
import { CalculatorKind } from 'types/learningMaterials';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { useStores } from 'index';

const useCalcResult = (calcOptions: CalculatorOptions, calculatorKind: CalculatorKind) => {
  const cs = useStores().calculatorStore;
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  useEffect(() => {
    // Abort previous request
    abortController.abort();
    // Create new abort controller
    const newAbortController = new AbortController();

    // Fetch result
    switch (calculatorKind) {
      case 'CALC_NUM':
      case 'CALC_UD':
        cs.simulateBallLaunch(calcOptions, newAbortController);
        break;
      case 'CALC_CD':
        cs.simulateClubDelivery(calcOptions, newAbortController);
        break;
      case 'CALC_SD':
        cs.simulateSwingDirection(calcOptions, newAbortController);
        break;
      case 'CALC_BR':
        cs.simulateBounceAndRoll(calcOptions, newAbortController);
        break;
    }

    setAbortController(newAbortController);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cs, calcOptions, JSON.stringify(calcOptions), calculatorKind]); // purposefyully left abortController out

  const calcResultWithKind: CalcResultWithKind = useMemo(() => {
    switch (calculatorKind) {
      case 'CALC_NUM':
        return { calcKind: 'CALC_NUM', calcResult: cs.ballLaunchSimulation };
      case 'CALC_UD':
        return { calcKind: 'CALC_UD', calcResult: cs.ballLaunchSimulation };
      case 'CALC_CD':
        return { calcKind: 'CALC_CD', calcResult: cs.clubDeliverySimulation };
      case 'CALC_SD':
        return { calcKind: 'CALC_SD', calcResult: cs.swingDirectionSimulation };
      case 'CALC_BR':
        return { calcKind: 'CALC_BR', calcResult: cs.bounceAndRollSimulation };
    }
  }, [calculatorKind, cs.ballLaunchSimulation, cs.clubDeliverySimulation, cs.swingDirectionSimulation, cs.bounceAndRollSimulation]);

  return { calcResultWithKind };
};

export default useCalcResult;
