import './AdminEditWarning.scss';

import DevelopmentEnvWarning from './DevelopmentEnvWarning/DevelopmentEnvWarning';
import LocalEnvWarning from './LocalEnvWarning/LocalEnvWarning';
import ProductionEnvWarning from './ProductionEnvWarning/ProductionEnvWarning';
import ReleaseEnvWarning from './ReleaseEnvWarning/ReleaseEnvWarning';
import { observer } from 'mobx-react-lite';

export const AdminEditWarning = observer(() => {
  const isLocal = window.location.hostname.includes('localhost');
  const isDevelopment = window.location.hostname.includes('dev');
  const isRelease = window.location.hostname.includes('release');
  const isProduction = window.location.hostname === 'trackmanuniversity.com';

  return (
    <>
      {isLocal ? <LocalEnvWarning /> : <></>}
      {isDevelopment ? <DevelopmentEnvWarning /> : <></>}
      {isRelease ? <ReleaseEnvWarning /> : <></>}
      {isProduction ? <ProductionEnvWarning /> : <></>}
    </>
  );
});

export default AdminEditWarning;
