import { AdminCourseModel, AdminCoursesList } from 'types/admin/courses';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class StudyroomCourseEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getStudyroomCourses = async () => {
    const response = await this.axiosInstance.get<Result<AdminCoursesList>>(`/api/admin/study-room-courses/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting study courses.');
  };

  getStudyroomCourse = async (studyRoomCourseId: string) => {
    const response = await this.axiosInstance.get<Result<AdminCourseModel>>(`/api/admin/study-room-courses/${studyRoomCourseId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting study course.');
  };

  saveNewCourse = async (course: AdminCourseModel) => {
    const response = await this.axiosInstance.post<Result<AdminCourseModel>>(`/api/admin/study-room-courses`, { ...course });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new course.');
  };

  saveEditedCourse = async (course: AdminCourseModel) => {
    const response = await this.axiosInstance.put<Result<AdminCourseModel>>(`/api/admin/study-room-courses`, { ...course });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving existing course.');
  };

  deleteStudyCourse = async (studyRoomCoursesId: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/study-room-courses/${studyRoomCoursesId}`, {});

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting course.');
  };

  updateSortOrder = async (id: string, direction: boolean) => {
    const response = await this.axiosInstance.post<Result<boolean>>(`/api/admin/study-room-courses/sort`, {
      CourseId: id,
      MoveUp: direction,
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while updating new courses sort order.');
  };
}

export default StudyroomCourseEditorService;
