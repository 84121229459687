import '../AdminModal.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  deleteAction: () => void;
  itemName: string;
};

export const DeleteListItemModal = observer(({ isModalOn, setIsModalOn, deleteAction, itemName }: Props) => {
  return (
    <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='admin-info-modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>Delete {itemName}</h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <Paragraph>
            <>Deleting this {itemName} cannot be undone.</>
          </Paragraph>
          <Paragraph>
            <>Are you sure you want to permanently delete this {itemName}? </>
          </Paragraph>
        </div>
        <div className='modal-footer'>
          <button className='styled-button tertiary' onClick={() => setIsModalOn(false)}>
            Cancel
          </button>
          <button className='styled-button danger' onClick={() => deleteAction()}>
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default DeleteListItemModal;
