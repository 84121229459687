import { CurrentView, chartPaddingTop, maxSideInRadarUnits, pixelsPerMeterOnSvg } from '../helpers/constants';

import { Fragment } from 'react';
import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import usePathFunctionsAndProps from '../helpers/usePathFunctionsAndProps';

type Props = {
  chartDimensions: { width: number; height: number };
  currentView: CurrentView;
  trajectories: TrajectoryPoint[][];
};

const SideTicksAndTrajectory = ({ chartDimensions, trajectories, currentView }: Props) => {
  const { symbol: xSymbol } = useNumericConstants('Distance');

  const { xMaxTraj, yMaxTraj } = useEdgeValuesOfTrajectories(trajectories);

  const { x, z, trajectoryLineTop, fullPathScale, xTicks, backgroundScale, svgFontSize } = usePathFunctionsAndProps({
    chartDimensions,
    trajectories,
    currentView,
  });

  return (
    <svg
      style={{
        position: 'absolute',
        left: chartPaddingTop.x,
        top: '50%',
        color: '#ec691a',
        overflow: 'visible',
        transform: 'translateY(-50%)',
      }}
      width={chartDimensions.width / fullPathScale}
      height={chartDimensions.height / fullPathScale}
    >
      {xTicks.map((tick, i) => (
        <Fragment key={`xTickAndLabelTop-${tick}-${backgroundScale}`}>
          <line
            x1={x(tick)}
            x2={x(tick)}
            y1={z(-maxSideInRadarUnits)}
            y2={z(maxSideInRadarUnits)}
            stroke={'rgba(0, 0, 0, 0.09)'}
            strokeWidth={1 / backgroundScale}
          />
          {i !== 0 && (
            <text
              key={`xLabel-${tick}-${xSymbol}-${svgFontSize}`}
              x={(x(tick) ?? 0) + svgFontSize / 3}
              y={(z(maxSideInRadarUnits) ?? 0) - svgFontSize / 3}
              fill={'black'}
              fontSize={svgFontSize.toFixed(2)}
            >
              {i === xTicks.length - 1 ? xSymbol : tick}
            </text>
          )}
        </Fragment>
      ))}
      <path
        key={`top-view-${JSON.stringify(trajectories)}`} // without a unique key it will not rerender
        style={{
          strokeDasharray: (xMaxTraj + 2 * yMaxTraj) * pixelsPerMeterOnSvg, // estimation of path length, the values must be higher than actual length
          strokeDashoffset: (xMaxTraj + 2 * yMaxTraj) * pixelsPerMeterOnSvg, // estimation of path length, the values must be higher than actual length
          animation: 'dash 1.5s ease forwards',
        }}
        fill='none'
        stroke='currentColor'
        strokeWidth={3 / backgroundScale}
        d={trajectoryLineTop(trajectories.flat(1)) ?? undefined} // flattening the trajectories makes them into one path, what enables the animation
      />
    </svg>
  );
};

export default SideTicksAndTrajectory;
