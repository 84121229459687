import './Ground.scss';

import { CSSProperties, memo } from 'react';

import groundSideImg from './images/ground.png';
import groundTopImg from './images/groundTop.png';

type Props = {
  view: View;
  style?: CSSProperties;
  className?: string;
};

type View = 'top' | 'side';

const clubImgByTypeAndView: { [key in View]: string } = {
  top: groundTopImg,
  side: groundSideImg,
};

const Ground = memo(({ view, style, className }: Props) => {
  return (
    <div className='ground-container'>
      <img src={clubImgByTypeAndView[view]} style={{ ...style }} className={`ground-img ${className}`} alt={`${view} view`} />
      {view === 'top' && <div className='ground-top-center-line' />}
    </div>
  );
});

export default Ground;
