import { AxiosInstance, AxiosResponse } from 'axios';
import { ImportTranslationsResponse, SyncAndUpdateResponse } from 'types/admin/translations';

interface ApiResponse<T> {
  data: T;
  isError: boolean;
}
export class TranslationsService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  syncTranslations = async (material: string) => {
    const response = await this.axiosInstance.post<AxiosResponse<ApiResponse<SyncAndUpdateResponse>>>(
      `/api/admin/translations/sync-${material}-terms`
    );

    if (response.status !== 200 || response.data.data.isError) throw Error('There was an internal error while synchronizing translations.');
  };

  updateTranslations = async (material: string) => {
    const response = await this.axiosInstance.post<AxiosResponse<ApiResponse<SyncAndUpdateResponse>>>(
      `/api/admin/translations/update-${material}-language`,
      { languageCode: 'en-US' }
    );

    if (response.status !== 200 || response.data.data.isError) throw Error('There was an internal error while updating translations.');
  };

  importTranslations = async (material: string, language: string) => {
    const response = await this.axiosInstance.post<{ languageCode: string }, AxiosResponse<ApiResponse<ImportTranslationsResponse>>>(
      `/api/admin/translations/import-${material}-language`,
      { languageCode: language }
    );

    if (response.status !== 200 || response.data.data.isError) throw Error('There was an internal error while importing translations.');
  };
}

export default TranslationsService;
