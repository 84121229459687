import '../../../pages/Organization/Organization.scss';

import InviteStudentsButton from '../InviteStudentsButton/InviteStudentsButton';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const NoPedingInvitations = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='no-data-found no-students-found'>
      <h2>{t('NoPendingInvitations', { ns: 'studentsPage' })}</h2>
      <Paragraph>
        <>{t('InviteStudentsAndCheckBack', { ns: 'studentsPage' })}</>
      </Paragraph>
      <InviteStudentsButton />
    </div>
  );
});

export default NoPedingInvitations;
