import './ClassProgress.scss';

import { IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Loader, Modal } from '@trackman/web-shared-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../../../images/icons/Icon_Left.svg';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type DynamicRows = {
  lessonId: string;
  lesson: string;
  progress: number;
  status: string;
};

export const ClassProgress = observer(() => {
  const { t } = useTranslation();

  const gs = useStores().gameProgressStore;
  const a9progressStore = useStores().area9ProgressStore;
  const cs = useStores().classesStore;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOn, setIsModalOn] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    gs.isSearchForStudentsVisible = false;
  }, [gs]);

  useEffect(() => {
    a9progressStore.area9Progress && formRef.current && formRef.current.submit();
  }, [a9progressStore.area9Progress]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const rows = useMemo((): DynamicRows[] => {
    let dynamicRows: DynamicRows[] = [];

    gs.classProgressForSelectedGame[0].progressList?.forEach((lesson, index) => {
      let lessonProgress = gs.classProgressForSelectedGame.reduce((accumulator, object) => {
        if (object.progressList) {
          return accumulator + object.progressList[index].percentage;
        }
        return accumulator;
      }, 0);
      lessonProgress = Math.round((lessonProgress / gs.classProgressForSelectedGame.length) * 100);

      let status =
        lessonProgress === 100
          ? t('Completed', { ns: 'organizationPage' })
          : lessonProgress === 0
          ? t('NotStartedYet', { ns: 'organizationPage' })
          : t('Started', { ns: 'organizationPage' });

      dynamicRows.push({
        lessonId: lesson.lessonId,
        lesson: lesson.lessonName,
        progress: lessonProgress,
        status: status,
      });
    });

    return dynamicRows;
  }, [gs.classProgressForSelectedGame, t]);

  return (
    <Table sx={{ minWidth: 500 }} aria-label='class-progress-table' className='class-progress-table'>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 200 }}>{t('Lesson', { ns: 'general' })}</TableCell>
          <TableCell sx={{ width: 740 }}>{t('StudentsProgress', { ns: 'general' })}</TableCell>
          <TableCell sx={{ width: 150 }}>{t('Status', { ns: 'organizationPage' })}</TableCell>
          <TableCell sx={{ width: 100 }}> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
          <TableRow key={row.lessonId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row'>
              <div>{row.lesson}</div>
            </TableCell>
            <TableCell>
              <div className={`progress-info ${row.status.toLowerCase().split(' ').join('-')}`}>
                <div className='progressbar'>
                  <span style={{ width: `${row.progress}%` }}></span>
                </div>
                <div className='progress-percentage'>{row.progress}%</div>
              </div>
            </TableCell>
            <TableCell>
              <div className={`progress-status ${row.status.toLowerCase().split(' ').join('-')}`}>{row.status}</div>
            </TableCell>
            <TableCell>
              <IconButton
                className='area9-progress-link'
                aria-label='expand row'
                size='small'
                onClick={() => {
                  setIsModalOn(true);
                  a9progressStore.showArea9Progress(cs.selectedClassId, row.lessonId);
                }}
              >
                <ArrowIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <Modal
          title={t('ClassProgress', { ns: 'organizationPage' })}
          open={isModalOn}
          onClose={() => setIsModalOn(false)}
          className='class-progress-modal'
          placeholder={undefined}
        >
          <div className='modal-body'>
            {a9progressStore.area9Progress ? (
              <>
                <form
                  ref={formRef}
                  method='post'
                  encType='application/x-www-form-urlencoded'
                  target='a9frame'
                  action={a9progressStore.area9Progress.area9LaunchUrl}
                >
                  {a9progressStore.area9Progress.parameters.map((param) => (
                    <input type='hidden' name={param.name} value={param.value} key={param.name} />
                  ))}
                </form>
                <iframe title='a9frame' name='a9frame' id='a9frame' src='about:blank' />
              </>
            ) : (
              <div className='loader-wrapper'>
                <Loader size='large' />
              </div>
            )}
          </div>
        </Modal>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={5}
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t('RowsPerPage', { ns: 'general' })}
            slotProps={{
              select: {
                'aria-label': t('RowsPerPage', { ns: 'general' }),
              },
            }}
            onPageChange={(e, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
});

export default ClassProgress;
