import './BillingAndPaymentTab.scss';

import { ReactComponent as AmericanExpressIcon } from 'images/icons/CreditCardIcons/americanExpress.svg';
import BillingAddressModal from 'components/Payment/BillingAddressModal/BillingAddressModal';
import CardDetailsModal from 'components/Payment/CardDetailsModal/CardDetailsModal';
import { ReactComponent as ChinaUnionPayIcon } from 'images/icons/CreditCardIcons/chinaUnionPay.svg';
import { ReactComponent as DinersClubIcon } from 'images/icons/CreditCardIcons/dinersClub.svg';
import { ReactComponent as DiscoverIcon } from 'images/icons/CreditCardIcons/discover.svg';
import { ReactComponent as InvoiceIcon } from 'images/icons/Invoice.svg';
import { ReactComponent as JCBIcon } from 'images/icons/CreditCardIcons/jcb.svg';
import { ReactComponent as MasterCardIcon } from 'images/icons/CreditCardIcons/mastercard.svg';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as VisaIcon } from 'images/icons/CreditCardIcons/visa.svg';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const BillingAndPaymentTab = observer(() => {
  const [isEditCardModalOn, setIsEditCardModalOn] = useState(false);
  const [isEditAddressModalOn, setIsEditAddressModalOn] = useState(false);

  const bs = useStores().billingStore;
  const { t } = useTranslation();

  const paymentMethod = bs.billingInfo.payment_method;

  return (
    <div className='profile-section billing-and-payment-section'>
      {bs.billingInfoErrorCode ? (
        <div className='no-billing-info'>
          <h3>{t('NoBillingInformation', { ns: 'billingInformation' })}</h3>
        </div>
      ) : (
        <>
          <div className='profile-inputs-column-title'>
            <InvoiceIcon />
            {t('BillingInformation', { ns: 'billingInformation' })}
          </div>
          <div className='billing-info-wrapper'>
            <div className='card-wrapper'>
              <div className='card-type'>
                {paymentMethod.card_type === 'Diners Club' && <DinersClubIcon />}
                {paymentMethod.card_type === 'American Express' && <AmericanExpressIcon />}
                {paymentMethod.card_type === 'Discover' && <DiscoverIcon />}
                {paymentMethod.card_type === 'JCB' && <JCBIcon />}
                {paymentMethod.card_type === 'MasterCard' && <MasterCardIcon />}
                {paymentMethod.card_type === 'Union Pay' && <ChinaUnionPayIcon />}
                {paymentMethod.card_type === 'Visa' && <VisaIcon />}
                <strong>{paymentMethod.card_type}</strong>
              </div>
              <div className='card-number'>**** **** **** {paymentMethod.last_four}</div>
              <div className='card-expiry'>{`${t('Expiry', { ns: 'billingInformation' })} ${
                paymentMethod.exp_month < 10 ? `0${paymentMethod.exp_month}` : paymentMethod.exp_month
              }/${paymentMethod.exp_year}`}</div>
              <div className='edit-button' onClick={() => setIsEditCardModalOn(true)}>
                <PencilIcon />
              </div>
              <CardDetailsModal isModalOn={isEditCardModalOn} setIsModalOn={setIsEditCardModalOn} />
            </div>
            <div className='billing-address-wrapper'>
              <strong>{t('BillingAddress', { ns: 'billingInformation' })}</strong>
              {bs.billingInfo.address.street1 && <p>{bs.billingInfo.address.street1}, </p>}
              {bs.billingInfo.address.street2 && <p>{bs.billingInfo.address.street2}, </p>}
              {bs.billingInfo.address.postal_code && (
                <p>
                  {bs.billingInfo.address.postal_code} {bs.billingInfo.address.city}, {bs.billingInfo.address.country}
                </p>
              )}
              <div className='edit-button' onClick={() => setIsEditAddressModalOn(true)}>
                {t('EditBillingInformation', { ns: 'billingInformation' })}
              </div>
              <BillingAddressModal isModalOn={isEditAddressModalOn} setIsModalOn={setIsEditAddressModalOn} />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default BillingAndPaymentTab;
