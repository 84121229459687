import { SelectOptions } from '@trackman/web-shared-components';
import { Unit } from '../constants/supportedUnits';
import { radarToDisplayUnits } from './unitConverters';

type Props = {
  mutableObject: Object;
  updatePath: string;
  newValue: number | string;
};

export const changeValueByPath = ({ mutableObject, updatePath, newValue }: Props) =>
  updatePath.split('.').reduce((o, p, i) => (o[p] = updatePath.split('.').length === ++i ? newValue : o[p] || {}), mutableObject);

type GetValueProps = {
  readableObject: Object;
  readPath: string;
};

export const getValueByPath = ({ readableObject, readPath }: GetValueProps) => {
  try {
    let readValue = readPath.split('.').reduce((a, v) => a[v], readableObject) as unknown as number | string;
    if (typeof readValue === 'string' && readValue.slice(0, 2) === '{{') {
      const newPath =
        'calculators.' +
        readValue
          .slice(2, readValue.length - 2)
          .split(':')
          .join('.');
      return getValueByPath({ readableObject, readPath: newPath });
    }
    if (typeof readValue === 'string' && readValue === 'driver') {
      let firstLetter = readValue.charAt(0).toUpperCase();
      let remainingLetters = readValue.slice(1);
      readValue = firstLetter + remainingLetters;
    } // missing capitalization exception handling
    return readValue === undefined ? 0 : readValue;
  } catch {
    try {
      return readableObject[readPath];
    } catch {
      return '';
    }
  }
};

type GetNumericDisplayValueProps = {
  valueInRadarUnits: number;
  unit: Unit;
  precision: number;
};

export const getNumericDisplayValue = ({ valueInRadarUnits, unit, precision }: GetNumericDisplayValueProps): number => {
  const valueInDisplayUnits = radarToDisplayUnits(valueInRadarUnits, unit);
  return Number.parseFloat(valueInDisplayUnits.toFixed(precision));
};

type GetSelectDisplayValueProps = {
  selectValue: string;
  selectOptions: SelectOptions[];
};

export const getSelectDisplayValue = ({ selectValue, selectOptions }: GetSelectDisplayValueProps): string => {
  const selectValueToTranslation = {};
  selectOptions.forEach((obj) => (selectValueToTranslation[obj.value] = obj.label));
  return selectValueToTranslation[selectValue] ?? '';
};
