import { Dispatch, SetStateAction, useCallback } from 'react';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { changeValueByPath } from '../helpers/valueManipulators';

const useValueSetter = (setCalcOptions: Dispatch<SetStateAction<CalculatorOptions>>) => {
  const setCalcVariable = useCallback(
    (updatePath: string, newValue: string | number) => {
      setCalcOptions((calcOptions) => {
        const tempCalcOptions = { ...calcOptions };
        changeValueByPath({ mutableObject: tempCalcOptions, updatePath, newValue });
        return { ...tempCalcOptions };
      });
    },
    [setCalcOptions]
  );

  return { setCalcVariable };
};

export default useValueSetter;
