import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import { Checkbox } from '@trackman/web-shared-components';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel;
};

export const ComingSoonSetting = observer(({ dataItem }: Props) => {
  return (
    <Checkbox
      label='Coming soon'
      placeholder=''
      withoutBorder
      reversed
      onChange={() => (dataItem.comingSoon = !dataItem.comingSoon)}
      checked={dataItem.comingSoon}
    />
  );
});

export default ComingSoonSetting;
