import { AdminOrganization, AdminOrganizationInstructor, AdminOrganizationsList } from 'types/admin/organizations';
import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../../NotificationsStore';
import OrganizationsEditorService from '../../../services/Admin/Editors/OrganizationsEditorService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class OrganizationsEditorStore {
  private organizationsEditorService: OrganizationsEditorService;
  private ns: NotificationsStore;

  constructor(organizationsEditorService: OrganizationsEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.organizationsEditorService = organizationsEditorService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  isLookingUpEmails: boolean = false;
  organizationsList: AdminOrganizationsList = [];
  filteredList: AdminOrganizationsList = [];
  cleanOrganization: AdminOrganization = {
    id: '',
    name: '',
    instructors: [],
    disabled: false,
    numberOfInvites: 0,
    membershipDurationMonth: 0,
  };
  organization: AdminOrganization = { ...this.cleanOrganization };
  selectedOrganizationId: string = '';
  numberOfLicensesToAdd: number = 0;
  isInviteInstructorsModalOpen: boolean = false;
  emailsToCheck: string[] = [];
  foundInstructors: AdminOrganizationInstructor[] = [];
  notFoundInstructors: string[] = [];

  getOrganizations = async () => {
    try {
      this.isLoading = true;
      const response = await this.organizationsEditorService.getOrganizations();
      this.organizationsList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getOrganization = async (lessonId: string) => {
    try {
      this.isLoading = true;
      const response = await this.organizationsEditorService.getOrganization(lessonId);
      this.organization = response;
      this.organization.instructors = this.organization.instructors || [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewOrgAndUpdateOrg = async () => {
    try {
      this.isLoading = true;
      await this.organizationsEditorService.saveNewOrganization(this.organization);
      this.organization = { ...this.cleanOrganization };
      this.getOrganizations();
      this.ns.addToast('success', 'New organization has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedOrgAndUpdateOrgs = async () => {
    try {
      this.isLoading = true;
      this.organization.numberOfInvites = this.numberOfLicensesToAdd;
      await this.organizationsEditorService.saveEditedOrganization(this.organization);
      this.organization.id && this.getOrganization(this.organization.id);
      this.ns.addToast('success', 'Organization has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteOrganizationAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.organizationsEditorService.deleteOrganization(this.selectedOrganizationId);
      this.getOrganizations();
      this.ns.addToast('success', 'Organization has been deleted successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedOrganizationId = '';
    this.organization = { ...this.cleanOrganization };
    this.isLoading = false;
  };

  instructorsEmailLookup = async (emails: string[]) => {
    try {
      this.isLookingUpEmails = true;
      const response = await this.organizationsEditorService.instructorsEmailLookup(emails);

      this.foundInstructors = response;
      this.notFoundInstructors = this.emailsToCheck.filter((email) => !this.foundInstructors.find((el) => el.email === email));

      if (!this.notFoundInstructors.length) {
        this.organization.instructors = [...this.organization.instructors, ...this.foundInstructors];
        this.isInviteInstructorsModalOpen = false;
        this.ns.addToast('success', 'Instructors have been added successfully', 'success');
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLookingUpEmails = false;
  };
}

export default OrganizationsEditorStore;
