import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { ClassesTableRows } from 'types/organization/organization';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  rows: ClassesTableRows[];
};

export const InvitesModalTable = observer(({ rows }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  const handleSelectOne = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = cs.invitesToAdd.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(cs.invitesToAdd, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(cs.invitesToAdd.slice(1));
      } else if (selectedIndex === cs.invitesToAdd.length - 1) {
        newSelected = newSelected.concat(cs.invitesToAdd.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(cs.invitesToAdd.slice(0, selectedIndex), cs.invitesToAdd.slice(selectedIndex + 1));
      }

      cs.invitesToAdd = newSelected;
    },
    [cs]
  );

  return (
    <Table sx={{ minWidth: 750 }} aria-labelledby='invitesTable'>
      <TableHead>
        <TableRow className='sub-table-head-row'>
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={cs.invitesToAdd.length > 0 && cs.invitesToAdd.length < rows.length}
              checked={cs.invitesToAdd.length > 0 && cs.invitesToAdd.length === rows.length}
              onChange={() => (!cs.invitesToAdd.length ? (cs.invitesToAdd = rows.map((n) => n.id)) : (cs.invitesToAdd = []))}
              inputProps={{
                'aria-label': t('SelectAllInvites', { ns: 'studentsPage' }),
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 100 }}></TableCell>
          <TableCell sx={{ width: 250 }}>{t('Invites', { ns: 'organizationPage' })}</TableCell>
          <TableCell sx={{ width: 250 }}></TableCell>
          <TableCell sx={{ width: 250 }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => {
          const isItemSelected = cs.invitesToAdd.indexOf(row.id) !== -1;
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleSelectOne(event, row.id)}
              role='checkbox'
              aria-checked={isItemSelected}
              key={row.id}
              selected={isItemSelected}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  checked={isItemSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </TableCell>
              <TableCell className='initials-cell' sx={{ width: 100 }}>
                <div>{row.initials}</div>
              </TableCell>
              <TableCell sx={{ width: 250 }}>{row.name}</TableCell>
              <TableCell sx={{ width: 250 }}>{row.classes}</TableCell>
              <TableCell sx={{ width: 250 }}>{row.email}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});

export default InvitesModalTable;
