import './OutputBox.scss';

import { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from 'services/Calculator/CalculatorService';
import { getNumericDisplayValue, getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';

import { OutputVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import { useStores } from 'index';

type Props = {
  outputVariable: OutputVariable;
  calcResult: BallLaunch | ClubDelivery | SwingDirection | BounceAndRoll;
  htmlColor: string;
};

const OutputBox = observer(({ outputVariable, calcResult, htmlColor }: Props) => {
  const cs = useStores().calculatorStore;
  const { label, unit, precision, symbol } = useNumericConstants(outputVariable);

  const isLoadingCalcResult = useMemo(() => cs.isLoading, [cs.isLoading]);

  const displayValue = useMemo(() => {
    const outputValue = getValueByPath({ readableObject: calcResult, readPath: outputVariable });
    const displayValue =
      typeof outputValue === 'number'
        ? getNumericDisplayValue({ valueInRadarUnits: outputValue, unit, precision }).toFixed(precision)
        : outputValue;

    // Edge case handling
    if (outputVariable === 'LandingData.CarrySide' || outputVariable === 'LandingData.TotalSide')
      return `${Math.abs(displayValue)}${displayValue > 0 ? 'R' : displayValue < 0 ? 'L' : ''}`;

    return displayValue;
  }, [outputVariable, calcResult, unit, precision]);

  return (
    <>
      <div className='table-header' style={{ backgroundColor: `${htmlColor}80` }}>
        {label}
      </div>
      <div className={`value ${isLoadingCalcResult ? 'animate-pulse' : ''}`}>{displayValue}</div>
      <div className='unit'>{symbol}</div>
    </>
  );
});

export default OutputBox;
