import './RemoveTableFromStore.scss';

import { ReactComponent as Trash } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type Props = {
  tableId: string;
};

const RemoveTableFromStore = observer(({ tableId }: Props) => {
  const sct = useStores().savedCalcTableStore;

  return (
    <button className='remove-button' onClick={() => sct.deleteTable(tableId)}>
      <Trash />
    </button>
  );
});

export default RemoveTableFromStore;
