import { ReactComponent as ChineseFlag } from '../images/icons/languages/ChineseFlag.svg';
import { ReactComponent as GermanFlag } from '../images/icons/languages/GermanFlag.svg';
import { ReactComponent as Globe } from '../images/icons/menu/Globe.svg';
import { ReactComponent as JapaneseFlag } from '../images/icons/languages/JapaneseFlag.svg';
import { ReactComponent as KoreanFlag } from '../images/icons/languages/KoreanFlag.svg';
import { LanguageType } from '../types/languages';
import { ReactComponent as SpanishFlag } from '../images/icons/languages/SpanishFlag.svg';
import { ReactComponent as USAFlag } from '../images/icons/languages/AmericanFlag.svg';
import { observer } from 'mobx-react-lite';

type Props = {
  countryCode: LanguageType;
};

export const CountryFlag = observer(({ countryCode }: Props) => {
  const flag = () => {
    switch (countryCode) {
      case 'de-DE':
        return <GermanFlag />;
      case 'ja-JP':
        return <JapaneseFlag />;
      case 'ko-KR':
        return <KoreanFlag />;
      case 'en-US':
        return <USAFlag />;
      case 'zh-CN':
        return <ChineseFlag />;
      case 'es-ES':
        return <SpanishFlag />;
      default:
        return <Globe />;
    }
  };

  return <>{flag()}</>;
});
