import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { getLanguageText } from '../../../../utilities/languages';
import { mapBooleanToString } from 'utilities/booleanMapper';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  title: string;
  description: string;
  languages: string;
  visible: string;
  comingSoon: string;
  certificate: string;
};

export const LessonList = observer(() => {
  useSetPageTitle({ title: 'Lessons List', subtitle: '' });
  const ls = useStores().lessonEditorStore;
  const [searchValue, setSearchValue] = useState('');
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    ls.getLessons();
  }, [ls]);

  useEffect(() => {
    ls.filteredList = ls.lessonsList.filter((lesson) =>
      lesson.titles
        .find((title) => title.countryCode === 'en-US')
        ?.text.toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }, [ls, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      ls.filteredList.map((lesson) => {
        return {
          id: lesson.id,
          title: lesson.titles.find((title) => title.countryCode === 'en-US')?.text.toLowerCase(),
          description: lesson.descriptions.find((description) => description.countryCode === 'en-US')?.text,
          languages: lesson.languages ? lesson.languages.map((lng) => getLanguageText(lng)).join(', ') : '',
          visible: mapBooleanToString(lesson.visible),
          comingSoon: mapBooleanToString(lesson.comingSoon),
          certificate: mapBooleanToString(lesson.certification),
        };
      }) as RowsDisplay[],
    [ls.filteredList]
  );

  const deleteLesson = useCallback(() => {
    ls.deleteLessonAndUpdate();
    setIsModalOn(false);
  }, [ls, setIsModalOn]);

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field'>
            <input
              className='search-input'
              placeholder={'Search...'}
              name='search-for-lesson'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.LessonEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New lesson</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 300 }}>Name</TableCell>
              <TableCell sx={{ width: 600 }}>Languages</TableCell>
              <TableCell sx={{ width: 200 }}>Visible</TableCell>
              <TableCell sx={{ width: 200 }}>Coming soon</TableCell>
              <TableCell sx={{ width: 200 }}>Certification</TableCell>
              <TableCell sx={{ width: 200 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>
                      <div className='description-tooltip'>
                        {row.title}
                        <Tooltip
                          title={row.description ? row.description : 'No description'}
                          placement='bottom'
                          className='table-cell-tooltip'
                        >
                          <InfoIcon />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>{row.languages}</TableCell>
                    <TableCell>{row.visible}</TableCell>
                    <TableCell>{row.comingSoon}</TableCell>
                    <TableCell>{row.certificate}</TableCell>
                    <TableCell>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.LessonEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className='row-button'
                          onClick={() => {
                            ls.selectedLessonId = row.id;
                            setIsModalOn(true);
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No lessons found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deleteLesson} itemName='lesson' />
    </div>
  );
});

export default LessonList;
