import AdminWYSIWYGEditor from 'components/Admin/AdminWYSIWYGEditor/AdminWYSIWYGEditor';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const ParameterDefinition = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>Definition</h2>
      {ps.parameter.languages.map((language) => {
        const definition = ps.parameter.definitions.find((def) => def.countryCode === language);
        return (
          <AdminWYSIWYGEditor
            key={language}
            text={definition?.text ? definition.text : ''}
            dataItems={ps.parameter.definitions}
            language={definition?.countryCode ? definition?.countryCode : language}
          />
        );
      })}
    </div>
  );
});

export default ParameterDefinition;
