import { Organization, OrganizationList } from 'types/organization/organization';
import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import OrganizationsService from '../../services/CoachSection/OrganizationsService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class OrganizationsStore {
  private organisationsService: OrganizationsService;
  private ns: NotificationsStore;

  constructor(organisationsService: OrganizationsService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.organisationsService = organisationsService;
    this.ns = notificationStore;
  }

  isOrgListLoading: boolean = true;
  isSelectedOrgLoading: boolean = false;
  orgList: OrganizationList = [];
  selectedOrg: Organization = {
    id: '',
    name: '',
    instructors: null,
    disabled: false,
    numberOfInvites: null,
    membershipDurationMonth: 0,
  };

  getOrganizations = async () => {
    try {
      this.isOrgListLoading = true;
      const response = await this.organisationsService.getOrganizations();
      this.orgList = response;
      this.selectedOrg = this.orgList.length ? this.orgList[0] : this.selectedOrg;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isOrgListLoading = false;
  };
}

export default OrganizationsStore;
