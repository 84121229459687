import { Parameter } from './supportedVariablesAndParameters';
import { i18nInit } from 'i18nSetup';
import i18next from 'i18next';

// todo - delete commented code and add translations where needed

i18nInit();

// All parameters need to be translated, hence Parameter type used
export const parameterToName: { [key in Parameter]: string } = {
  ClubSpeed: i18next.t('ClubSpeed', { ns: 'units' }),
  AttackAngle: i18next.t('AttackAngle', { ns: 'units' }),
  ClubPath: i18next.t('ClubPath', { ns: 'units' }),
  DynamicLoft: i18next.t('DynamicLoft', { ns: 'units' }),
  FaceAngle: i18next.t('FaceAngle', { ns: 'units' }),
  SpinLoft: i18next.t('SpinLoft', { ns: 'units' }),
  FaceToPath: i18next.t('FaceToPath', { ns: 'units' }),
  SwingPlane: i18next.t('SwingPlane', { ns: 'units' }),
  SwingDirection: i18next.t('SwingDirection', { ns: 'units' }),
  // LowPoint: i18next.t('LowPoint', { ns: 'units' }),
  BallSpeed: i18next.t('BallSpeed', { ns: 'units' }),
  SmashFactor: i18next.t('SmashFactor', { ns: 'units' }),
  LaunchAngle: i18next.t('LaunchAngle', { ns: 'units' }),
  LaunchDirection: i18next.t('LaunchDirection', { ns: 'units' }),
  SpinRate: i18next.t('SpinRate', { ns: 'units' }),
  SpinAxis: i18next.t('SpinAxis', { ns: 'units' }),
  MaxHeight: i18next.t('Height', { ns: 'units' }),
  Carry: i18next.t('Carry', { ns: 'units' }),
  Total: i18next.t('Total', { ns: 'units' }),
  CarrySide: i18next.t('CarrySide', { ns: 'units' }),
  TotalSide: i18next.t('SideTotal', { ns: 'units' }),
  LandingAngle: i18next.t('LandingAngle', { ns: 'units' }),
  // FromPin: i18next.t('FromPin', { ns: 'units' }),
  // Targetdistance: i18next.t('Target', { ns: 'units' }),
  // HangTime: i18next.t('HangTime', { ns: 'units' }),
  // LastData: i18next.t('LastData', { ns: 'units' }),
  // Score: i18next.t('Score', { ns: 'units' }),
  WindDirection: i18next.t('WindDirection', { ns: 'units' }),
  WindSpeed: i18next.t('WindSpeed', { ns: 'units' }),
  Humidity: i18next.t('Humidity', { ns: 'units' }),
  Temperature: i18next.t('Temperature', { ns: 'units' }),
  Altitude: i18next.t('Altitude', { ns: 'units' }),
  // LandingSpeed: i18next.t('LandingSpeed', { ns: 'units' }),
  LandingBallSpeed: i18next.t('LandingBallSpeed', { ns: 'units' }),
  // LandingSpinRate: i18next.t('LandingSpinRate', { ns: 'units' }),
  LandingHeight: i18next.t('LandingHeight', { ns: 'units' }),
  Distance: i18next.t('Distance', { ns: 'units' }),

  ClubType: 'Club Type',
  OptimizationType: 'Optimization',
  TrajectoryType: 'Trajectory',
  SurfaceType: 'Surface Type',
  SurfaceHardness: 'Surface Hardness',
};
