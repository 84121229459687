import './ProfileInput.scss';

import { MenuItem, TextField } from '@mui/material';

import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';

type ProfileInputProps = {
  label: string;
  value: unknown;
  onChange: (newValue: string) => void;
  type?: string;
  selectOptions?: { value: string; label: string }[];
  helperText?: string;
  error?: boolean;
  errorMsg?: string;
  className?: string;
  required?: boolean;
};

export const ProfileInput = observer(
  ({
    label,
    value,
    onChange,
    type,
    selectOptions = [],
    helperText,
    error,
    errorMsg = i18next.t('ThisFieldIsRequired', { ns: 'general' }),
    className,
    required,
  }: ProfileInputProps) => {
    return (
      <div className={`text-field ${className ? className : ''}`}>
        <TextField
          select={selectOptions.length > 0}
          id={label}
          label={label}
          value={value}
          type={type}
          onChange={selectOptions.length === 0 ? (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value) : undefined}
          fullWidth
          helperText={helperText}
          className={error ? 'input-error' : ''}
          variant='filled'
          required={required}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} onClick={() => onChange(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {error && (
          <p className='error-info'>
            <ErrorIcon />
            <span>{errorMsg}</span>
          </p>
        )}
      </div>
    );
  }
);

export default ProfileInput;
