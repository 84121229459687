import { AdminParameterCategory } from 'types/admin/trackmanParameters';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const parameterCategories: AdminParameterCategory[] = ['Club', 'Launch', 'Flight', 'Roll', 'Green'];

export const ParameterCategories = observer(() => {
  const ps = useStores().parametersStore;

  return (
    <div className='admin-edit-section'>
      <h2>Parameter categories</h2>
      <ProfileInput
        label='Category'
        value={ps.parameter.category ?? ''}
        onChange={(newValue) => {
          if (parameterCategories.includes(newValue as AdminParameterCategory)) {
            ps.parameter.category = newValue as AdminParameterCategory;
          }
        }}
        selectOptions={parameterCategories.map((category) => {
          return {
            label: category || '',
            value: category,
          };
        })}
      />
    </div>
  );
});

export default ParameterCategories;
