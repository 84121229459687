import './LessonTileOverlay.scss';

import { ReactComponent as PadlockIcon } from 'images/icons/Padlock.svg';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type LessonTileOverlayProps = {
  color: string;
  prerequisites?: string[];
  isDisabled: boolean | undefined;
  certification: boolean | undefined;
  hasMembership?: boolean;
};

export const LessonTileOverlay = observer(({ color, prerequisites, isDisabled, certification, hasMembership }: LessonTileOverlayProps) => {
  const { t } = useTranslation();

  let overlayText = '';
  if (isDisabled || (certification && hasMembership))
    overlayText = t('PleaseTakeXCourseFirst', { ns: 'modulesAndTests', courseTitle: prerequisites?.toString() });
  if (certification && !hasMembership) overlayText = t('PremiumNeeded', { ns: 'modulesAndTests' });

  return (
    <div className='lesson-tile-overlay-wrapper'>
      <div className={`lesson-tile-overlay-description ${!hasMembership && certification ? 'certification' : ''}`}>
        <div className='padlock-icon' style={{ backgroundColor: `${color}` }}>
          <PadlockIcon />
        </div>
        <span>{overlayText}</span>
      </div>
    </div>
  );
});

export default LessonTileOverlay;
