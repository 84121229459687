import { configure, makeAutoObservable } from 'mobx';

import { LessonModel } from 'types/lessons';
import LessonService from '../../services/Lesson/LessonService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class LessonStore {
  private lessonService: LessonService;
  private ns: NotificationsStore;

  constructor(lessonService: LessonService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.lessonService = lessonService;
    this.ns = notificationStore;
  }

  lesson: LessonModel | undefined = undefined;
  isLoadingLesson: boolean = false;

  startLesson = async (lessonId: string, courseId: string) => {
    try {
      this.isLoadingLesson = true;
      const response = await this.lessonService.startLesson(lessonId, courseId);
      this.lesson = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoadingLesson = false;
  };
}

export default LessonStore;
