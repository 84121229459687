import { CurrentView, chartPaddingSide, pixelsPerMeterOnSvg } from '../helpers/constants';

import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';
import { useMemo } from 'react';
import usePathFunctionsAndProps from '../helpers/usePathFunctionsAndProps';

type Props = {
  position: 'club' | 'landing';
  chartDimensions: { width: number; height: number };
  currentView: CurrentView;
  trajectories: TrajectoryPoint[][];
};

const SideHill = ({ position, chartDimensions, currentView, trajectories }: Props) => {
  const { xMaxTraj } = useEdgeValuesOfTrajectories(trajectories);

  const { y, yTicks } = usePathFunctionsAndProps({
    chartDimensions,
    trajectories,
    currentView,
  });

  const height = useMemo(() => {
    if (position === 'club') return 1.2 * ((y(yTicks[0]) ?? 0) - (y(0) ?? 0));
    return (trajectories.at(-1)?.at(-1)?.Y ?? 0) < 0 ? 0 : 1.2 * ((y(0) ?? 0) - (y(trajectories.at(-1)?.at(-1)?.Y ?? 0) ?? 0));
  }, [y, yTicks, trajectories]);

  return (
    <svg
      style={{
        position: 'absolute',
        bottom: chartPaddingSide.y,
        left: position === 'club' ? 0 : xMaxTraj * pixelsPerMeterOnSvg,
        transform: `translate(-${position === 'club' ? '45%' : '60%'}, 10%)`,
      }}
      width='400'
      height={height ? height : 0}
      viewBox='0 0 718 112'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        d='M246.017 5.11827C312.197 -1.37059 412.186 -2.03355 486.289 5.11827C560.392 12.2701 562.546 77.7203 684.126 95.3056C805.706 112.891 577.654 111.992 354.638 111.992C131.621 111.992 -85.082 110.204 33.6199 95.3056C152.322 80.4068 179.837 11.6071 246.017 5.11827Z'
        fill='#298D55'
      />
      <ellipse cx='367.587' cy='10.5632' rx='131.641' ry='9.94479' fill='#2B9956' />
    </svg>
  );
};

export default SideHill;
