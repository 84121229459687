import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const NoParametersFound = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='no-parameters-found'>
      <h2>{t('NoParametersAddedYet', { ns: 'trackmanParameters' })}</h2>
    </div>
  );
});

export default NoParametersFound;
