import { CurrentView, chartPaddingSide, pixelsPerMeterOnSvg } from '../helpers/constants';

import { Fragment } from 'react';
import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import usePathFunctionsAndProps from '../helpers/usePathFunctionsAndProps';

type Props = {
  chartDimensions: { width: number; height: number };
  currentView: CurrentView;
  trajectories: TrajectoryPoint[][];
};

const SideTicksAndTrajectory = ({ chartDimensions, trajectories, currentView }: Props) => {
  const { symbol: xSymbol } = useNumericConstants('Distance');
  const { symbol: ySymbol } = useNumericConstants('LandingData.MaxHeight');

  const { xMinTraj, xMaxTraj, yMinTraj, yMaxTraj } = useEdgeValuesOfTrajectories(trajectories);

  const { x, y, trajectoryLineSide, fullPathScale, xTicks, yTicks, backgroundScale, svgFontSize } = usePathFunctionsAndProps({
    chartDimensions,
    trajectories,
    currentView,
  });

  return (
    <svg
      style={{
        position: 'absolute',
        left: chartPaddingSide.x,
        bottom: chartPaddingSide.y,
        color: '#ec691a',
        overflow: 'visible',
        transition: 'opacity 150ms ease',
      }}
      width={chartDimensions.width / fullPathScale}
      height={chartDimensions.height / fullPathScale}
    >
      {yTicks.map((tick, i) => (
        <Fragment key={`yTickAndLabel-${tick}-${backgroundScale}`}>
          <line
            x1={x(xMinTraj)}
            x2={x(xMaxTraj)}
            y1={y(tick)}
            y2={y(tick)}
            stroke={'rgba(0, 0, 0, 0.09)'}
            strokeWidth={1 / backgroundScale}
          />
          {i !== 0 && (
            <text
              key={`xLabel-${tick}-${ySymbol}-${svgFontSize}`}
              x={(x(xMinTraj) ?? 0) + svgFontSize / 3}
              y={(y(tick) ?? 0) - svgFontSize / 3}
              fill={'black'}
              fontSize={svgFontSize.toFixed(2)}
            >
              {i === yTicks.length - 1 ? ySymbol : tick}
            </text>
          )}
        </Fragment>
      ))}
      {xTicks.map((tick, i) => (
        <Fragment key={`xTickAndLabel-${tick}-${backgroundScale}`}>
          <line
            x1={x(tick)}
            x2={x(tick)}
            y1={y(yMinTraj)}
            y2={y(2 * yMaxTraj)}
            stroke={'rgba(0, 0, 0, 0.09)'}
            strokeWidth={1 / backgroundScale}
          />
          {i !== 0 && (
            <text
              key={`xLabel-${tick}-${xSymbol}-${svgFontSize}`}
              x={(x(tick) ?? 0) + svgFontSize / 3}
              y={(y(yMinTraj) ?? 0) - svgFontSize / 3}
              fill={'black'}
              fontSize={svgFontSize.toFixed(2)}
            >
              {i === xTicks.length - 1 ? xSymbol : tick}
            </text>
          )}
        </Fragment>
      ))}
      <path
        key={`side-view-${JSON.stringify(trajectories)}`} // without a key it will not rerender
        style={{
          strokeDasharray: (xMaxTraj + 2 * yMaxTraj) * pixelsPerMeterOnSvg, // estimation of path length, the values must be higher than actual length
          strokeDashoffset: (xMaxTraj + 2 * yMaxTraj) * pixelsPerMeterOnSvg, // estimation of path length, the values must be higher than actual length
          animation: 'dash 1.5s ease forwards',
        }}
        fill='none'
        stroke='currentColor'
        strokeWidth={3 / backgroundScale}
        d={trajectoryLineSide(trajectories.flat(1)) ?? undefined} // flattening the trajectories makes them into one path, what enables the animation
      />
    </svg>
  );
};

export default SideTicksAndTrajectory;
