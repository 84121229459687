import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import { Students } from 'types/organization/students';
import i18next from 'i18next';

export class StudentsService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getStudents = async (orgId: string) => {
    const response = await this.axiosInstance.get<Result<Students>>(`/api/students?organizationId=${orgId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('GetStudentsError', { ns: 'studentsPage' }));
  };

  getNumberOfStudents = async (orgId: string) => {
    const response = await this.axiosInstance.get<Result<number>>(`/api/students/number?organizationId=${orgId}`);

    if (response.data && !response.data.isError && response.data.data !== undefined) {
      return response.data.data;
    }
    throw Error(i18next.t('GetNumberOfStudentsError', { ns: 'studentsPage' }));
  };

  removeStudents = async (orgId: string, students: Array<string>) => {
    const response = await this.axiosInstance.delete(`/api/students?organizationId=${orgId}`, { data: students });

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('RemoveStudentsError', { ns: 'studentsPage' }));
  };
}

export default StudentsService;
