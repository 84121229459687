import '../../../pages/Admin/Admin.scss';

import { useCallback, useEffect } from 'react';

import { CalculatorKind } from 'types/learningMaterials';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { getCalcOptionsByType } from 'utilities/adminCalculatorOptions';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type Props = {
  setSaveButtonDisabled: (value: boolean) => void;
};

export const LearningMaterialTypes = observer(({ setSaveButtonDisabled }: Props) => {
  const lms = useStores().learningMaterialStore;
  const ms = useStores().mediaStore;

  useEffect(() => {
    lms.getAllLearningMaterialTypes();
  }, [lms]);

  const handleTypeChange = useCallback(
    (newValue: string) => {
      setSaveButtonDisabled(false);

      if (newValue === 'Video' || newValue === 'Picture') setSaveButtonDisabled(true);

      lms.learningMaterial.kind = newValue;
      lms.learningMaterial.medias = [];
      ms.mediaCollection = {};
      lms.learningMaterial.calculatorOptions = getCalcOptionsByType('CALC_NUM' as CalculatorKind);
      lms.learningMaterial.calculatorKind = 'CALC_NUM';
      lms.learningMaterial.youTubeKey = '';
    },
    [lms, ms, setSaveButtonDisabled]
  );

  return (
    <ProfileInput
      label='Type'
      value={lms.learningMaterial.kind ?? ''}
      onChange={(newValue) => handleTypeChange(newValue)}
      selectOptions={lms.learningMaterialTypes.map((type) => {
        return {
          label: type.displayName === ' ' ? '' : type.displayName,
          value: type.displayName,
        };
      })}
    />
  );
});

export default LearningMaterialTypes;
