import './LearningMaterialsLessonVideo.scss';

import { observer } from 'mobx-react-lite';

type LearningMaterialsLessonVideoProps = {
  title: string;
  youTubeId: string;
};

export const LearningMaterialsLessonVideo = observer(({ title, youTubeId }: LearningMaterialsLessonVideoProps) => {
  const url = `//www.youtube.com/embed/${youTubeId}?autoplay=0&amp;rel=0&amp;fs=1&enablejsapi=1&playerapiid=player&amp;wmode=opaque&amp;hl=en_US&amp;cc_lang_pref=en_US`;

  return (
    <div className='video-content lesson-content'>
      <figure>
        <iframe
          id='player'
          className='video-in-iframe'
          src={url}
          height={440}
          width={792}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title={title}
        ></iframe>
      </figure>
    </div>
  );
});

export default LearningMaterialsLessonVideo;
