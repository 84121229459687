import './PurchaseMembershipForm.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useCheckoutPricing, useRecurly } from '@recurly/react-recurly';

import { MembershipPlanTable } from 'components/PurchaseMembership/MembershipPlanTable/MembershipPlanTable';
import { PaymentDetails } from 'components/PurchaseMembership/PaymentDetails/PaymentDetails';
import { PricingFormState } from 'types/purchaseMembership';
import { RecurlyError } from '@recurly/recurly-js';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const PurchaseMembershipForm = observer(() => {
  const { t } = useTranslation();
  const bs = useStores().buySubscriptionStore;
  const recurly = useRecurly();
  const formRef = useRef<HTMLFormElement>(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [is3DSecureInitialized, setIs3DSecureInitialized] = useState(false);
  const [recurlyPlanError, setRecurlyPlanError] = useState<RecurlyError | null>(null);
  const [pricingFormState, setPricingFormState] = useState<PricingFormState>({
    plan: 'tmu_us',
    coupon: '',
    billingCountry: '',
    billingPostalCode: '',
    billingVatNumber: '',
    address: {
      email: '',
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
    },
  });

  const [{ price, loading }, setCheckoutPricing] = useCheckoutPricing(pricingFormState, setRecurlyPlanError);

  useEffect(() => {
    const subscriptions = pricingFormState.plan ? [{ plan: pricingFormState.plan }] : [];
    const address = {
      country: pricingFormState.billingCountry,
      postal_code: pricingFormState.billingPostalCode,
      first_name: pricingFormState.address.first_name,
      last_name: pricingFormState.address.last_name,
    };

    setCheckoutPricing({ ...pricingFormState, address, subscriptions });
  }, [pricingFormState, setCheckoutPricing]);

  useEffect(() => {
    setErrorMessage(bs.ThreeDSecureError);
  }, [bs.ThreeDSecureError]);

  const handleThreeDSecure = useCallback(
    (cardTokenId: string, actionTokenId: string) => {
      setIs3DSecureInitialized(false);
      const threeDSecure = recurly.Risk().ThreeDSecure({ actionTokenId: actionTokenId });

      threeDSecure.on('token', (actionToken) =>
        bs.sendThreeDSecureRequest(
          cardTokenId,
          pricingFormState.address.email,
          pricingFormState.plan,
          price.currency.code,
          pricingFormState.coupon,
          actionToken.id
        )
      );

      threeDSecure.attach(document.body);
      bs.isTokenHandling = false;
    },
    [bs, recurly]
  );

  const onRecurlyValidationError = (err: RecurlyError) => {
    if (err.fields) {
      setErrorMessage(`${err.message}. ${t('TheFollowingFieldsAppearToBeInvalid', { ns: 'purchaseMembership' })} ${err.fields.join(', ')}`);
    } else if (err && err.message) {
      setErrorMessage(err.message);
    }
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      setIs3DSecureInitialized(false);

      if (event.preventDefault) event.preventDefault();
      setErrorMessage('');
      setIsButtonEnabled(false);

      if (bs.discountData.couponCode) {
        bs.discountData.currencySymbol = price.currency.symbol;
        bs.discountData.discount = price.now.discount;
      }

      formRef.current &&
        recurly.token(formRef.current, (err, token) => {
          if (err) {
            onRecurlyValidationError(err);
          } else {
            bs.tokenId = token.id;
            bs.tokenHandler(pricingFormState.address.email, pricingFormState.plan, price.currency.code, pricingFormState.coupon);
          }
          setIsButtonEnabled(true);
        });
    },
    [bs, recurly]
  );

  useEffect(() => {
    if (bs.isTokenHandling && bs.ThreeDSecureToken && bs.tokenId && !is3DSecureInitialized) {
      setIs3DSecureInitialized(true);
      handleThreeDSecure(bs.tokenId, bs.ThreeDSecureToken);
    }
  }, [bs.isTokenHandling, handleThreeDSecure, bs.ThreeDSecureToken, bs.tokenId]);

  useEffect(() => {
    bs.tokenHandlingError && setErrorMessage(bs.tokenHandlingError);
  }, [bs.tokenHandlingError]);

  return (
    <form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
      <div className='go-premium-section'>
        <strong className='section-title'>{t('YourPlan', { ns: 'billingInformation' })}</strong>
        <MembershipPlanTable
          loading={loading}
          recurlyPlanError={recurlyPlanError}
          setRecurlyPlanError={setRecurlyPlanError}
          price={price}
          pricingFormState={pricingFormState}
          setPricingFormState={setPricingFormState}
        />
      </div>
      <div className='go-premium-section'>
        <strong className='section-title'>{t('PaymentDetails', { ns: 'purchaseMembership' })}</strong>
        <PaymentDetails
          pricingFormState={pricingFormState}
          setPricingFormState={setPricingFormState}
          errorMessage={errorMessage}
          loading={loading}
          recurlyPlanError={recurlyPlanError}
          isButtonEnabled={isButtonEnabled}
        />
      </div>
    </form>
  );
});

export default PurchaseMembershipForm;
