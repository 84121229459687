import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import Paragraph from 'components/TextComps/Paragraph';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { TextArea } from '@trackman/web-shared-components';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel;
};

export const DescriptionInputs = observer(({ dataItem }: Props) => {
  return (
    <div className='admin-edit-section'>
      <h2>Description</h2>
      <div className='inputs-container'>
        {dataItem.languages.map((language) => {
          const foundDescription = dataItem.descriptions.find((desc) => desc.countryCode === language);

          return (
            <div key={language} className='input-wrapper'>
              <Paragraph>
                <>{getLanguageText(language)}</>
              </Paragraph>
              <TextArea
                label={`${getLanguageText(language)} description`}
                value={foundDescription?.text ?? ''}
                onChange={(e) =>
                  foundDescription
                    ? (foundDescription.text = e.target.value)
                    : dataItem.descriptions.push({ countryCode: language, text: e.target.value })
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
