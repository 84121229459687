import { Invites } from 'types/organization/invites';
import { Students } from 'types/organization/students';

export const filterUsersByClass = (usersList: Invites | Students, classId: string, includeInClass: boolean = true) => {
  if (Array.isArray(usersList) && usersList.length > 0) {
    if ('organisationName' in usersList[0]) {
      return (usersList as Invites).filter((invite) =>
        includeInClass
          ? invite.classes?.some((classItem) => classItem.id === classId)
          : !invite.classes?.some((classItem) => classItem.id === classId)
      );
    } else if ('performanceUrl' in usersList[0]) {
      return (usersList as Students).filter((student) =>
        includeInClass
          ? student.classes?.some((classItem) => classItem.id === classId)
          : !student.classes?.some((classItem) => classItem.id === classId)
      );
    }
  }
  return [];
};
