import './StudentsList.scss';

import InviteStudentsButton from '../InviteStudentsButton/InviteStudentsButton';
import { Invites } from 'types/organization/invites';
import NoPedingInvitations from '../NoPedingInvitations/NoPedingInvitations';
import NoStudentsFound from '../NoStudentsFound/NoStudentsFound';
import PendingInvites from './PendingInvites/PendingInvites';
import SeeStudents from './SeeStudents/SeeStudents';
import { Students } from 'types/organization/students';
import Tab from '@mui/material/Tab';
import TableTab from '../TableTab';
import Tabs from '@mui/material/Tabs';
import { filterUsersByClass } from 'utilities/organizationDataFilter';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const StudentsList = observer(() => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const ss = useStores().studentsStore;
  const iss = useStores().inviteStudentsStore;
  const cs = useStores().classesStore;

  const handleSelectTabChange = (newValue: number) => {
    setValue(newValue);
    ss.searchInput = '';
    ss.filteredList = filterUsersByClass(ss.students, cs.selectedClassId) as Students;
    iss.searchInput = '';
    iss.filteredList = filterUsersByClass(iss.invites, cs.selectedClassId) as Invites;
  };

  return (
    <>
      <div className='table-tabs-wrapper'>
        <div className='tabs-wrapper'>
          <Tabs value={value} onChange={(event: React.SyntheticEvent, newValue: number) => handleSelectTabChange(newValue)}>
            <Tab label={t('SeeStudents', { ns: 'studentsPage', number: ss.filteredList.length })} />
            <Tab label={t('SeePendingInvites', { ns: 'studentsPage', number: iss.filteredList.length })} />
          </Tabs>
        </div>
        <InviteStudentsButton />
      </div>
      <TableTab value={value} index={0} tabPrefix='see-students-tab'>
        {!ss.students.length ? <NoStudentsFound /> : <SeeStudents />}
      </TableTab>
      <TableTab value={value} index={1} tabPrefix='pending-invites-tab'>
        {!iss.invites.length ? <NoPedingInvitations /> : <PendingInvites />}
      </TableTab>
    </>
  );
});

export default StudentsList;
