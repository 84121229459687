import '../../../pages/Organization/Organization.scss';

import AddNewClassButton from '../AddNewClassButton/AddNewClassButton';
import AddNewClassModal from '../Modals/AddNewClassModal/AddNewClassModal';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const NoClassesFound = observer(() => {
  const { t } = useTranslation();

  return (
    <div className='no-data-found'>
      <h2>{t('NoClassesFound', { ns: 'organizationPage' })}</h2>
      <Paragraph>
        <>{t('SeemsLikeYouHaveNotCreatedAnyClasses', { ns: 'organizationPage' })}</>
      </Paragraph>
      <Paragraph>
        <>{t('AddANewOneAndCheckBackAgain', { ns: 'organizationPage' })}</>
      </Paragraph>
      <AddNewClassButton />
      <AddNewClassModal />
    </div>
  );
});

export default NoClassesFound;
