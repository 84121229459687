import { AxiosInstance } from 'axios';
import i18next from 'i18next';

export class CoursesService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getCourses = async () => {
    const response = await this.axiosInstance.get(`/api/courses`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('CoursesListFetchError', { ns: 'organizationPage' }));
  };
}

export default CoursesService;
