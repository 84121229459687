import './TextComps.scss';

type Props = {
  children: React.ReactElement;
};

export const Subtitle = ({ children }: Props) => {
  return <h3 className='tm-subtitle'>{children}</h3>;
};

export default Subtitle;
