import { Area9ProgressParameters } from 'types/organization/classes';
import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class Area9ProgressService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  showArea9Progress = async (selectedClassId: string, lessonId: string) => {
    const response = await this.axiosInstance.get<Result<Area9ProgressParameters>>(`/api/classes/${selectedClassId}/report/${lessonId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('Area9ProgressError', { ns: 'organizationPage' }));
  };
}

export default Area9ProgressService;
