import ClassesList from 'components/Organization/ClassesList/ClassesList';
import NoClassesFound from 'components/Organization/NoClassesFound/NoClassesFound';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';

export const ClassesTab = observer(() => {
  const cs = useStores().classesStore;
  const iss = useStores().inviteStudentsStore;
  const ss = useStores().studentsStore;
  const orgStore = useStores().organizationsStore;

  useEffect(() => {
    if (orgStore.selectedOrg.id) {
      ss.getStudents();
      ss.getNumberOfStudents();
      iss.getInvites();
    }
  }, [orgStore.selectedOrg.id, ss, iss]);

  if (!cs.isLoading && !cs.classesList.length) return <NoClassesFound />;

  return <ClassesList />;
});

export default ClassesTab;
