import { configure, makeAutoObservable } from 'mobx';

import BuySubscriptionService from '../../services/Subscription/BuySubscriptionService';
import { DiscountData } from 'types/purchaseMembership';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });
export class BuySubscriptionStore {
  private buySubscriptionService: BuySubscriptionService;
  private ns: NotificationsStore;

  constructor(buySubscriptionService: BuySubscriptionService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.buySubscriptionService = buySubscriptionService;
    this.ns = notificationStore;
  }

  isTokenHandling: boolean = false;
  tokenId: string = '';
  tokenHandlingError: string = '';
  ThreeDSecureToken: string = '';
  ThreeDSecureError: string = '';
  subscriptionPurchased: boolean = false;

  discountData: DiscountData = {
    couponCode: '',
    discount: '',
    currencySymbol: '',
  };
  subscriptionEmail: string = '';

  tokenHandler = async (email: string, planCode: string, currency: string, couponCode: string) => {
    try {
      this.tokenHandlingError = '';
      this.isTokenHandling = true;
      const response = await this.buySubscriptionService.tokenHandler(this.tokenId, email, planCode, currency, couponCode);

      if (response.status === 'tokenReturned') {
        this.ThreeDSecureToken = response.token;
      } else if (response.status === 'success') {
        this.subscriptionPurchased = response.isSubscriptionPurchased;
        this.isTokenHandling = false; // Loading state should stop only if token is not returned
      } else {
        this.subscriptionPurchased = response.isSubscriptionPurchased;
        this.tokenHandlingError = response.errorMessage;
        this.isTokenHandling = false; // Loading state should stop only if token is not returned
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  sendThreeDSecureRequest = async (
    tokenId: string,
    email: string,
    planCode: string,
    currency: string,
    couponCode: string,
    threeDSecureTokenId: string
  ) => {
    try {
      this.isTokenHandling = true;

      const response = await this.buySubscriptionService.sendThreeDSecureRequest(
        tokenId,
        email,
        planCode,
        currency,
        couponCode,
        threeDSecureTokenId
      );

      if (response.data && response.status !== 200) {
        this.ThreeDSecureError = response.data;
      } else if (response.data && response.data.data.isError) {
        this.ThreeDSecureError = i18next.t('PurchaseSubscriptionError', { ns: 'purchaseMembership' });
      } else {
        this.subscriptionPurchased = response.data.data;
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isTokenHandling = false;
  };
}

export default BuySubscriptionStore;
