import { AxiosInstance } from 'axios';
import { LearningMaterialsLessonDTO } from 'types/learningMaterials';
import { Result } from 'services/Result';

export class LearningMaterialsRoomLessonService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  loadLesson = async (courseId: string, lessonId: string) => {
    const response = await this.axiosInstance.get<Result<LearningMaterialsLessonDTO>>(
      `/api/library-lesson/?courseId=${courseId}&lessonId=${lessonId}`
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while loading lesson.');
  };

  markLessonItemAsDone = async (courseId: string, lessonId: string, learningMaterialId: string) => {
    const response = await this.axiosInstance.post(
      `/api/library-lesson/learningmaterial-done`,
      {
        studyRoomCourseId: courseId,
        studyRoomLessonId: lessonId,
        learningMaterialId: learningMaterialId,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while marking step as done.');
  };

  markLessonAsDone = async (courseId: string, lessonId: string) => {
    const response = await this.axiosInstance.post(
      `/api/library-lesson/lesson-done`,
      {
        studyRoomCourseId: courseId,
        studyRoomLessonId: lessonId,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while marking lesson as done.');
  };
}

export default LearningMaterialsRoomLessonService;
