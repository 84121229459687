import './FlipCard.scss';

import { ReactComponent as Flip } from '../../images/icons/Flip.svg';
import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

type FlipCardProps = {
  position: string;
  children: ReactNode;
  handleFlip: (flip: boolean) => void;
  flip: boolean;
};

export const FlipCard = observer(({ position, children, handleFlip, flip }: FlipCardProps) => {
  return (
    <div className={`side-container ${position}`}>
      {children}
      <div
        className='flipButton'
        onClick={() => {
          handleFlip(!flip);
        }}
      >
        <Flip />
      </div>
    </div>
  );
});

export default FlipCard;
