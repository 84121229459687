import '../Admin.scss';
import './TestInvitations.scss';

import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const TestInvitations = observer(() => {
  useSetPageTitle({ title: 'Test Invitations', subtitle: '' });
  const sendInvitationsStore = useStores().sendInvitationsTestStore;

  return (
    <div className='admin-content-container'>
      <div className='admin-edit-section invitation-buttons-group'>
        <button className='styled-button secondary' onClick={() => sendInvitationsStore.sendInvitation()}>
          Send student invitation
        </button>
        <button className='styled-button secondary' onClick={() => sendInvitationsStore.resendInvitation()}>
          Resend student invitation
        </button>
        <button className='styled-button secondary' onClick={() => sendInvitationsStore.sendInstructorInvitation()}>
          Send instructor invitation
        </button>
      </div>
    </div>
  );
});

export default TestInvitations;
