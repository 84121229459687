import './Hamburger.scss';

import { observer } from 'mobx-react-lite';

type Props = {
  isOpen: boolean;
};

const Hamburger = observer(({ isOpen }: Props) => {
  return (
    <div className={`hamburger-container ${isOpen ? 'hamburger-open' : ''}`}>
      <div className='hamburger-line--top' />
      <div className='hamburger-line--mid' />
      <div className='hamburger-line--bottom' />
    </div>
  );
});

export default Hamburger;
