import { ClubType } from './selectOptions';
import { SliderParameter } from './supportedVariablesAndParameters';

// todo - delete commented code and add translations where needed

// Only slider parameters can have slider limits, hence SliderParameter type used
export const sliderLimitsByClubTypeAndParameter: {
  [key in ClubType]: { [key2 in SliderParameter]: { min: number; max: number; default: number } };
} = {
  Driver: {
    ClubSpeed: { min: 33.528, max: 55.88, default: 42.0218 },
    AttackAngle: { min: -7.5, max: 7.5, default: 0 },
    // BallSpeed: { min: 46.9392, max: 84.9376, default: 61.6915 },
    // LaunchAngle: { min: 5, max: 20, default: 13.5 },
    // LaunchDirection: { min: -10, max: 10, default: 0 },
    // SpinRate: { min: 1750, max: 3750, default: 2750 },
    SpinAxis: { min: -20, max: 20, default: 0 },
    LandingHeight: { min: -36, max: 15, default: 0 },
    // SpinLoft: { min: 5, max: 25, default: 15.5 },
    DynamicLoft: { min: 5, max: 20, default: 15.5 },
    ClubPath: { min: -10, max: 10, default: 0 },
    FaceAngle: { min: -10, max: 10, default: 0 },
    // LandingAngle: { min: 25, max: 50, default: 36.5 },
    // LandingSpeed: { min: 24.5872, max: 37.9984, default: 29.0576 },
    SwingPlane: { min: 35, max: 55, default: 45 },
    SwingDirection: { min: -10, max: 10, default: 0 },
    WindSpeed: { min: 0, max: 11.176, default: 0 },
    Temperature: { min: 1.66, max: 40.55, default: 0 },
    Humidity: { min: 0, max: 1, default: 0 },
    SmashFactor: { min: 1.3, max: 1.52, default: 1.3 },
    Altitude: { min: 0, max: 2000, default: 0 },
  },
  '6Iron': {
    ClubSpeed: { min: 26.8224, max: 44.704, default: 35.7632 },
    AttackAngle: { min: -8, max: -1, default: -3 },
    // BallSpeed: { min: 35.7632, max: 73.7616, default: 49.1744 },
    // LaunchAngle: { min: 12, max: 22, default: 16.5 },
    // LaunchDirection: { min: -10, max: 10, default: 0 },
    // SpinRate: { min: 4000, max: 8000, default: 6000 },
    SpinAxis: { min: -20, max: 20, default: 0 },
    LandingHeight: { min: -36, max: 15, default: 0 },
    // SpinLoft: { min: 16, max: 48, default: 25.5 },
    DynamicLoft: { min: 15, max: 40, default: 25.5 },
    ClubPath: { min: -10, max: 10, default: 0 },
    FaceAngle: { min: -10, max: 10, default: 0 },
    // LandingAngle: { min: 30, max: 60, default: 43.5 },
    // LandingSpeed: { min: 20.1168, max: 33.528, default: 24.5872 },
    SwingPlane: { min: 53, max: 73, default: 63 },
    SwingDirection: { min: -10, max: 10, default: 0 },
    WindSpeed: { min: 0, max: 11.176, default: 0 },
    Temperature: { min: 1.66, max: 40.55, default: 0 },
    Humidity: { min: 0, max: 1, default: 0 },
    SmashFactor: { min: 1.25, max: 1.46, default: 1.25 },
    Altitude: { min: 0, max: 1981.2, default: 0 },
  },
  PitchingWedge: {
    ClubSpeed: { min: 23.2461, max: 41.1277, default: 32.1869 },
    AttackAngle: { min: -12, max: -2, default: -4 },
    // BallSpeed: { min: 24.5872, max: 62.5856, default: 38.4454 },
    // LaunchAngle: { min: 20, max: 33, default: 26.5 },
    // LaunchDirection: { min: -10, max: 10, default: 0 },
    // SpinRate: { min: 5500, max: 11500, default: 8500 },
    SpinAxis: { min: -20, max: 20, default: 0 },
    LandingHeight: { min: -36, max: 15, default: 0 },
    // SpinLoft: { min: 32, max: 72, default: 40.5 },
    DynamicLoft: { min: 30, max: 60, default: 36.5 },
    ClubPath: { min: -10, max: 10, default: 0 },
    FaceAngle: { min: -10, max: 10, default: 0 },
    // LandingAngle: { min: 30, max: 60, default: 47 },
    // LandingSpeed: { min: 20.1168, max: 33.528, default: 24.5872 },
    SwingPlane: { min: 57, max: 77, default: 67 },
    SwingDirection: { min: -10, max: 10, default: 0 },
    WindSpeed: { min: 0, max: 11.176, default: 0 },
    Temperature: { min: 1.66, max: 40.55, default: 0 },
    Humidity: { min: 0, max: 1, default: 0 },
    SmashFactor: { min: 1.05, max: 1.3, default: 1.05 },
    Altitude: { min: 0, max: 1981.2, default: 0 },
  },
};
