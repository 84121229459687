import '../../../../pages/Organization/Organization.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  selected: string[];
};

export const DeleteClassesModal = observer(({ isModalOn, setIsModalOn, selected }: Props) => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;

  const deleteClasses = useCallback(() => {
    selected.map((id) => {
      cs.deleteClassAndUpdate(id);
      setIsModalOn(false);
      return true;
    });
  }, [selected, cs, setIsModalOn]);

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      aria-labelledby={t('DeleteClass', { ns: 'classPage' })}
      aria-describedby={t('DeleteClassFromTheList', { ns: 'classPage' })}
      className='organization-modal'
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <TrashIcon />
            {t('DeleteClass', { ns: 'classPage' })}
          </h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <Paragraph>
            <>{t('AreYouSureYouWantPermanentlyDeleteThisClass', { ns: 'classPage' })}</>
          </Paragraph>
        </div>
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => setIsModalOn(false)}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button className='styled-button primary' onClick={() => deleteClasses()}>
            {t('Delete', { ns: 'studentsPage' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default DeleteClassesModal;
