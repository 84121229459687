import { ConfigurationService } from './ConfigurationService';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

export class AuthenticationService {
  private configService: ConfigurationService;
  private pathLogin: string = 'account/login';
  private pathIsUserLoggedIn: string = 'account/is-user-logged';

  constructor() {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.configService = new ConfigurationService();
  }

  isUserLoggedIn: boolean = false;
  isLoadingUserLoggedIn: boolean = true; // initialized as true to display loading immediately
  isLoggingIn: boolean = false;

  // these are methods for new TMU setup

  checkIfUserIsLoggedIn = async () => {
    this.isLoadingUserLoggedIn = true;
    try {
      const selfUrl = this.configService !== null && this.configService.getWindowOrigin();
      const requestURL = `${selfUrl}/${this.pathIsUserLoggedIn}`;
      const response = await axios.get(requestURL);
      this.isUserLoggedIn = response.data;
    } catch {
      this.isUserLoggedIn = false;
    }
    this.isLoadingUserLoggedIn = false;
  };

  logIn = async () => {
    this.isLoggingIn = true;
    window.location.assign(`${this.configService.getWindowOrigin()}/${this.pathLogin}`); // this works with the current backend configuration only
    this.isLoggingIn = false;
  };
}
