import { useEffect, useState } from 'react';

import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import { pixelsPerMeterOnSvg } from '../helpers/constants';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';

type Props = {
  trajectories: TrajectoryPoint[][];
};

const SideCart = ({ trajectories }: Props) => {
  const [isCartAnimated, setCartAnimated] = useState(false);

  useEffect(() => {
    const cartTimeout = setTimeout(() => setCartAnimated(true), 2000);
    return () => clearTimeout(cartTimeout);
  }, []);

  const averageCartSpeed = 5; // in display unit per second (meters or yards)

  const { xMaxTraj, xMaxTrajOld } = useEdgeValuesOfTrajectories(trajectories);

  return (
    <svg
      version='1.1'
      id='Layer_2'
      x='0px'
      y='0px'
      width='1417px'
      height='522px'
      viewBox='0 0 1417 522'
      xmlSpace='preserve'
      style={{
        position: 'absolute',
        bottom: 0,
        transform: `translateX(${isCartAnimated ? xMaxTraj * pixelsPerMeterOnSvg - 20 : 0}px)`, // 20 meters/yards before the ball landing
        transition: `transform ${Math.abs(xMaxTraj - xMaxTrajOld) / averageCartSpeed}s ease`,
      }}
    >
      <g>
        <g style={{ opacity: 0.1 }} id='g1285'>
          <ellipse style={{ fill: '#1D1D1B' }} cx='11' cy='489.4' rx='5' ry='0.4' id='ellipse1287' />
        </g>
        <g id='g1289'>
          <g id='g1291'>
            <path
              style={{ fill: '#ECECEC' }}
              d='M14.2,486.9C14.2,487,14.2,487,14.2,486.9L14.2,486.9c0.1,0.1,0.1,0,0.1,0v-3.4c0,0,0-0.1-0.1-0.1l0,0         c0,0-0.1,0-0.1,0.1V486.9z'
              id='path1293'
            />
          </g>
          <g id='g1295'>
            <path
              style={{ fill: '#D9D9D9' }}
              d='M13.8,487.2C13.8,487.3,13.9,487.3,13.8,487.2L13.8,487.2c0.1-0.1,0.1-0.1,0.1-0.1l-1.9-1.6         c0,0-0.1,0-0.1,0l0,0c0,0,0,0.1,0,0.1L13.8,487.2z'
              id='path1297'
            />
          </g>
          <g id='g1299'>
            <path
              style={{ fill: '#ECECEC' }}
              d='M7.9,487.1C7.9,487.1,7.9,487.1,7.9,487.1L7.9,487.1c0.1,0.1,0.1,0,0.1,0v-3.1c0,0,0-0.1-0.1-0.1l0,0         c0,0-0.1,0-0.1,0.1V487.1z'
              id='path1301'
            />
          </g>
          <g id='g1303'>
            <path
              style={{ fill: '#6F6F6E' }}
              d='M14.8,487.3c0,0,0,1.1-0.1,1.3c-0.1,0.1-1.1,0.2-1.9,0.2c0,0,0.2-0.6,0.2-0.8         C13.1,487.8,14.5,486.9,14.8,487.3z'
              id='path1305'
            />
          </g>
          <g id='g1307'>
            <path
              style={{ fill: '#6F6F6E' }}
              d='M9,488.7c0,0-2.2,0.1-2.5-0.2c-0.3-0.4,0-0.7,0-0.7s1.6-0.8,2.3,0C9.5,488.6,9.1,488.6,9,488.7'
              id='path1309'
            />
          </g>
          <g id='g1311'>
            <path
              style={{ fill: '#C6C6C5' }}
              d='M15.1,487.5c0,0,0-0.4-0.3-0.5c-0.3-0.1-1.1-0.3-1.1-0.3s-0.2-0.1-0.2,0.1c0,0.2-0.6,1.6-0.6,1.6         s-1.6,0-1.9-0.4l0-0.7c0,0,0-0.2-0.3-0.2c-0.3,0-2.9,0-3.1,0.1c-0.2,0-1.5,0.3-1.2,1.4l0,0c0,0,0.3-0.7,1-0.9         c0,0,1.4-0.2,1.7,0.9l0,0.3H13v-0.3c0,0,0.3-0.9,1.5-1.1C14.5,487.4,14.8,487.4,15.1,487.5z'
              id='path1313'
            />
          </g>
          <g style={{ opacity: 0.56 }} id='g1315'>
            <path
              style={{ fill: '#FFFFFF' }}
              d='M7.2,487.6c-0.7,0.2-1,0.9-1,0.9l0,0c0-0.1,0-0.2,0-0.3c0-0.8,1.1-1,1.3-1c0.2,0,2.8,0,3,0         c0.3,0,0.3,0.2,0.3,0.2l0,0.6c0.3,0.4,2.1,0.4,2.1,0.4s0.6-1.3,0.6-1.5c0-0.2,0.2-0.1,0.2-0.1s0.7,0.2,1,0.3         c0.2,0.1,0.3,0.2,0.3,0.3c-0.2,0-0.5,0-0.5,0c-1.2,0.2-1.5,1.1-1.5,1.1v0.3H8.9l0-0.3C8.6,487.4,7.2,487.6,7.2,487.6z'
              id='path1317'
            />
          </g>
          <g id='g1319'>
            <g id='g1321'>
              <circle style={{ fill: '#1D1D1B' }} cx='7.6' cy='488.6' r='0.8' id='circle1323' />
            </g>
            <g id='g1325'>
              <g id='g1327'>
                <defs id='defs1329'>
                  <ellipse id='SVGID_9_' cx='7.6' cy='488.6' rx='0.5' ry='0.4' />
                </defs>
                <clipPath id='SVGID_10_'>
                  <use href='#SVGID_9_' style={{ overflow: 'visible' }} id='use1333' />
                </clipPath>
                <g id='g1335' clipPath='url(#SVGID_10_)'>
                  <image
                    style={{ overflow: 'visible' }}
                    width='114'
                    height='106'
                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAABsCAYAAABU3f3vAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAeRJREFUeNrs3clRA0EQBED1ugX4 b9bw4aXgkMQe0zVZDmiik+o9pAhuN4lLNT//MJf+hx/m1P+ww8wyDjhuvVIOlAE5NW7BzMMtoHmz LZh5My6YebMumHmwBTQPt4DmwRbMPNgCmodbQPNgC2gebAHNgy2gebAFNA+2gObBbkD9JQBt0NYC mge7mVeLjCNQtbRRNDWwraWledfXDaj1Kw3W8KalmioN2lpamnfTpKmLrF8tdU2V2a6tUBdoqtVr /cqMKxhqeFOtXutXZl3BUDVVOqG6nmqqQBWoAlXu7ougaqpAFagC9eG8vX8shVr3d07S39P6tX4F qkAVqAJ1mScZqJoqrVDLKDRVoApUefnO9x7VdVVTBaqcsnq/Q7WCNVWgyuGr9ydUK1hTBaocunp/ Q7WCE7W/4qejDd2s37wi/olqDbtRkqtb+iiqtmqqXNnSZ1C1NVHfI04fp1caCHbu4vlPx2mg/71W gp0Q1N1vIOged7XaOhnoXo8qYCcC3fP5E+w8Fru+VAA7h8Mhb4rgXmxw1Os/sBfO/8h3umCvmfsp L+rhnjzzs759AXvivM/+Sm3AzENdDbeW+dAFcGvZDw/ELYfIwS2HyQAuB8sAbjGvrr8SHOaTh7oX up++So98CjAADldBWvJVTLAAAAAASUVORK5CYII='
                    transform='matrix(0.24 0 0 0.24 -6.0372 475.9851)'
                    id='image1337'
                  ></image>
                </g>
              </g>
            </g>
            <g id='g1339'>
              <g id='g1341'>
                <defs id='defs1343'>
                  <circle id='SVGID_11_' cx='7.6' cy='488.6' r='0.4' />
                </defs>
                <clipPath id='SVGID_12_'>
                  <use href='#SVGID_11_' style={{ overflow: 'visible' }} id='use1347' />
                </clipPath>
                <g id='g1349' clipPath='url(#SVGID_12_)'>
                  <image
                    style={{ overflow: 'visible' }}
                    width='102'
                    height='102'
                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAYAAACPZlfNAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAApZJREFUeNrsne1RwkAURZNNA1oB KcES7AQ60BKwA6kAKMEKsAQ7gBK0At2Mm5mMghLYJO/unjvzZuAHH+Fw73sTwm5RIISGUyn2futQ sXQIBbCBAM19LSI+/8bXVglgaRxSbEB9AJqEVwJJF95UoO59rX19Gq51eJ81oGyDyh6cKqjswKUC KgtwdYKgjoEbBVo1MKg7Xw9Gpr4h1Rznja+PcP8dV+E2YAENWFLQgDUCtCoSqFyGiySGkSVOOquW MT5sF8FdM4x0lmZT9zR6ltAgAiwhaMASggYsMWhMhBNPjq6nu5gIRSZHolAsGolCI9HoiML0ohF3 GXKZw11puQx3GXOZw13puAx3GXSZw11puAx3GXWZ4wutJUccasXisYtwHkOh6dRcvNNcuPNKJCYW icSh8VisiEOtWCQSE5gSkQgw+pdAH6voX1p9jEikhyGAIYABDAEMAQxgCGAIYABDAEMAAxgCGAIY wBDA0PXADgW7H1jTLybdazrefN0W38t6Ixta+XomEulhaCpg9DHD/etnD6OPGe9fRGIiPYxYNBqH f2lZ8Mdw/pSOhhvriUWhOCQWBVfCwWVi7sJlgqu54TIxd+EywRVJcZmYu3CZ4KrauEzMXa0Wvva4 IWrti4F3NSQaJ4blrNg3Q219bfo+qO+mpc0PnM1CH+1mnOjyvvUSPs9RRD8z3LeAlgAsoAnCYnKc AFasndLbAYRB5PiA8XTJREg8ZhyDQEsAFtAEYeUOrTnmnRqsLrRdRuD2qqBydNtosKoRXqM9/5ji 6H8Ix7eyOLYTk4n0qpzAZQdKFZwZUKUhcPPOqa7aSH86hNsX/diYMjAr8ExCsg7sFLwhAHYBmYWk BOw/gNfKPCCEEEIn9SXAAIZtAK4kxBZ7AAAAAElFTkSuQmCC'
                    transform='matrix(0.24 0 0 0.24 -4.6317 476.378)'
                    id='image1351'
                  ></image>
                </g>
              </g>
              <path
                d='M7.6,489c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C8,488.8,7.8,489,7.6,489z M7.6,488.2          c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4C8,488.4,7.8,488.2,7.6,488.2z'
                id='path1353'
              />
            </g>
            <g id='g1355'>
              <path
                d='M7.6,489c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4S7.8,489,7.6,489z M7.6,488.3          c-0.2,0-0.3,0.2-0.3,0.3c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3C7.9,488.4,7.8,488.3,7.6,488.3z'
                id='path1357'
              />
            </g>
            <g id='g1359'>
              <circle style={{ fill: '#868686' }} cx='7.6' cy='488.6' r='0.4' id='circle1361' />
            </g>
          </g>
          <g id='g1363'>
            <g id='g1365'>
              <circle style={{ fill: '#1D1D1B' }} cx='14.3' cy='488.6' r='0.8' id='circle1367' />
            </g>
            <g id='g1369'>
              <g id='g1371'>
                <defs id='defs1373'>
                  <ellipse id='SVGID_13_' cx='14.3' cy='488.6' rx='0.5' ry='0.4' />
                </defs>
                <clipPath id='SVGID_14_'>
                  <use href='#SVGID_13_' style={{ overflow: 'visible' }} id='use1377' />
                </clipPath>
                <g id='g1379' clipPath='url(#SVGID_14_)'>
                  <image
                    style={{ overflow: 'visible' }}
                    width='114'
                    height='106'
                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAABsCAYAAABO4R1hAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAa1JREFUeNrs3UFqAkEQBdCmyV5P Eq8wObmeIScxJ0gGssxCGCN01X8feiskz1/dKvSMIVIk276+X7Cu/rX9UKEHokIPhQUeiAs7CBd2 EC7sINxo7DTcKOx03PbQgJtjA24MDbc5NODm2ICbQwNuDg24OTTg5tCAm0MDbg4NuAj0fALY76V+ aLBWafP0ni1XsKsWa7Qmixa3bPNcaS+Q18S4DjiETS3WZAn4SOWw1eAQpsnh49pebE+WKnsz5OAm G9XGtVQa2ZBDm2xUG9dSbWRD1mTpiGw/1mSBLEseviBrskAWyAJZIAvkAzmdz+P9colDfkv6Y7/u 9/G5L00WyAJZIAtkgSyQ5Te3fX1A1mTpjvyn6qLJAllWzjZc0dDmWglNNq7FyLZK3ACkyca1GNmW y9rEuJb/yW08+Cp6PvsCYm+2Fri93rgWbR5Bz6CQYoeto8gOYT5CyYotPoKszQ5h1lj08bqgAYOu Duzg1fCgpc1GNOiOwLPTSBGNNqZBw4UNGHQqMOgQYNghuKCDgEEHPqlngwsbLmy4sOEChwsdaig4 2CZvAJBSMz8CDAD7n14wFOXc9wAAAABJRU5ErkJggg=='
                    transform='matrix(0.24 0 0 0.24 0.6394 475.967)'
                    id='image1381'
                  ></image>
                </g>
              </g>
            </g>
            <g id='g1383'>
              <g id='g1385'>
                <defs id='defs1387'>
                  <circle id='SVGID_15_' cx='14.3' cy='488.6' r='0.4' />
                </defs>
                <clipPath id='SVGID_16_'>
                  <use href='#SVGID_15_' style={{ overflow: 'visible' }} id='use1391' />
                </clipPath>
                <g id='g1393' clipPath='url(#SVGID_16_)'>
                  <image
                    style={{ overflow: 'visible' }}
                    width='103'
                    height='103'
                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAYAAACPZlfNAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAphJREFUeNrsnW1Rw0AYhHMXBOCA SgAHSMABdYCFIgEFtBJQgAVQAHUACuAy0wxMvzu9JLuXZ2fevxnI093bJG2uqhBC3SkY/a3TNJOM x5un+XQDdmEE6D4zsKs0SzeAQRhSbkDHOG6pDi+MHJIdvCAASgXSIXgS4AKgvMAFQHmBC4AqIypz wvpI81PwPPf5Qaw7BHWX5qEwV23TdZrLNDcrp33hKtwGLKABywoasHqCVmcANZZyUUQZecVJR81t rhMeM1wQo8Ma/KYBa5ZREQGWETRgGUEDlhk0GqFAc4wnuItGaNIciUKzaCQKhaIxEoVlRSPuEnNZ xF3luAx3Cbos4q4yXIa7RF0WcZe/y3CXsMsiH2ovReLQOxaJQ/FYJBKNI5E4NItF4tAgFolE85aI xGMxsn7JaroP2D3AiEQEMAQwgCGAIYABDAEMbV4fA0xbU4B5aV6tvXgMYNpaAIzSgQCGAAYwBDAE sGKBbfR9pA1sDjA5bWVCJOpqAbDCSgfrmHgcbhPfr+fnRqjL6zBi0SgOiUV+lI66jkRi0RAYdz2E 169dW3k0O/e1W1Gg/mA9nmMWyofR29xYy8SrPC4r4I2kuMzMXbjM8K3auMzMXa2mFZsN2O2/QjQa 7b1CNJpEIdHIpm/AGsLSQDPcjBtoA2wNHB0WTkrGn87dKb15DPO+sjqPYjp6bEI89rdmzSrh1xoC TahgAK1AWEDrAVbdwTHf0nyPrIw0xeIlzZN7cx6D23qNwLrj45fstkFcFXp2WwPN/Q3eDajlUDcO wgD/sCu4QUGprG8zkzVOpqoHEXDtyVBxXeum7PcCSwCmAu8/JNnYC5Wu+oBnAckF2C54+9SCXY+0 fcBGWSDUisyEU4EQOl2/AgwA3OmWzlrU3VEAAAAASUVORK5CYII='
                    transform='matrix(0.24 0 0 0.24 2.047 476.3605)'
                    id='image1395'
                  ></image>
                </g>
              </g>
              <path
                d='M14.3,489c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C14.7,488.8,14.5,489,14.3,489z           M14.3,488.2c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4C14.7,488.4,14.5,488.2,14.3,488.2z'
                id='path1397'
              />
            </g>
            <g id='g1399'>
              <path
                d='M14.3,489c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4S14.5,489,14.3,489z M14.3,488.3          c-0.2,0-0.3,0.2-0.3,0.3c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3C14.6,488.4,14.5,488.3,14.3,488.3z'
                id='path1401'
              />
            </g>
            <g id='g1403'>
              <circle style={{ fill: '#868686' }} cx='14.3' cy='488.6' r='0.4' id='circle1405' />
            </g>
          </g>
          <g id='g1407'>
            <path
              style={{ fill: '#B1B1B1' }}
              d='M8.6,485.1L9,487l1.6,0c0,0,0.2-0.2,0-0.3c0,0,0-0.2-0.3-0.2c-0.3,0-1,0.1-1,0.1l-0.1-1.2         c0,0,0-0.2-0.2-0.2C8.9,485.2,8.9,485,8.6,485.1z'
              id='path1409'
            />
          </g>
          <g id='g1411'>
            <path
              style={{ fill: '#6F6F6E' }}
              d='M9.2,485.4l0.1,1.2c0,0,0.7-0.1,1-0.1c0.3,0,0.3,0.2,0.3,0.2l-1.4,0.1L9,485.2         C9.1,485.2,9.2,485.4,9.2,485.4z'
              id='path1413'
            />
          </g>
          <g id='g1415'>
            <path style={{ fill: '#666666' }} d='M10.6,487l-1.5,0L9,487L10.6,487C10.7,487,10.6,487,10.6,487z' id='path1417' />
          </g>
          <g style={{ opacity: 0.56 }} id='g1419'>
            <polygon style={{ fill: '#6F6F6E' }} points='13,488.6 13,488.9 8.9,488.9 8.9,488.6 8.9,488.6        ' id='polygon1421' />
          </g>
          <g id='g1423'>
            <g id='g1425'>
              <path
                style={{ fill: '#FFFFFF' }}
                d='M7.2,483.9c0,0.1,0.1,0.2,0.2,0.2l7.4-0.6c0.1,0,0.2-0.1,0.2-0.2l0,0c0-0.1-0.1-0.2-0.2-0.2l-7.4,0.6          C7.3,483.6,7.2,483.7,7.2,483.9L7.2,483.9z'
                id='path1427'
              />
            </g>
            <g id='g1429'>
              <path
                style={{ fill: '#9C9B9B' }}
                d='M7.6,484l7-0.5c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-7.4,0.5c0,0,0,0,0,0          C7.4,484,7.5,484,7.6,484z'
                id='path1431'
              />
            </g>
          </g>
          <g id='g1433'>
            <g id='g1435'>
              <defs id='defs1437'>
                <path
                  id='SVGID_17_'
                  d='M11.9,485.8C11.9,485.8,11.9,485.8,11.9,485.8L11.9,485.8c0.1,0.1,0.1,0.1,0.1,0.1l0.2-0.2           c0,0,0,0,0-0.1l0,0c0,0-0.1,0-0.1,0L11.9,485.8z'
                />
              </defs>
              <clipPath id='SVGID_18_'>
                <use href='#SVGID_17_' style={{ overflow: 'visible' }} id='use1441' />
              </clipPath>
              <g id='g1443' clipPath='url(#SVGID_18_)'>
                <image
                  style={{ overflow: 'visible' }}
                  width='20'
                  height='20'
                  href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAItJREFUeNrs1UsKgCAQBuDRO3aA dh2pIChoWXRDtW2E6Tx+oUWzHPz9EF9Ef32+5u1M03ok5Jz+2QghUkyRxmWHQS7XvAND37kmCBoq hlFQNYiAWCErxA5YINFgLSReugZSbaQUUh9LCWS6ZFzI/GRwIDPCgSBIDYIhJQiKvEFwJAf5Fgji oxPXJcAAUI1T2WsKQfwAAAAASUVORK5CYII='
                  transform='matrix(0.24 0 0 0.24 17.6977 479.6702)'
                  id='image1445'
                ></image>
              </g>
            </g>
          </g>
          <g id='g1447'>
            <path
              style={{ fill: '#C6C6C5' }}
              d='M11.5,486.1C11.5,486.1,11.5,486.2,11.5,486.1L11.5,486.1C11.5,486.2,11.6,486.2,11.5,486.1l0.8-0.9         c0,0,0-0.1,0-0.1l0,0c0,0-0.1,0-0.1,0L11.5,486.1z'
              id='path1449'
            />
          </g>
          <g id='g1451'>
            <path
              style={{ fill: '#6F6F6E' }}
              d='M9.2,485.4l0.1,1.2c0,0.1-0.1,0.2-0.1,0.2l0,0l-0.3-1.7C9.1,485.2,9.2,485.4,9.2,485.4z'
              id='path1453'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SideCart;
