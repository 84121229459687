import './OrganizationsList.scss';
import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  name: string;
  disabled: boolean;
};

export const OrganizationsList = observer(() => {
  useSetPageTitle({ title: 'Organizations List', subtitle: '' });
  const os = useStores().organizationsEditorStore;
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    os.organizationsList.length === 0 && os.getOrganizations();
  }, [os]);

  useEffect(() => {
    os.filteredList = os.organizationsList.filter((org) => org.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [os, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      os.filteredList.map((org) => {
        return {
          id: org.id,
          name: org.name,
          disabled: org.disabled,
        };
      }) as RowsDisplay[],
    [os.filteredList]
  );

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field'>
            <input
              className='search-input'
              placeholder={'Search...'}
              name='search-for-lesson'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.OrganizationsEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New organization</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id} className='organization-list-row'>
                    <TableCell>
                      <Link to={`${DefaultRoutes.Admin}/${DefaultRoutes.OrganizationsEditor}/${row.id}`}>{row.name.toLowerCase()}</Link>
                    </TableCell>
                    {row.disabled ? (
                      <TableCell>
                        <span className='disabled-info'>Disabled</span>
                      </TableCell>
                    ) : (
                      <TableCell>Active</TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>
                  <Paragraph>
                    <>No organizations found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default OrganizationsList;
