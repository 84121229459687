import { AdminParameterModel, AdminParametersList, LegendModelGET } from 'types/admin/trackmanParameters';
import { configure, makeAutoObservable } from 'mobx';

import { AdminUploadedImage } from 'types/admin/media';
import { NotificationsStore } from '../../NotificationsStore';
import ParametersService from 'services/Admin/Editors/ParametersService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export const cleanParameter: AdminParameterModel = {
  id: '',
  titles: [{ countryCode: 'en-US', text: '' }],
  primaryCategory: 'Tee-to-green',
  category: 'Club',
  mediaType: 'Image',
  legendData: [],
  languages: ['en-US'],
  definitions: [{ countryCode: 'en-US', text: '' }],
  videoUrls: [],
  usageInfo: [{ countryCode: 'en-US', text: '' }],
  articlesInfo: [{ countryCode: 'en-US', text: '' }],
  visible: false,
};
export class ParametersStore {
  private parametersService: ParametersService;
  private ns: NotificationsStore;

  constructor(parametersService: ParametersService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.parametersService = parametersService;
    this.ns = notificationStore;
  }

  parametersList: AdminParametersList = [];

  parameter: AdminParameterModel = { ...cleanParameter };
  selectedParameterId: string = '';
  filteredList: AdminParametersList = [];
  newLegendImage: AdminUploadedImage = { id: '', data: new File([], '') };
  legendImgCollection: LegendModelGET[] = [];
  isMainImgChanged: boolean = false;

  isLoading: boolean = false;
  isSaving: boolean = false;

  getParameters = async () => {
    try {
      this.isLoading = true;
      const response = await this.parametersService.getParameters();
      this.parametersList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getParameter = async (id: string) => {
    try {
      this.isLoading = true;
      const response = await this.parametersService.getParameter(id);
      this.parameter = response;
      !this.parameter.videoUrls && (this.parameter.videoUrls = [{ videoUrl: '', label: '' }]);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getLegendImagesCollection = async () => {
    try {
      this.isLoading = true;
      const response = await this.parametersService.getLegendImagesCollection();
      this.legendImgCollection = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  uploadLegendImage = async () => {
    try {
      this.isLoading = true;
      await this.parametersService.uploadLegendImage(this.newLegendImage);
      this.getLegendImagesCollection();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteParameterAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.parametersService.deleteParameter(this.selectedParameterId);
      this.getParameters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewParameterAndUpdate = async () => {
    try {
      this.isSaving = true;
      delete this.parameter.id;
      await this.parametersService.saveNewParameter(this.parameter);
      this.ns.addToast('success', 'New parameter has been saved successfully', 'success');
      this.parameter = structuredClone(cleanParameter);
      this.getParameters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isSaving = false;
  };

  saveExistingParameterAndUpdate = async () => {
    try {
      this.isSaving = true;

      if (!this.isMainImgChanged) {
        delete this.parameter.mainImgData;
      }

      await this.parametersService.saveExistingParameter(this.parameter);
      this.ns.addToast('success', 'Existing parameter has been saved successfully', 'success');
      this.getParameters();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isSaving = false;
    this.isMainImgChanged = false;
  };
}

export default ParametersStore;
