import { getNumericDisplayValue, getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { SliderVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { isSliderHidden } from 'components/FullCalculator/helpers/hiddenVariables';
import { observer } from 'mobx-react-lite';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useMemo } from 'react';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';

type Props = {
  calcOptions: CalculatorOptions;
  sliderVariable: SliderVariable;
};

const SliderVariableRow = observer(({ calcOptions, sliderVariable }: Props) => {
  const { label, unit, precision, symbol } = useNumericConstants(sliderVariable);
  const { clubType } = useClubType(calcOptions);

  const valueInRadarUnits = useMemo(
    () => getValueByPath({ readableObject: calcOptions, readPath: sliderVariable }),
    [calcOptions, sliderVariable]
  );

  return isSliderHidden(clubType, sliderVariable) ? (
    <></>
  ) : (
    <li className='parameter'>
      <div className='parameter-name'>
        <span>{label}</span>
      </div>
      <div className='parameter-value'>
        <span>{getNumericDisplayValue({ valueInRadarUnits, unit, precision }).toFixed(precision)}</span>{' '}
        <span className='unit'>{symbol}</span>
      </div>
    </li>
  );
});

export default SliderVariableRow;
