import './ModulesAndTestsItem.scss';

import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DefaultRoutes } from 'Routes';
import ModulesAndTestsIframe from 'components/ModulesAndTestsIframe/ModulesAndTestsIframe';
import SectionTitle from 'components/TextComps/SectionTitle';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type RoomInfo = {
  sectionTitle: string;
  sectionColor: string;
  lessonTitle: string;
  lessonNumber: number;
};

const ModulesAndTestsItem = observer(() => {
  const ls = useStores().lessonStore;
  const ds = useStores().dashboardStore;
  const { t } = useTranslation();
  useSetPageTitle({
    title: t('ModulesAndTests', { ns: 'general' }),
    subtitle: t('TestYourKnowledge', { ns: 'modulesAndTests' }),
  });

  let { courseId, lessonId } = useParams();
  const navigate = useNavigate();

  const [roomInfo, setRoomInfo] = useState<RoomInfo>({
    sectionTitle: '',
    sectionColor: '',
    lessonTitle: '',
    lessonNumber: 1,
  });

  useEffect(() => {
    if (courseId === undefined || lessonId === undefined) {
      navigate(DefaultRoutes.ModulesAndTests);
    } else {
      ls.startLesson(lessonId, courseId);
    }
  }, [ls, lessonId, courseId, navigate]);

  const sections = useMemo(() => ds.lessonsSectionsCollection.courses, [ds.lessonsSectionsCollection.courses]);

  useEffect(() => {
    // this will execute only on refresh
    sections.length === 0 && ds.getDashboardData();
  }, [sections.length, ds]);

  useEffect(() => {
    const currentSection = sections.find((section) => section.id === courseId);
    if (currentSection === undefined) return;
    const currentLesson = currentSection.lessons.find((lesson) => lesson.id === lessonId);
    if (currentLesson === undefined) return;
    const currentLessonNumber = currentSection.lessons.indexOf(currentLesson) + 1;
    setRoomInfo({
      sectionTitle: currentSection.title,
      sectionColor: currentSection.color,
      lessonTitle: currentLesson.title,
      lessonNumber: currentLessonNumber,
    });
  }, [sections, courseId, lessonId]);

  return (
    <div className='modules-and-tests-item-page'>
      <div className='item-header'>
        <h3>
          <>{roomInfo.sectionTitle}</>
        </h3>
        <SectionTitle>
          <>
            <span style={{ color: roomInfo.sectionColor }}>{roomInfo.lessonNumber}</span> {roomInfo.lessonTitle}
          </>
        </SectionTitle>
      </div>
      <ModulesAndTestsIframe />
    </div>
  );
});

export default ModulesAndTestsItem;
