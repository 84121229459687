import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo } from 'react';

import clsx from 'clsx';
import { handleSearchStudent } from '../../OrganizationSearchbar/SearchForStudents/search-helper';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type DynamicRows = {
  id: string;
  initials: string;
  name: string;
  [key: string]: string;
};

export const StudentProgress = observer(() => {
  const { t } = useTranslation();
  const gps = useStores().gameProgressStore;

  useEffect(() => {
    gps.isSearchForStudentsVisible = true;
  }, [gps]);

  useEffect(() => {
    gps.filteredList = handleSearchStudent(gps.searchStudentInput, gps.classProgressForSelectedGame);
  }, [gps, gps.searchStudentInput]);

  const dynamicColumns = useCallback((): GridColDef[] => {
    const columns = [
      {
        field: 'initials',
        headerName: '',
        width: 64,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: (params: GridCellParams<string>) => clsx('initials-cell'),
      },
      { field: 'name', headerName: t('Name', { ns: 'studentsPage' }), width: 160, sortable: false, disableColumnMenu: true },
    ];

    gps.filteredList[0] &&
      gps.filteredList[0].progressList?.forEach((lesson) => {
        return columns.push({
          field: lesson.lessonName,
          headerName: lesson.lessonName.toLowerCase(),
          width: 130,
          sortable: false,
          disableColumnMenu: true,
          cellClassName: (params: GridCellParams<string>): string => {
            if (params.value == null) {
              return '';
            }
            return clsx('lesson-progress', {
              gameFinished: params.value === '100%',
              gameNotStarted: params.value === '0%',
              gameInProgress: params.value !== '0%' && params.value !== '100%',
            });
          },
        });
      });

    return columns;
  }, [gps.filteredList, t]);

  const rows = useMemo((): DynamicRows[] => {
    let dynamicRows: DynamicRows[] = [];

    gps.filteredList.map((student, index) => {
      const initials = student.name
        .split(' ')
        .map((a) => a[0])
        .join('');

      dynamicRows.push({
        id: student.id,
        initials: initials,
        name: student.name,
      });

      student.progressList?.map((lesson) => {
        return (dynamicRows[index][lesson.lessonName] = `${Math.round(lesson.percentage * 100)}%`);
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [gps.filteredList]);

  return (
    <DataGrid
      rows={rows}
      rowHeight={64}
      columns={dynamicColumns()}
      initialState={{
        pagination: {
          page: 0,
          pageSize: 5,
        },
      }}
      rowsPerPageOptions={[5, 10, 25]}
      disableSelectionOnClick={true}
    />
  );
});

export default StudentProgress;
