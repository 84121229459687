import { CertificationsTabsRelativeRoutes } from 'Routes';
import Tabs from 'components/Tabs/Tabs';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useTranslation } from 'react-i18next';

type TabRouteKeys = keyof typeof CertificationsTabsRelativeRoutes;

// order in the object defines order of tabs
const certifiedTabNamesByRoutes: { [key in TabRouteKeys]: string } = {
  AboutTrackmanCertification: i18next.t('AboutTrackmanCertification', { ns: 'aboutCertification' }),
  ApplyForMaster: i18next.t('ApplyForMaster', { ns: 'general' }),
  Certificate: i18next.t('Certificate', { ns: 'aboutCertification' }),
  Partners: i18next.t('Partners', { ns: 'aboutCertification' }),
  Masters: i18next.t('Masters', { ns: 'aboutCertification' }),
} as const;

export const GetCertified = observer(() => {
  const { t } = useTranslation();
  useSetPageTitle({
    title: t('Certifications', { ns: 'general' }),
    subtitle: t('PromoteYourselfAsATrackmanCertified', { ns: 'aboutCertification' }),
  });

  return <Tabs tabs={certifiedTabNamesByRoutes} defaultTabName={certifiedTabNamesByRoutes.AboutTrackmanCertification} />;
});

export default GetCertified;
