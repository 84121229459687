import { StudyRoomLessonModel, StudyRoomLessonsList } from 'types/admin/studyroomLessons';
import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../../NotificationsStore';
import StudyroomLessonEditorService from '../../../services/Admin/Editors/StudyroomLessonEditorService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class StudyroomLessonEditorStore {
  private studyroomLessonEditorService: StudyroomLessonEditorService;
  private ns: NotificationsStore;

  constructor(studyroomLessonEditorService: StudyroomLessonEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.studyroomLessonEditorService = studyroomLessonEditorService;
    this.ns = notificationStore;
  }

  studyLessonsList: StudyRoomLessonsList = [];
  cleanStudyLesson: StudyRoomLessonModel = {
    comingSoon: false,
    descriptions: [{ countryCode: 'en-US', text: '' }],
    id: '',
    languages: ['en-US'],
    learningMaterialIds: [],
    titles: [{ countryCode: 'en-US', text: '' }],
    visible: false,
  };
  studyLesson = { ...this.cleanStudyLesson };
  selectedLessonId: string = '';
  filteredList: StudyRoomLessonsList = [];

  isLoading: boolean = false;

  getLessons = async () => {
    try {
      this.isLoading = true;
      const response = await this.studyroomLessonEditorService.getLessons();
      this.studyLessonsList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getLesson = async (lessonId: string) => {
    try {
      this.isLoading = true;
      const response = await this.studyroomLessonEditorService.getLesson(lessonId);
      this.studyLesson = response;
      this.studyLesson.learningMaterialIds = this.studyLesson.learningMaterialIds || [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewLessonAndUpdate = async () => {
    try {
      this.isLoading = true;
      delete this.studyLesson.id;
      await this.studyroomLessonEditorService.saveNewLesson(this.studyLesson);
      this.ns.addToast('success', 'New lesson has been saved successfully', 'success');
      this.studyLesson = { ...this.cleanStudyLesson };
      this.getLessons();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedLessonAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.studyroomLessonEditorService.saveEditedLesson(this.studyLesson);
      this.getLessons();
      this.ns.addToast('success', 'Lesson has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteLessonAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.studyroomLessonEditorService.deleteLesson(this.selectedLessonId);
      this.getLessons();
      this.ns.addToast('success', 'Lesson has been deleted successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedLessonId = '';
    this.isLoading = false;
  };
}

export default StudyroomLessonEditorStore;
