import './TextComps.scss';

type Props = {
  children: React.ReactElement;
};

export const SectionTitle = ({ children }: Props) => {
  return <h2 className='tm-section-title'>{children}</h2>;
};

export default SectionTitle;
