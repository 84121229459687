import './CourseProgress.scss';

import ClassProgress from './ClassProgress/ClassProgress';
import NoStudentsFound from '../NoStudentsFound/NoStudentsFound';
import Paragraph from 'components/TextComps/Paragraph';
import StudentProgress from './StudentProgress/StudentProgress';
import Tab from '@mui/material/Tab';
import TableTab from '../TableTab';
import Tabs from '@mui/material/Tabs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const CourseProgress = observer(() => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const coursesStore = useStores().coursesStore;
  const gps = useStores().gameProgressStore;

  return (
    <>
      <div className='table-tabs-wrapper'>
        <div className='table-heading'>
          <h3>{t('Course', { ns: 'organizationPage' })}</h3>
          <Paragraph>
            <>{coursesStore.selectedCourseName}</>
          </Paragraph>
        </div>
        <div className='tabs-wrapper'>
          <Tabs value={value} onChange={(event: React.SyntheticEvent, newValue: number) => setValue(newValue)}>
            <Tab label={t('StudentProgress', { ns: 'organizationPage' })} />
            <Tab label={t('ClassProgress', { ns: 'organizationPage' })} />
          </Tabs>
        </div>
      </div>

      <>
        <TableTab value={value} index={0} tabPrefix='students-progress-tab'>
          {!gps.classProgressForSelectedGame.length ? <NoStudentsFound /> : <StudentProgress />}
        </TableTab>
        <TableTab value={value} index={1} tabPrefix='class-progress-tab'>
          {!gps.classProgressForSelectedGame.length ? <NoStudentsFound /> : <ClassProgress />}
        </TableTab>
      </>
    </>
  );
});

export default CourseProgress;
