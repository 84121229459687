import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import React from 'react';
import SelectVariableRow from './Row/SelectVariableRow';
import SliderVariableRow from './Row/SliderVariableRow';
import { observer } from 'mobx-react-lite';
import useGetVariables from '../../hooks/useGetVariables';
import { useTranslation } from 'react-i18next';

type Props = {
  calcOptions: CalculatorOptions;
  htmlColor: string;
};

const InputsDisplayBox = observer(({ calcOptions, htmlColor }: Props) => {
  const { t } = useTranslation();

  const { sliderVariablesInThisCalc, selectVariablesInThisCalc } = useGetVariables(calcOptions);

  return (
    <div className='input-display-box'>
      <div className='table-header' style={{ backgroundColor: `${htmlColor}80` }}>
        {t('Input', { ns: 'learningMaterialsLesson' })}
      </div>
      <ul className='parameters-list'>
        {sliderVariablesInThisCalc.map((sliderVariable) => {
          return (
            <React.Fragment key={sliderVariable}>
              <SliderVariableRow calcOptions={calcOptions} sliderVariable={sliderVariable} />
            </React.Fragment>
          );
        })}
        {selectVariablesInThisCalc.map((selectVariable) => {
          return (
            <React.Fragment key={selectVariable}>
              <SelectVariableRow calcOptions={calcOptions} selectVariable={selectVariable} />
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
});

export default InputsDisplayBox;
