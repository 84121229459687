import { AdminLessonModel, AdminLessonsList } from 'types/admin/lessons';
import { configure, makeAutoObservable } from 'mobx';

import LessonEditorService from '../../../services/Admin/Editors/LessonEditorService';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class LearningMaterialStore {
  private lessonEditorService: LessonEditorService;
  private ns: NotificationsStore;

  constructor(lessonEditorService: LessonEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.lessonEditorService = lessonEditorService;
    this.ns = notificationStore;
  }

  isLoading: boolean = false;
  lessonsList: AdminLessonsList = [];
  cleanLesson: AdminLessonModel = {
    enterpoint: '',
    certification: false,
    certificationLevel: null,
    organizationIds: [],
    prerequisiteIds: [],
    visible: false,
    comingSoon: false,
    id: '',
    titles: [{ countryCode: 'en-US', text: '' }],
    descriptions: [{ countryCode: 'en-US', text: '' }],
    languages: ['en-US'],
  };
  lesson = { ...this.cleanLesson };
  selectedLessonId: string = '';
  filteredList: AdminLessonsList = [];

  getLessons = async () => {
    try {
      this.isLoading = true;
      const response = await this.lessonEditorService.getLessons();
      this.lessonsList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getLesson = async (lessonId: string) => {
    try {
      this.isLoading = true;
      const response = await this.lessonEditorService.getLesson(lessonId);
      this.lesson = response;
      this.lesson.prerequisiteIds = this.lesson.prerequisiteIds || [];
      this.lesson.organizationIds = this.lesson.organizationIds || [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteLessonAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.lessonEditorService.deleteLessonAndUpdate(this.selectedLessonId);
      this.getLessons();
      this.ns.addToast('success', 'Lesson has been deleted successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.selectedLessonId = '';
    this.isLoading = false;
  };

  saveNewLessonAndUpdate = async () => {
    try {
      this.isLoading = true;
      delete this.lesson.id;
      const response = await this.lessonEditorService.saveNewLesson(this.lesson);
      this.lesson = response;
      this.ns.addToast('success', 'New lesson has been saved successfully', 'success');
      this.lesson = { ...this.cleanLesson };
      this.getLessons();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedLessonAndUpdate = async (lessonId: string) => {
    try {
      this.isLoading = true;
      await this.lessonEditorService.saveEditedLesson(lessonId, this.lesson);
      this.getLessons();
      this.ns.addToast('success', 'Lesson has been saved successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default LearningMaterialStore;
