import '../../../../pages/Organization/Organization.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  selected: string[];
};

export const DeleteStudentsModal = observer(({ isModalOn, setIsModalOn, selected }: Props) => {
  const { t } = useTranslation();
  const ss = useStores().studentsStore;

  return (
    <Modal
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      aria-labelledby={t('DeleteStudents', { ns: 'studentsPage' })}
      aria-describedby={t('DeleteStudentsFromTheList', { ns: 'studentsPage' })}
      className='organization-modal'
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>
            <TrashIcon />
            {t('DeleteStudents', { ns: 'studentsPage' })}
          </h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <Paragraph>
            <>{t('AreYouSureYouWantToDeleteStudents', { ns: 'studentsPage' })}</>
          </Paragraph>
        </div>
        <div className='modal-footer'>
          <button className='styled-button secondary' onClick={() => setIsModalOn(false)}>
            {t('Cancel', { ns: 'general' })}
          </button>
          <button
            className='styled-button primary'
            onClick={() => {
              ss.removeStudents(selected);
              setIsModalOn(false);
            }}
          >
            {t('Delete', { ns: 'studentsPage' })}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default DeleteStudentsModal;
