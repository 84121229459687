import './InfoBar.scss';

import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import { observer } from 'mobx-react-lite';

type Props = {
  text: string;
};

const InfoBar = observer(({ text }: Props) => {
  return (
    <div className='info-bar'>
      <InfoIcon />
      <span>{text}</span>
    </div>
  );
});

export default InfoBar;
