import * as d3 from 'd3';

import { CurrentView, maxSideInRadarUnits, pixelsPerMeterOnSvg } from './constants';

import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import { createGridValues } from 'components/FullCalculator/helpers/createGridValues';
import { radarToDisplayUnits } from 'components/FullCalculator/helpers/unitConverters';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';
import { useMemo } from 'react';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';

type Props = {
  chartDimensions: { width: number; height: number };
  currentView: CurrentView;
  trajectories: TrajectoryPoint[][];
};

const usePathFunctionsAndProps = ({ chartDimensions, trajectories, currentView }: Props) => {
  const { unit: xUnit } = useNumericConstants('Distance');
  const { unit: yUnit } = useNumericConstants('LandingData.MaxHeight');

  const { xMinTraj, xMaxTraj, xCarryTraj, yMinTraj, yMaxTraj } = useEdgeValuesOfTrajectories(trajectories);

  const d3functions = useMemo(() => {
    const fullPathScale = chartDimensions.width / (xMaxTraj !== 0 ? xMaxTraj : 200) / pixelsPerMeterOnSvg; // 200 is an arbitrary distance value used for first render when xMaxTraj is 0

    // x, y, z - functions for scaling svg elements in relation to 2D coordinate system
    const x = d3.scaleLinear([xMinTraj, xMaxTraj], [0, chartDimensions.width / fullPathScale]);
    const y = d3.scaleLinear([yMinTraj, 2 * yMaxTraj], [chartDimensions.height / fullPathScale, 0]); // second array reversed since the viewport y-axis goes from top to bottom, and we want chart to go from bottom to top
    const z = d3.scaleLinear([-maxSideInRadarUnits, maxSideInRadarUnits], [0, chartDimensions.height / fullPathScale]);

    // used for drawing trajectory paths
    const trajectoryLineSide = d3
      .line<TrajectoryPoint>()
      .x((d) => x(radarToDisplayUnits(d.X, xUnit)) ?? 0)
      .y((d) => y(radarToDisplayUnits(d.Y, yUnit)) ?? 0);
    const trajectoryLineTop = d3
      .line<TrajectoryPoint>()
      .x((d) => x(radarToDisplayUnits(d.X, xUnit)) ?? 0)
      .y((d) => z(radarToDisplayUnits(d.Z, 'UnitLess')) ?? 0); // Z can be unitless since the y-ticks are never displayed in top view

    return { x, y, z, trajectoryLineSide, trajectoryLineTop, fullPathScale };
  }, [xUnit, yUnit, xMinTraj, xMaxTraj, yMinTraj, yMaxTraj, chartDimensions]);

  // split so that currentView change does not cause redefining of d3 functions
  const chartProps = useMemo(() => {
    const xTicksStep = currentView === 'landing' ? 5 : 20; // in display units
    const yTicksStep = 10;
    const xTicks = createGridValues(xMinTraj, xMaxTraj, xTicksStep);
    const yTicks = createGridValues(yMinTraj, 2 * yMaxTraj, yTicksStep);

    let xMaxDisplay: number;
    if (currentView === 'club') {
      xMaxDisplay = 0;
    } else {
      xMaxDisplay = xMaxTraj !== 0 ? xMaxTraj : 200; // 200 is an arbitrary distance value used for first render when xMaxTraj is 0
    }

    let xMinDisplay: number;
    if (currentView === 'landing') {
      xMinDisplay = Math.min(xCarryTraj, trajectories.at(-1)?.at(-1)?.X ?? xCarryTraj);
    } else if (currentView === 'club') {
      xMinDisplay = -10;
    } else {
      xMinDisplay = xMinTraj;
    }

    const backgroundScale = chartDimensions.width / (xMaxDisplay - xMinDisplay) / pixelsPerMeterOnSvg;

    const svgFontSize = 15 / backgroundScale;

    return { xTicks, yTicks, backgroundScale, svgFontSize, xMaxDisplay, xMinDisplay };
  }, [currentView, xUnit, yUnit, xMinTraj, xMaxTraj, yMinTraj, xCarryTraj, yMaxTraj, chartDimensions, trajectories]);

  return { ...d3functions, ...chartProps };
};

export default usePathFunctionsAndProps;
