import { configure, makeAutoObservable } from 'mobx';

import AccountService from '../../services/UserAccount/AccountService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class AccountStore {
    private accountService: AccountService;
    private ns: NotificationsStore;

    constructor(accountService: AccountService, notificationStore: NotificationsStore) {
        makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
        this.accountService = accountService;
        this.ns = notificationStore;
    }

    isLoading: boolean = false;

    logOut = async () => {
        try {
            await this.accountService.logOut();
        } catch (ex) {
            this.ns.addToast('error', getErrorMessage(ex), 'error');
        }
    };
}

export default AccountStore;