import './MembershipPlanTable.scss';

import { Dispatch, SetStateAction, useMemo } from 'react';

import { Checkbox } from '@trackman/web-shared-components';
import type { CheckoutPrice } from 'recurly__recurly-js/lib/pricing/checkout';
import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import { PricingFormState } from 'types/purchaseMembership';
import { RecurlyError } from '@recurly/recurly-js';
import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type Props = {
  loading: boolean;
  price: CheckoutPrice;
  recurlyPlanError: RecurlyError | null;
  setRecurlyPlanError: Dispatch<SetStateAction<RecurlyError | null>>;
  pricingFormState: PricingFormState;
  setPricingFormState: Dispatch<SetStateAction<PricingFormState>>;
};

export const MembershipPlanTable = observer(
  ({ loading, price, recurlyPlanError, setRecurlyPlanError, pricingFormState, setPricingFormState }: Props) => {
    const { t } = useTranslation();
    const bs = useStores().buySubscriptionStore;
    const showInfo = useMemo(() => {
      return !loading && !recurlyPlanError;
    }, [loading, recurlyPlanError]);

    return (
      <div className='plan-table'>
        <table>
          <tbody>
            <tr>
              <th className='orange-text'>
                <strong>{t('PremiumMembership', { ns: 'billingInformation' })}</strong>
              </th>
              <th className='align-right'>
                {showInfo && price && price.now && price.now.items.length ? (
                  <>
                    <strong data-recurly='currency_symbol'>{price.currency.symbol}</strong>
                    <strong>
                      <span data-recurly='subtotal_now'>{price.now.items.length && Number(price.now.items[0].plan).toFixed()}</span>
                      {t('SlashYear', { ns: 'purchaseMembership' })}
                    </strong>
                  </>
                ) : (
                  <Skeleton variant='text' sx={{ fontSize: '13px', width: '30px' }} />
                )}
              </th>
            </tr>
            <tr>
              <td className='orange-label'>
                {!loading && price && price.now && price.now.items.length ? (
                  <Checkbox
                    label={`${t('AutorenewAndSave', {
                      currencySymbol: price.currency.symbol,
                      setupFee: '300', //this is a static value we use in other places too
                      ns: 'purchaseMembership',
                    })}`}
                    checked={pricingFormState.plan === 'tmu_us'}
                    onChange={() => {
                      setRecurlyPlanError(null);
                      setPricingFormState((pricingFormState) => ({
                        ...pricingFormState,
                        plan: pricingFormState.plan === 'tmu_us' ? 'tmu_us_norenew' : 'tmu_us',
                      }));
                    }}
                    reversed
                    name='tmu_plan'
                    placeholder={undefined}
                  />
                ) : recurlyPlanError ? (
                  <>
                    <Checkbox
                      label='Autorenew off'
                      checked={false}
                      onChange={() => {
                        setRecurlyPlanError(null);
                        setPricingFormState((pricingFormState) => ({
                          ...pricingFormState,
                          plan: pricingFormState.plan === 'tmu_us' ? 'tmu_us_norenew' : 'tmu_us',
                        }));
                      }}
                      reversed
                      name='tmu_plan'
                      placeholder={undefined}
                    />
                    <p className='error-info'>
                      <ErrorIcon />
                      <span>{t('PlanSelectError', { ns: 'purchaseMembership' })}</span>
                    </p>
                  </>
                ) : (
                  <Skeleton variant='text' sx={{ fontSize: '15px', width: '30px' }} />
                )}
              </td>
              <td></td>
            </tr>
            <tr className='fees'>
              <td>
                {!loading && price && price.now && price.now.items.length && Number(price.now.items[0].setupFee) > 0
                  ? t('OneTimeEntryFee', { ns: 'purchaseMembership' })
                  : t('IncludingYearlyFee', { ns: 'purchaseMembership' })}
              </td>
              <td className='align-right'>
                {showInfo && price && price.currency && price.now.items.length ? (
                  <strong>{price.currency.symbol}300</strong>
                ) : (
                  <Skeleton variant='text' sx={{ fontSize: '11px', width: '30px' }} />
                )}
              </td>
            </tr>
            {bs.discountData.couponCode && (
              <tr className='fees'>
                <td>
                  {t('DiscountOnCode', { ns: 'purchaseMembership' })} <strong>{bs.discountData.couponCode}</strong>
                </td>
                <td className='align-right'>
                  <span data-recurly='currency_symbol'>{price.currency.symbol}</span>
                  <span data-recurly='discount_now'>{price.now.discount}</span>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('Vat', { ns: 'aboutCertification' })}</td>
              <td className='align-right'>
                {showInfo && price && price.currency ? (
                  <>
                    <strong data-recurly='currency_symbol'>{price.currency.symbol}</strong>
                    <strong data-recurly='tax_now'>{price.now.taxes}</strong>
                  </>
                ) : (
                  <Skeleton variant='text' sx={{ fontSize: '11px', width: '30px' }} />
                )}
              </td>
            </tr>
            <tr>
              <td>{t('TotalIncl', { ns: 'aboutCertification' })}</td>
              <td className='align-right'>
                {showInfo && price && price.currency ? (
                  <>
                    <strong data-recurly='currency_symbol'>{price.currency.symbol}</strong>
                    <strong data-recurly='total_now' id='total_price'>
                      {Number(price.now.subtotal).toFixed()}
                    </strong>
                  </>
                ) : (
                  <Skeleton variant='text' sx={{ fontSize: '13px', width: '30px' }} />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
);
