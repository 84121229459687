import { AdminImageCollection, AdminImageType } from 'types/admin/media';
import { configure, makeAutoObservable } from 'mobx';

import MediaService from '../../services/Admin/MediaService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class MediaStore {
  private mediaService: MediaService;
  private ns: NotificationsStore;

  constructor(mediaService: MediaService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.mediaService = mediaService;
    this.ns = notificationStore;
  }

  selectedFolderPath: string = 'TMU';
  mediaCollection: AdminImageCollection = {};
  newImagesToUpload: AdminImageType[] = [];
  deleteImageId: string = '';
  imagesInSelectedFolder: AdminImageType[] = [];
  isLoading: boolean = false;

  getMedia = async (imageId: string, language: string) => {
    try {
      this.isLoading = true;
      const response = await this.mediaService.getMedia(imageId);
      this.mediaCollection = {
        ...this.mediaCollection,
        [language]: { fileName: response.originalFilename, fileUrl: response.fileUrl },
      };
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteMedia = async () => {
    try {
      this.isLoading = true;
      await this.mediaService.deleteMedia(this.deleteImageId);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  getMediaInSelectedFolder = async () => {
    try {
      this.isLoading = true;
      const response = await this.mediaService.getMediaInSelectedFolder(this.selectedFolderPath);
      this.imagesInSelectedFolder = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  generateImageDataUrl = (item: AdminImageType): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(item.data);
    });
  };

  processLoadedImagesAndUpdateImagesList = async (): Promise<void> => {
    try {
      await Promise.all(
        this.newImagesToUpload.map(async (item) => {
          await this.generateImageDataUrl(item);
          item.mimeType = item.data.type;
          return item;
        })
      );

      const uploadPromises = this.newImagesToUpload.map((image) => this.uploadNewImage(image));

      await Promise.all(uploadPromises);
      await this.getMediaInSelectedFolder();
    } catch (error) {
      this.ns.addToast('error', 'There was an internal error while processing the images.', 'error');
    }
  };

  uploadNewImage = async (image: AdminImageType) => {
    try {
      await this.mediaService.uploadNewImage(image);
      this.ns.addToast('success', 'Images uploaded succesfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', 'There was an internal error while uploading this image.', 'error');
    }
    this.newImagesToUpload = [];
  };
}

export default MediaStore;
