import i18next from 'i18next';

export type ProfileData = {
  firstName: string | null;
  lastName: string | null;
  pictureId: string;
  pictureUrl: string;
  gender: string;
  birthDay: string | null;
  email: string;
  phone: string | null;
  dexterity: DexterityValue;
  nationality: string | null;
  handicap: number;
  category: string;
  address: {
    street1: string | null;
    street2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string;
  };
  culture: string;
  language: string;
  acceptedEmails: boolean;
};

export type SignUpLists = {
  Categories: string[];
  Countries: [
    {
      name: string;
      twoLetterIsoCode: string;
    }
  ];
  States: string[];
};

type ValueOf<T> = T[keyof T]; // helper

export const dexterityOptionToValue = {
  [i18next.t('Unknown', { ns: 'editProfile' })]: 'Unknown',
  [i18next.t('Left', { ns: 'editProfile' })]: 'LeftHanded',
  [i18next.t('Right', { ns: 'editProfile' })]: 'RightHanded',
};

export type DexterityOption = keyof typeof dexterityOptionToValue;
export type DexterityValue = ValueOf<typeof dexterityOptionToValue>;

export type ProfilePictureDataType = {
  id: string;
  fileUrl: string;
  mimeType: string;
  data: File | null;
};
