import { TranslationMaterial, TranslationTask } from 'types/admin/translations';
import { configure, makeAutoObservable } from 'mobx';

import { LanguageType } from '../../types/languages';
import LanguagesStore from './LanguagesStore';
import { NotificationsStore } from '../NotificationsStore';
import TranslationsService from 'services/Languages/TranslationsService';
import { getErrorMessage } from 'utilities/ErrorHelper';
import { i18nInit } from 'i18nSetup';
import i18next from 'i18next';
import { setI18n } from 'react-i18next';

configure({ enforceActions: 'never' });

export class TranslationsStore {
  private languagesStore: LanguagesStore;
  private translationsService: TranslationsService;
  private ns: NotificationsStore;

  constructor(languagesStore: LanguagesStore, translationsService: TranslationsService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.languagesStore = languagesStore;
    this.translationsService = translationsService;
    this.ns = notificationStore;
  }

  currentLng: string = localStorage.i18nextLng || 'en-US';
  isLoading: boolean = false;
  translationsTask: TranslationTask = '';
  translationsMaterial: TranslationMaterial = '';
  importLng: string = '';

  setLanguage = async (lng: LanguageType) => {
    try {
      await this.languagesStore.changeLanguage(lng);
      localStorage.i18nextLng = lng;
      window.location.reload();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  initialize = async () => {
    i18nInit();
    setI18n(i18next);
  };

  syncTranslations = async () => {
    try {
      this.isLoading = true;
      await this.translationsService.syncTranslations(this.translationsMaterial);
      this.ns.addToast('success', 'Translations synchronized successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.translationsTask = '';
    this.translationsMaterial = '';
    this.isLoading = false;
  };

  updateTranslations = async () => {
    try {
      this.isLoading = true;
      await this.translationsService.updateTranslations(this.translationsMaterial);
      this.ns.addToast('success', 'Translations updated successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.translationsTask = '';
    this.translationsMaterial = '';
    this.isLoading = false;
  };

  importTranslations = async () => {
    try {
      this.isLoading = true;
      await this.translationsService.importTranslations(this.translationsMaterial, this.importLng);
      this.ns.addToast('success', 'Translations imported successfully', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.translationsTask = '';
    this.translationsMaterial = '';
    this.importLng = '';
    this.isLoading = false;
  };
}

export default TranslationsStore;
