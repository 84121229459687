import '../../../../pages/Admin/Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useMemo, useState } from 'react';

import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import LessonPreviewModal from '../../Modals/LessonPreviewModal/LessonPreviewModal';
import { Link } from 'react-router-dom';
import { ReactComponent as MagnifierIcon } from 'images/icons/Magnifier.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  title: string;
  languages: string;
  description: string;
};

export const AvailableStudyLessonsTable = observer(() => {
  const studyCoursesStore = useStores().studyroomCourseEditorStore;
  const studyLessonsStore = useStores().studyroomLessonEditorStore;
  const [isModalOn, setIsModalOn] = useState(false);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      studyLessonsStore.studyLessonsList
        .filter((lesson) => lesson.id && !studyCoursesStore.studyCourse.lessonIds.includes(lesson.id))
        .map((lesson) => {
          return {
            id: lesson.id,
            title: lesson.titles.find((title) => title.countryCode === 'en-US')?.text,
            languages: lesson.languages ? lesson.languages.map((lng) => getLanguageText(lng)).join(', ') : '',
            description: lesson.descriptions.find((description) => description.countryCode === 'en-US')?.text,
          };
        }) as RowsDisplay[],
    [
      studyLessonsStore.studyLessonsList,
      studyCoursesStore.studyCourse.lessonIds,
      JSON.stringify(studyLessonsStore.studyLessonsList),
      JSON.stringify(studyCoursesStore.studyCourse.lessonIds),
    ]
  );

  const lessonRawPreview = useCallback(
    () =>
      JSON.stringify(
        studyLessonsStore.studyLessonsList.find((lesson) => lesson.id === studyLessonsStore.selectedLessonId),
        null,
        2
      ),
    [studyLessonsStore.studyLessonsList, studyLessonsStore.selectedLessonId]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong>Lessons</strong>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 300 }}>Name</TableCell>
            <TableCell sx={{ width: 900 }}>Languages</TableCell>
            <TableCell sx={{ width: 200 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length ? (
            rowsDisplay.map((lesson) => {
              return (
                <TableRow hover key={lesson.id}>
                  <TableCell>
                    <div className='description-tooltip'>
                      <button className='row-button' onClick={() => studyCoursesStore.studyCourse.lessonIds?.push(lesson.id)}>
                        <PlusIcon />
                      </button>
                      {lesson.title.toLowerCase()}
                      <Tooltip title={lesson.description} placement='bottom' className='table-cell-tooltip'>
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell>{lesson.languages}</TableCell>
                  <TableCell>
                    <div className='action-buttons'>
                      <button
                        className='row-button'
                        onClick={() => {
                          studyLessonsStore.selectedLessonId = lesson.id;
                          setIsModalOn(true);
                        }}
                      >
                        <MagnifierIcon />
                      </button>
                      <Link to={``}>
                        <PencilIcon />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>
                <Paragraph>
                  <>No lessons left to add.</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isModalOn ? <LessonPreviewModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} jsonPreview={lessonRawPreview()} /> : <></>}
    </div>
  );
});

export default AvailableStudyLessonsTable;
