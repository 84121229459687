import { AxiosInstance } from 'axios';
import { Invites } from 'types/organization/invites';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class InviteStudentsService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getNumberOfInvitesLeft = async (orgId: string) => {
    const response = await this.axiosInstance.get<Result<number>>(`/api/invites/number-of-invites-left?organizationId=${orgId}`);

    if (response.status === 200 && !response.data.isError && typeof response.data.data != 'undefined') {
      return response.data.data;
    }

    throw Error(i18next.t('RemainingNumberOfInvitesError', { ns: 'studentsPage' }));
  };

  getInvites = async (orgId: string) => {
    const response = await this.axiosInstance.get<Result<Invites>>(`/api/invites?organizationId=${orgId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('GetInvitesError', { ns: 'studentsPage' }));
  };

  deleteInvitesAndUpdate = async (orgId: string, invitesIds: Array<string>) => {
    const response = await this.axiosInstance.delete(`/api/invites?organizationId=${orgId}`, { data: invitesIds });

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('DeleteInvitesError', { ns: 'studentsPage' }));
  };

  submitInvitesAndUpdate = async (invites: Invites, orgId: string) => {
    const response = await this.axiosInstance.post(`/api/invites?organizationId=${orgId}`, [...invites]);

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('SubmitInvitesError', { ns: 'studentsPage' }));
  };

  resendInvitation = async (inviteId: string, orgId: string) => {
    const response = await this.axiosInstance.post(`/api/invites/${inviteId}/resend?organizationId=${orgId}`);

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('ResendInvitesError', { ns: 'studentsPage' }));
  };
}

export default InviteStudentsService;
