import { AxiosInstance } from 'axios';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { Result } from 'services/Result';
import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';

export type SwingDirection = {
  SwingPlane: number;
  SwingDirection: number;
  AttackAngle: number;
  ClubPath: number;
}; // there are also some string keys returned, but they contain duplicated values and are very inconsistent between endpoint calls, so no type declaration for them

export type ClubDelivery = {
  LaunchData: LaunchData;
  LandingData: LandingData;
  Trajectory: null;
  ClubType: string;
  ClubPath: number;
  AttackAngle: number;
  SpinLoft: number;
  FaceAngle: number;
  FaceToPath: number;
}; // there are also some string keys returned, but they contain duplicated values and are very inconsistent between endpoint calls, so no type declaration for them

export type BallLaunch = {
  Trajectory: TrajectoryPoint[][];
  LandingData: LandingData;
  LaunchData: LaunchData;
}; // there are also some string keys returned, but they contain duplicated values and are very inconsistent between endpoint calls, so no type declaration for them

export type BounceAndRoll = {
  Distance: number;
  Trajectory: TrajectoryPoint[][];
}; // there are also some string keys returned, but they contain duplicated values and are very inconsistent between endpoint calls, so no type declaration for them

type LandingData = {
  Carry: number;
  CarrySide: number;
  Total: number;
  TotalSide: number;
  MaxHeight: number;
  HangTime: number;
  LandingAngle: number;
  LandingBallSpeed: number;
  LandingSpinRate: number;
};

type LaunchData = {
  BallSpeed: number;
  LaunchAngle: number;
  LaunchDirection: number;
  SpinRate: number;
  SpinAxis: number;
};

export type CalcResultWithKind =
  | {
      calcKind: 'CALC_NUM' | 'CALC_UD';
      calcResult: BallLaunch;
    }
  | {
      calcKind: 'CALC_CD';
      calcResult: ClubDelivery;
    }
  | {
      calcKind: 'CALC_SD';
      calcResult: SwingDirection;
    }
  | {
      calcKind: 'CALC_BR';
      calcResult: BounceAndRoll;
    };

export class CalculatorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  simulateBallLaunch = async (options: CalculatorOptions, abortController: AbortController) => {
    try {
      const response = await this.axiosInstance.post<Result<BallLaunch>>(`/api/Calculator/SimulateBallLaunch`, options, {
        headers: { 'Content-Type': 'application/json' },
        signal: abortController.signal,
      });
      if (response && response.data && !response.data.isError && response.data.data) {
        return response.data.data;
      }
    } catch {
      if (abortController.signal.aborted) return;
      throw Error('There was an internal error while sending ball flight calculator data.');
    }
  };

  simulateClubDelivery = async (options: CalculatorOptions, abortController: AbortController) => {
    try {
      const response = await this.axiosInstance.post<Result<ClubDelivery>>(`/api/Calculator/SimulateClubDelivery`, options, {
        headers: { 'Content-Type': 'application/json' },
        signal: abortController.signal,
      });
      if (response && response.data && !response.data.isError && response.data.data) {
        return response.data.data;
      }
    } catch {
      if (abortController.signal.aborted) return;
      throw Error('There was an internal error while sending club delivery calculator data.');
    }
  };

  simulateSwingDirection = async (options: CalculatorOptions, abortController: AbortController) => {
    try {
      const response = await this.axiosInstance.post<Result<SwingDirection>>(`/api/Calculator/SwingDirection`, options, {
        headers: { 'Content-Type': 'application/json' },
        signal: abortController.signal,
      });
      if (response && response.data && !response.data.isError && response.data.data) {
        return response.data.data;
      }
    } catch {
      if (abortController.signal.aborted) return;
      throw Error('There was an internal error while sending swing direction calculator data.');
    }
  };

  simulateBounceAndRoll = async (options: CalculatorOptions, abortController: AbortController) => {
    try {
      const response = await this.axiosInstance.post<Result<BounceAndRoll>>(`/api/Calculator/SimulateBounceAndRoll`, options, {
        headers: { 'Content-Type': 'application/json' },
        signal: abortController.signal,
      });
      if (response && response.data && !response.data.isError && response.data.data) {
        return response.data.data;
      }
    } catch {
      if (abortController.signal.aborted) return;
      throw Error('There was an internal error while sending simulate bounce and roll calculator data.');
    }
  };
}

export default CalculatorService;
