import { LanguageType } from 'types/languages';
import { i18nInit } from 'i18nSetup';
import i18next from 'i18next';

i18nInit();

export const getLanguageText = (countryCode: LanguageType) => {
  let german = i18next.t('German', { ns: 'languages' });
  let japan = i18next.t('Japanese', { ns: 'languages' });
  let korea = i18next.t('Korean', { ns: 'languages' });
  let usa = i18next.t('English', { ns: 'languages' });
  let china = i18next.t('Chinese', { ns: 'languages' });
  let spain = i18next.t('Spanish', { ns: 'languages' });
  let unknown = i18next.t('Language', { ns: 'general' });

  switch (countryCode) {
    case 'de-DE':
      return german;
    case 'ja-JP':
      return japan;
    case 'ko-KR':
      return korea;
    case 'en-US':
      return usa;
    case 'zh-CN':
      return china;
    case 'es-ES':
      return spain;
    default:
      return unknown;
  }
};
