import { AdminImageType } from 'types/admin/media';
import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class MediaService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getMedia = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminImageType>>(`/api/admin/media/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching media.');
  };

  uploadNewImage = async (data: AdminImageType) => {
    const response = await this.axiosInstance.post<Result<AdminImageType>>(`/api/admin/media`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while uploading this image.');
  };

  uploadMedia = async (data) => {
    const response = await this.axiosInstance.post(`/api/admin/media/`, data);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching media.');
  };

  deleteMedia = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/media/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting media.');
  };

  getMediaInSelectedFolder = async (path: string) => {
    const response = await this.axiosInstance.get(`/api/admin/media?folderPath=/${path}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching media in the current folder.');
  };
}

export default MediaService;
