import './AddTableToStore.scss';

import { BallLaunch, BounceAndRoll, ClubDelivery, SwingDirection } from 'services/Calculator/CalculatorService';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { ReactComponent as Save } from 'images/icons/Save.svg';
import nanoid from 'nanoid';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type Props = {
  calcOptions: CalculatorOptions;
  calcResult: BallLaunch | ClubDelivery | SwingDirection | BounceAndRoll;
  htmlColor: string;
};

const AddTableToStore = observer(({ calcOptions, calcResult, htmlColor }: Props) => {
  const sct = useStores().savedCalcTableStore;

  return (
    <button
      className='save-button'
      onClick={() => sct.saveTable({ id: nanoid(), savedCalcOptions: calcOptions, savedCalcResult: calcResult, htmlColor: htmlColor })}
      disabled={sct.savedTables.length > 4}
      style={sct.savedTables.length > 4 ? { opacity: 0.5 } : {}}
    >
      <Save />
    </button>
  );
});

export default AddTableToStore;
