import './Select.scss';

import { Dispatch, SetStateAction, memo, useEffect } from 'react';

import BaseSelect from '@trackman/web-shared-components/components/Select';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { SelectVariable } from 'components/FullCalculator/constants/supportedVariablesAndParameters';
import { getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';
import { isSelectHidden } from '../helpers/hiddenVariables';
import useClubType from 'components/FullCalculator/hooks/useClubType';
import { useSelectConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import useSelectOptions from '../hooks/useSelectOptions';
import useValueSetter from 'components/FullCalculator/hooks/useValueSetter';

type Props = {
  calcOptions: CalculatorOptions;
  setCalcOptions: Dispatch<SetStateAction<CalculatorOptions>>;
  selectVariable: SelectVariable;
};

export const Select = memo(({ calcOptions, setCalcOptions, selectVariable }: Props) => {
  const { parameter, label } = useSelectConstants(selectVariable);
  const { setCalcVariable } = useValueSetter(setCalcOptions);
  const { clubType } = useClubType(calcOptions);
  const { selectOptions } = useSelectOptions({ calcOptions, parameter });

  useEffect(() => {
    const initialValue = getValueByPath({ readableObject: calcOptions, readPath: selectVariable });
    const allowedValues = selectOptions.map((option) => option.value);
    if (!allowedValues.includes(initialValue)) setCalcVariable(selectVariable, allowedValues[0]);
  }, [calcOptions, selectVariable, selectOptions, setCalcVariable]);

  return isSelectHidden(clubType, parameter) ? (
    <></>
  ) : (
    <div className='dropdown-container'>
      <BaseSelect
        label={label}
        value={getValueByPath({ readableObject: calcOptions, readPath: selectVariable }).toString()}
        onChange={(e) => setCalcVariable(selectVariable, e.target.value)}
        width='100%'
        selectOptions={selectOptions}
      />
    </div>
  );
});

export default Select;
