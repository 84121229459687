import './ModulesAndTestsSection.scss';
import '../LessonTile/TilesSectionContainer.scss';

import LessonTile from '../LessonTile/LessonTile';
import { LessonsSection } from 'types/lessons';
import ModulesAndTestsSectionHeading from './ModulesAndTestsSectionHeading/ModulesAndTestsSectionHeading';
import Separator from 'components/Separator/Separator';
import { observer } from 'mobx-react-lite';

type ModulesAndTestsSectionProps = {
  section: LessonsSection;
  index: number;
  sectionsCount: number;
  donePercentage: number;
};

export const ModulesAndTestsSection = observer(({ section, index, sectionsCount, donePercentage }: ModulesAndTestsSectionProps) => {
  return (
    <div className={`modules-and-tests-section-container tiles-section-container ${section.certification && 'certification-container'}`}>
      <ModulesAndTestsSectionHeading
        title={section.title.toLowerCase()}
        description={section.description}
        color={section.color}
        progress={donePercentage}
      />
      <div className='tiles-section-lessons-list'>
        {section.lessons.map((lesson, index, array) => {
          const isPrevLessonDone = array[index - 1] ? array[index - 1].done : undefined;

          return (
            <LessonTile
              lesson={lesson}
              key={lesson.id}
              lessonNumber={index + 1}
              color={section.color}
              courseId={section.id}
              isPrevLessonDone={isPrevLessonDone}
              isModulesAndTests={true}
            />
          );
        })}
      </div>
      {index < sectionsCount && !section.certification && <Separator />}
    </div>
  );
});

export default ModulesAndTestsSection;
