import BallLaunchChart from './BallLaunchChart/BallLaunchChart';
import BounceAndRollChart from './BounceAndRollChart/BounceAndRollChart';
import { CalcResultWithKind } from 'services/Calculator/CalculatorService';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import ClubDeliveryChart from './ClubDeliveryChart/ClubDeliveryChart';
import SwingDirectionChart from './SwingDirectionChart/SwingDirectionChart';

type Props = {
  calcOptions: CalculatorOptions;
  calcResultWithKind: CalcResultWithKind;
};

const Chart = ({ calcOptions, calcResultWithKind }: Props) => {
  return (
    <div>
      {(calcResultWithKind.calcKind === 'CALC_NUM' || calcResultWithKind.calcKind === 'CALC_UD') && (
        <BallLaunchChart calcOptions={calcOptions} calcResult={calcResultWithKind.calcResult} />
      )}
      {calcResultWithKind.calcKind === 'CALC_CD' && (
        <ClubDeliveryChart calcOptions={calcOptions} calcResult={calcResultWithKind.calcResult} />
      )}
      {calcResultWithKind.calcKind === 'CALC_SD' && (
        <SwingDirectionChart calcOptions={calcOptions} calcResult={calcResultWithKind.calcResult} />
      )}
      {calcResultWithKind.calcKind === 'CALC_BR' && <BounceAndRollChart calcResult={calcResultWithKind.calcResult} />}
    </div>
  );
};

export default Chart;
