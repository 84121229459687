import { AdminCourseModel, AdminCoursesList } from 'types/admin/courses';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

type GetCoursesResponse = AdminCoursesList | string;
export class CourseEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getCourses = async (): Promise<GetCoursesResponse> => {
    const response = await this.axiosInstance.get<Result<AdminCoursesList>>(`/api/admin/courses`);

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    } else if (response.status === 403) {
      return 'unauthorized';
    }
    throw Error('There was an internal error while fetching courses.');
  };

  getCourse = async (id: string) => {
    const response = await this.axiosInstance.get<Result<AdminCourseModel>>(`/api/admin/courses/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching course.');
  };

  deleteCourseAndUpdate = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/courses/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting course.');
  };

  saveNewCourse = async (courseData: AdminCourseModel) => {
    const response = await this.axiosInstance.post<Result<AdminCourseModel>>(`/api/admin/courses`, { ...courseData });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new course.');
  };

  saveEditedCourse = async (courseData: AdminCourseModel) => {
    const response = await this.axiosInstance.put<Result<AdminCourseModel>>(`/api/admin/courses`, { ...courseData });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving existing course.');
  };

  updateSortOrder = async (id: string, direction: boolean) => {
    const response = await this.axiosInstance.post(`/api/admin/courses/sort`, { courseId: id, moveUp: direction });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while updating new courses sort order.');
  };
}

export default CourseEditorService;
