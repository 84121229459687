import { CSSProperties, memo } from 'react';

import Arrow from '../Arrow/Arrow';
import TeeBall from '../TeeBall/TeeBall';

type Props = {
  leftToRotationPoint: string;
  topToRotationPoint: string;
  length: string;
  rotation?: number;
  scale?: number;
  style?: CSSProperties;
  className?: string;
};

const BallWithArrow = memo(({ leftToRotationPoint, topToRotationPoint, length, rotation = 0, scale = 1, style, className }: Props) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: topToRotationPoint,
        left: leftToRotationPoint,
        transformOrigin: 'left',
        transform: `translateY(-50%) rotate(${rotation}deg) scale(${scale})`,
        transition: 'transform 150ms ease, width 150ms ease',
        width: length,
        ...style,
      }}
      className={className}
    >
      <div style={{ border: 'none', borderBottom: '6px dashed #ec691a', width: '50%' }} />
      <TeeBall type={'ball'} leftToRotationPoint={'62.5%'} topToRotationPoint={'50%'} style={{ width: '25%' }} />
      <Arrow color={'orange'} leftToRotationPoint={'75%'} topToRotationPoint={'50%'} thickness={6} length='25%' />
    </div>
  );
});

export default BallWithArrow;
