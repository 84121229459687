import CourseProgress from 'components/Organization/CourseProgress/CourseProgress';
import { Loader } from '@trackman/web-shared-components';
import NoClassesFound from 'components/Organization/NoClassesFound/NoClassesFound';
import OrganizationSearchbar from 'components/Organization/OrganizationSearchbar/OrganizationSearchbar';
import SearchForStudents from 'components/Organization/OrganizationSearchbar/SearchForStudents/SearchForStudents';
import SelectClass from 'components/Organization/OrganizationSearchbar/SelectClass/SelectClass';
import SelectCourse from 'components/Organization/OrganizationSearchbar/SelectCourse/SelectCourse';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useStores } from 'index';

export const ProgressTab = observer(() => {
  const classesStore = useStores().classesStore;
  const coursesStore = useStores().coursesStore;
  const gps = useStores().gameProgressStore;

  useEffect(() => {
    coursesStore.getCourses();
  }, [coursesStore]);

  useEffect(() => {
    coursesStore.selectedCourseId &&
      classesStore.classesList.length &&
      gps.getClassProgressForSelectedCourse(classesStore.selectedClassId, coursesStore.getSelectedCourseLessonIds());
  }, [classesStore.selectedClassId, classesStore.classesList.length, coursesStore, coursesStore.selectedCourseId, gps]);

  return (
    <>
      {!classesStore.classesList.length ? (
        classesStore.isLoading ? (
          <div className='loader-wrapper'>
            <Loader size='large' />
          </div>
        ) : (
          <NoClassesFound />
        )
      ) : (
        <>
          <OrganizationSearchbar heading={t('StudentAndClassProgress', { ns: 'organizationPage' })}>
            <SelectClass onChange={(newValue) => (classesStore.selectedClassId = newValue)} value={classesStore.selectedClassId ?? ''} />
            <SelectCourse />
            {gps.isSearchForStudentsVisible && (
              <SearchForStudents
                label={t('SearchForStudents', { ns: 'organizationPage' })}
                input={gps.searchStudentInput}
                onChange={(newValue: string) => (gps.searchStudentInput = newValue)}
              />
            )}
          </OrganizationSearchbar>
          <div className='course-progress-table org-table'>
            {!gps.isLoading ? (
              <CourseProgress />
            ) : (
              <div className='loader-wrapper'>
                <Loader size='large' />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ProgressTab;
