import '../DisplayBoxes/InputsDisplayBox/InputsDisplayBox.scss';
import './SavedTable.scss';

import InputsDisplayBox from '../DisplayBoxes/InputsDisplayBox/InputsDisplayBox';
import OutputDataBoxes from '../DisplayBoxes/OutputDataBoxes/OutputDataBoxes';
import RemoveTableFromStore from './RemoveTableFromStore/RemoveTableFromStore';
import { ReactComponent as SavedIcon } from 'images/icons/Save.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const SavedTable = observer(() => {
  const { t } = useTranslation();
  const sct = useStores().savedCalcTableStore;

  return sct.savedTables.length === 0 ? (
    <></>
  ) : (
    <>
      {sct.savedTables.map((savedTable, i) => (
        <div className='saved-table-wrapper' key={`saved-table-${i}`}>
          <div className='section-title'>
            <SavedIcon />
            <h2>{t('SavedInput', { ns: 'learningMaterialsLesson' })}</h2>
          </div>
          <div className='table-container'>
            <InputsDisplayBox calcOptions={savedTable.savedCalcOptions} htmlColor={savedTable.htmlColor} />
            <OutputDataBoxes
              calcOptions={savedTable.savedCalcOptions}
              calcResult={savedTable.savedCalcResult}
              htmlColor={savedTable.htmlColor}
            />
            <RemoveTableFromStore tableId={savedTable.id} />
          </div>
        </div>
      ))}
    </>
  );
});

export default SavedTable;
