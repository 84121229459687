import React, { Suspense } from 'react';

import { Loader } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';

type Props = {
  modelScene: string;
};

const Spline = React.lazy(() => import('@splinetool/react-spline'));

export const ThreeDModel = observer(({ modelScene }: Props) => {
  return (
    <Suspense
      fallback={
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      }
    >
      <Spline scene={modelScene} />
    </Suspense>
  );
});

export default ThreeDModel;
