import { useCallback, useEffect, useRef, useState } from 'react';

import { AdminUploadedImage } from 'types/admin/media';
import { Button } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';

type Props = {
  imageData: AdminUploadedImage | undefined;
  imageUrl: string | undefined;
  title?: string;
  isSaving?: boolean;
  handleUploadImage: (data: File) => void;
};

export const ImageUploader = observer(({ imageData, imageUrl, title, isSaving, handleUploadImage }: Props) => {
  const imgInputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    isSaving && setSelectedFile(undefined);
  }, [isSaving]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(imageData?.data.name || '');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, imageData]);

  const onSelectFile = useCallback(() => {
    if (!imgInputRef.current?.files?.length) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(imgInputRef.current?.files[0]);
      handleUploadImage(imgInputRef.current?.files[0]);
    }
  }, [handleUploadImage]);

  return (
    <div className='main-image-container'>
      <Paragraph>
        <>{title}</>
      </Paragraph>
      <div className='inputs-container'>
        {preview ? <img src={preview} alt={selectedFile?.name} /> : imageUrl ? <img src={imageUrl} alt={imageUrl} /> : <></>}
        <input type='file' id='file' accept='image/*' ref={imgInputRef} style={{ display: 'none' }} onChange={onSelectFile} />
        <Button
          className='styled-button secondary'
          label={imageData?.data.name ? 'Change image' : 'Upload image'}
          intent='secondary'
          onClick={() => imgInputRef.current?.click()}
          placeholder={undefined}
        />
      </div>
    </div>
  );
});

export default ImageUploader;
