import { configure, makeAutoObservable } from 'mobx';

import { InvoiceData } from 'types/invoices';
import InvoiceService from '../../services/Subscription/InvoicesService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class BillingStore {
  private invoicesService: InvoiceService;
  private ns: NotificationsStore;

  constructor(invoicesService: InvoiceService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.invoicesService = invoicesService;
    this.ns = notificationStore;
  }

  isInvoicesLoading: boolean = false;
  invoicesData: InvoiceData[] = [];
  invoicesErrorCode: boolean = false;

  getInvoices = async () => {
    try {
      this.isInvoicesLoading = true;
      const response = await this.invoicesService.getInvoices();

      if (Array.isArray(response)) {
        this.invoicesData = response;
      } else if (response === 'accountNotFound') {
        this.invoicesErrorCode = true;
      }
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isInvoicesLoading = false;
  };

  downloadPdfInvoice = async (invoiceNumber: number) => {
    try {
      await this.invoicesService.downloadPdfInvoice(invoiceNumber).then((response) => {
        const href = window.URL.createObjectURL(response);

        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = `invoice_${invoiceNumber}.pdf`;
        anchorElement.style.display = 'none';

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };
}

export default BillingStore;
