import { configure, makeAutoObservable } from 'mobx';

import DashboardService from '../../services/Dashboard/DashboardService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';
import { LessonsSectionsCollection } from 'types/lessons';

configure({ enforceActions: 'never' });

export class DashboardStore {
  private dashboardService: DashboardService;
  private ns: NotificationsStore;

  constructor(dashboardService: DashboardService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.dashboardService = dashboardService;
    this.ns = notificationStore;
  }

  lessonsSectionsCollection: LessonsSectionsCollection = { courses: [] };
  isLoading: boolean = false;

  getDashboardData = async () => {
    try {
      this.isLoading = true;
      const response = await this.dashboardService.getDashboardData();
      this.lessonsSectionsCollection = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default DashboardStore;
