import { AxiosInstance } from 'axios';

export class SendInvitationsTestService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  sendInvitation = async () => {
    const response = await this.axiosInstance.post(`/api/admin/email-test/send-student-invitation`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while sending the student invitation.');
  };

  resendInvitation = async () => {
    const response = await this.axiosInstance.post(`/api/admin/email-test/resend-student-invitation`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while resending the student invitation.');
  };

  sendInstructorInvitation = async () => {
    const response = await this.axiosInstance.post(`/api/admin/email-test/send-instructor-invitation`);
    if (response.status !== 200 || response.data !== '')
      throw Error('There was an internal error while sending the instructor invitation.');
  };
}

export default SendInvitationsTestService;
