import './LearningMaterialsLessonCalculator.scss';

import { CalculatorKind } from 'types/learningMaterials';
import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import FullCalculator from 'components/FullCalculator/FullCalculator';
import { observer } from 'mobx-react-lite';

type LearningMaterialsLessonCalculatorProps = {
  calculatorKind: CalculatorKind;
  calculatorOptions: CalculatorOptions;
  htmlColor: string;
};

export const LearningMaterialsLessonCalculator = observer(
  ({ calculatorKind, calculatorOptions, htmlColor }: LearningMaterialsLessonCalculatorProps) => {
    return (
      <div className='calculator-content lesson-content'>
        <FullCalculator initialCalculatorOptions={calculatorOptions} calcKind={calculatorKind} htmlColor={htmlColor} />
      </div>
    );
  }
);

export default LearningMaterialsLessonCalculator;
