import './ProfilePicture.scss';

import { useCallback, useRef, useState } from 'react';

import AvatarEditor from 'react-avatar-editor';
import { Button } from '@trackman/web-shared-components';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import { Slider } from '@mui/material';
import { ReactComponent as UploadIcon } from 'images/icons/Download.svg';
import { getUserInitials } from 'utilities/userInfo';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const ProfilePicture = observer(() => {
  const { t } = useTranslation();
  const ps = useStores().profileStore;
  const [isModalOn, setIsModalOn] = useState(false);
  const inputPic = useRef<HTMLInputElement>(null);
  const [newProfilePic, setNewProfilePic] = useState<File>();
  const [profilePicZoom, setProfilePicZoom] = useState<number>(1.2);
  const [profilePicRotation, setProfilePicRotation] = useState<number>(0);
  let outputPic: AvatarEditor | null = null;

  const loadNewPic = useCallback(() => {
    if (inputPic.current?.files?.[0]) {
      ps.newPicture = {
        id: '',
        fileUrl: inputPic.current?.files?.[0].name,
        mimeType: inputPic.current?.files?.[0].type,
        data: null,
      };
    }

    setNewProfilePic(inputPic.current?.files?.[0]);
    setProfilePicZoom(1.2);
    setProfilePicRotation(0);
  }, [ps]);

  const closeModal = useCallback(() => {
    setIsModalOn(false);
    setNewProfilePic(undefined);
    setProfilePicZoom(1.2);
    setProfilePicRotation(0);
  }, []);

  const saveProfilePic = () => {
    if (!outputPic) {
      return;
    }

    const canvasScaled = outputPic.getImageScaledToCanvas();
    const croppedImg = canvasScaled.toDataURL();

    ps.pictureDataUrl = croppedImg;
    ps.processProfilePicAndUpdateProfile();
    closeModal();
  };

  return (
    <>
      {ps.profileData.pictureUrl && ps.profileData.pictureUrl !== '/Frontend/Images/no_avatar.svg' ? (
        <img className='profile-picture' src={ps.profileData.pictureUrl} alt={t('ProfilePicture', { ns: 'editProfile' })} />
      ) : (
        <div className='profile-picture-initials'>{ps.isLoaded && getUserInitials(ps.profileData.firstName, ps.profileData.lastName)}</div>
      )}
      <div className='profile-picture-change-icon' onClick={() => setIsModalOn(true)}>
        <CameraAltOutlinedIcon />
      </div>
      <Modal
        open={isModalOn}
        onClose={() => setIsModalOn(false)}
        aria-labelledby={t('UploadPicture', { ns: 'editProfile' })}
        aria-describedby={t('UploadPicture', { ns: 'editProfile' })}
        className='profile-pic-modal'
      >
        <div className='modal-container'>
          <div className='modal-header'>
            <h2 className='modal-title'>
              <UploadIcon />
              {t('UploadPicture', { ns: 'editProfile' })}
            </h2>
            <CloseIcon onClick={() => setIsModalOn(false)} />
          </div>
          <div className='modal-body'>
            {inputPic.current?.files?.[0] ? (
              <div className='profile-pic-modal-content'>
                <AvatarEditor
                  ref={(e) => (outputPic = e)}
                  image={newProfilePic ?? ps.newPicture.fileUrl}
                  width={162}
                  height={162}
                  border={13}
                  color={[0, 0, 0, 0.3]}
                  scale={profilePicZoom}
                  borderRadius={81}
                  rotate={profilePicRotation}
                />
                <div className='profile-pic-slider-container'>
                  <div className='slider-value-label'>
                    {t('Zoom', { ns: 'editProfile' })} {profilePicZoom}
                  </div>
                  <Slider
                    value={profilePicZoom}
                    onChange={(e, zoom) => {
                      if (typeof zoom === 'number') setProfilePicZoom(zoom);
                    }}
                    valueLabelDisplay='off'
                    step={0.1}
                    min={1}
                    max={3}
                  />
                </div>
                <div className='profile-pic-slider-container'>
                  <div className='slider-value-label'>
                    {t('Rotation', { ns: 'editProfile' })} {profilePicRotation}°
                  </div>
                  <Slider
                    value={profilePicRotation}
                    onChange={(e, rotation) => {
                      if (typeof rotation === 'number') setProfilePicRotation(rotation);
                    }}
                    valueLabelDisplay='off'
                    step={1}
                    min={-30}
                    max={30}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className='change-picture-container'>
              <input type='file' id='file' accept='image/*' ref={inputPic} style={{ display: 'none' }} onChange={loadNewPic} />
              <Button
                className='styled-button secondary'
                label={t('ChooseFile', { ns: 'editProfile' })}
                intent='secondary'
                onClick={() => inputPic.current?.click()}
                placeholder={undefined}
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button className='styled-button secondary' onClick={() => closeModal()}>
              {t('Cancel', { ns: 'general' })}
            </button>
            <button className='styled-button primary' onClick={() => saveProfilePic()}>
              {t('Save', { ns: 'general' })}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
